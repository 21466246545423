// /* eslint-disable jsx-a11y/anchor-is-valid */
// import { useState } from 'react'
// import * as Yup from 'yup'
// import clsx from 'clsx'
// import { Link, useNavigate } from 'react-router-dom'
// import { useFormik } from 'formik'
// import { getUserByToken, login } from '../core/_requests'
// import { useAuth } from '../core/Auth'
// import { toAbsoluteUrl } from 'src/marketConnect/helpers'
// import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'
// import { loginAsync } from 'src/redux/actions/authActions'
// import { accessSessionTokenKeyName, refreshSessionTokenKeyName, userLastName, userFirstName, userEmail } from 'src/utils/constants'
// import useAppToast from 'src/hooks/useAppToast'
// import { messageTemplete } from 'src/utils/messageTemplete'


// const loginSchema = Yup.object().shape({
//   email: Yup.string()
//     .email('Wrong email format')
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Email is required'),
//   password: Yup.string()
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Password is required'),
// })

// const initialValues = {
//   email: '',
//   password: '',
// }

// /*
//   Formik+YUP+Typescript:
//   https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
//   https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
// */

// export function Login() {
//   const [loading, setLoading] = useState(false)
//   const { saveAuth, setCurrentUser } = useAuth()
//   const dispatch = useAppDispatch()
//   const { } = useAppSelector(state => state.auth)
//   const { successToast, errorToast } = useAppToast()
//   const navigate = useNavigate()
//   const formik = useFormik({
//     initialValues,
//     validationSchema: loginSchema,
//     onSubmit: async (values, { setStatus, setSubmitting }) => {
//       setLoading(true)
//       try {
//         await dispatch(loginAsync({ email: values.email, password: values.password }))
//           .then((response) => {

//             if (response?.payload?.status_code === 401) {
//               errorToast(response?.payload?.detail)
//               setLoading(false);

//             } else if (response?.payload?.data.access) {
//               sessionStorage.setItem(accessSessionTokenKeyName, response?.payload?.data.access)
//               sessionStorage.setItem(refreshSessionTokenKeyName, response?.payload?.data.refresh)
//               sessionStorage.setItem(userEmail, response?.payload?.data.user_email)
//               sessionStorage.setItem(userFirstName, response?.payload?.data.user_first_name)
//               sessionStorage.setItem(userLastName, response?.payload?.data.user_last_name)
//               successToast(messageTemplete.LOGIN_SUCCESS);
//               setLoading(false);
//             } else {

//               errorToast(messageTemplete.SOMETHING_WENT_WRONG)
//               setLoading(false);
//             }


//           })
//           .catch((error) => {
//             console.log('error catch:', error)
//             setLoading(false)
//           })

//       } catch (error) {
//         console.error(error)
//         saveAuth(undefined)
//         setStatus('The login details are incorrect')
//         setSubmitting(false)
//         setLoading(false)
//       }
//     },
//   })

//   return (
//     <form
//       className='form w-100'
//       onSubmit={formik.handleSubmit}
//       noValidate
//       id='kt_login_signin_form'
//     >

//       {/* begin::Heading */}
//       <div className='text-center mb-11'>
//         <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
//         <div className='text-gray-500 fw-semibold fs-6'>Sign in into Chanakya</div>
//       </div>

//       {/* {formik.status ? (
//         <div className='mb-lg-15 alert alert-danger'>
//           <div className='alert-text font-weight-bold'>{formik.status}</div>
//         </div>
//       ) : (
//         <div className='mb-10 bg-light-info p-8 rounded'>
//           <div className='text-info'>
//             Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
//             continue.
//           </div>
//         </div>
//       )} */}

//       {/* begin::Form group */}
//       <div className='fv-row mb-8'>
//         <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
//         <input
//           placeholder='Email'
//           {...formik.getFieldProps('email')}
//           className={clsx(
//             'form-control bg-transparent',
//             { 'is-invalid': formik.touched.email && formik.errors.email },
//             {
//               'is-valid': formik.touched.email && !formik.errors.email,
//             }
//           )}
//           type='email'
//           name='email'
//           autoComplete='off'
//         />
//         {formik.touched.email && formik.errors.email && (
//           <div className='fv-plugins-message-container'>
//             <div className='fv-help-block'>
//               <span role='alert'>{formik.errors.email}</span>
//             </div>
//           </div>
//         )}
//       </div>
//       {/* end::Form group */}

//       {/* begin::Form group */}
//       <div className='fv-row mb-3'>
//         <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
//         <input
//           type='password'
//           placeholder='Password'
//           autoComplete='off'
//           {...formik.getFieldProps('password')}
//           className={clsx(
//             'form-control bg-transparent',
//             {
//               'is-invalid': formik.touched.password && formik.errors.password,
//             },
//             {
//               'is-valid': formik.touched.password && !formik.errors.password,
//             }
//           )}
//         />
//         {formik.touched.password && formik.errors.password && (
//           <div className='fv-plugins-message-container'>
//             <div className='fv-help-block'>
//               <span role='alert'>{formik.errors.password}</span>
//             </div>
//           </div>
//         )}
//       </div>
//       {/* end::Form group */}

//       {/* begin::Wrapper */}
//       <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
//         <div />

//         {/* begin::Link */}
//         <Link to='/auth/forgot-password' className='link-primary'>
//           Forgot Password ?
//         </Link>
//         {/* end::Link */}
//       </div>
//       {/* end::Wrapper */}

//       {/* begin::Action */}
//       <div className='d-grid mb-10'>
//         <button
//           type='submit'
//           id='kt_sign_in_submit'
//           className='btn btn-primary'
//           disabled={formik.isSubmitting || !formik.isValid}
//         >
//           {!loading && <span className='indicator-label'>Continue</span>}
//           {loading && (
//             <span className='indicator-progress' style={{ display: 'block' }}>
//               Please wait...
//               <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
//             </span>
//           )}
//         </button>
//       </div>
//       {/* end::Action */}

//       <div className='text-gray-500 text-center fw-semibold fs-6'>
//         Not a Member yet?{' '}
//         <Link to='/auth/registration' className='link-primary'>
//           Sign up
//         </Link>
//       </div>
//     </form>
//   )
// }

import React, { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link, useNavigate, useLocation as useReactRouterLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { useAuth } from '../core/Auth';
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks';
import { loginAsync } from 'src/redux/actions/authActions';
import { accessSessionTokenKeyName, refreshSessionTokenKeyName, userLastName, userFirstName, userEmail, userEmailsToNotify } from 'src/utils/constants';
import useAppToast from 'src/hooks/useAppToast';
import { messageTemplete } from 'src/utils/messageTemplete';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
});

interface LocationState {
  from?: {
    pathname?: string;
  };
}

const initialValues = {
  email: '',
  password: '',
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const dispatch = useAppDispatch();
  const { successToast, errorToast } = useAppToast();
  const navigate = useNavigate();
  const location = useReactRouterLocation();
  const state = location.state as LocationState | undefined;

  const from = state?.from?.pathname || '/';

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const response = await dispatch(loginAsync({ email: values.email, password: values.password })).unwrap();

        if (response.status_code === 401) {
          errorToast(response.detail);
        } else if (response.data.access) {
          sessionStorage.setItem(accessSessionTokenKeyName, response.data.access);
          sessionStorage.setItem(refreshSessionTokenKeyName, response.data.refresh);
          sessionStorage.setItem(userEmail, response.data.user_email);
          sessionStorage.setItem(userFirstName, response.data.user_first_name);
          sessionStorage.setItem(userLastName, response.data.user_last_name);
          sessionStorage.setItem(userEmailsToNotify, response.data.emails_to_notify);
          successToast(messageTemplete.LOGIN_SUCCESS);
          navigate(from, { replace: true });
        } else {
          errorToast(messageTemplete.SOMETHING_WENT_WRONG);
        }
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus('The login details are incorrect');
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Sign in into Chanakya</div>
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          placeholder='Password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
      </div>

      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div>
    </form>
  );
}



