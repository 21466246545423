import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../marketConnect/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../marketConnect/layout/core'
import {MasterInit} from '../marketConnect/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ThemeModeProvider} from '../marketConnect/partials'
import AlertError from './modules/auth/components/AlertError'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {hideDialog} from 'src/redux/slices/errorSlice'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const {isVisible} = useAppSelector((state) => state.error)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        dispatch(hideDialog())
      }, 2000)
    }
  }, [isVisible])

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <ThemeModeProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </ThemeModeProvider>
          </LayoutProvider>
        </I18nProvider>
        <AlertError />
      </Suspense>
      <ToastContainer />
    </>
  )
}

export {App}
