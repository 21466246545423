import { configureStore } from '@reduxjs/toolkit'
import authSlice from './slices/authSlice'
import errorSlice from './slices/errorSlice'
import platformSlice from './slices/platformSlice'
import connectedPlatformSlice from './slices/connectedPlatformSlice'
import fetchConnectedPlatformSlice from './slices/fetchConnectedPlatformSlice'
import retrivePlatformDataSlice from './slices/retrivePlatformDataSlice'
import createPlatformSettingsSlice from './slices/createPlatformSettingsSlice'
import SliceDatabase from './slices/SliceDatabase'
import avaliablePlatformSlice from './slices/avaliablePlatformSlice'
import agentSlice from './slices/agentSlice'
import gptModelsSlice from './slices/gptModelsSlice'
import fetchConnectedAgentSlice from './slices/fetchConnectedAgentsSlice'
import orderDataSlice from './slices/orderDataSlice'
import invitedUserSlice from './slices/invitedUserSlice'

// ...

export const store = configureStore({
  reducer: {
    testReducer : ()=>{return null},
    auth: authSlice,
    platform:platformSlice,
    connectedPlatform:connectedPlatformSlice,
    fetchConnectedPlatform :fetchConnectedPlatformSlice,
    retrivePlatformData :retrivePlatformDataSlice,
    createPlatformSettings:createPlatformSettingsSlice,
    error: errorSlice,
    database:SliceDatabase,
    availablePlatforms : avaliablePlatformSlice,
    agent : agentSlice,
    availableAgents: gptModelsSlice,
    fetchConnectedAgents: fetchConnectedAgentSlice,
    platformOrderData: orderDataSlice,
    invitedUser:invitedUserSlice,
    
  },
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),

})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch