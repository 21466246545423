// // src/app/pages/Instructions/DaySelector.tsx
// import React, { useState } from 'react';
// import { Button, Modal, Form, FormGroup, FormLabel, FormControl, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';

// interface DaySelectorProps {
//     selectedDays: string[];
//     onChange: (days: string[]) => void;
// }

// const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

// const DaySelector: React.FC<DaySelectorProps> = ({ selectedDays, onChange }) => {
//     const toggleDay = (day: string) => {
//         if (selectedDays.includes(day)) {
//             onChange(selectedDays.filter(d => d !== day));
//         } else {
//             onChange([...selectedDays, day]);
//         }
//     };

//     return (
//         <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
//             {daysOfWeek.map(day => (
//                 <div
//                     key={day}
//                     onClick={() => toggleDay(day)}
//                     style={{
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         width: '40px',
//                         height: '40px',
//                         borderRadius: '50%',
//                         margin: '0 5px',
//                         cursor: 'pointer',
//                         border: '1px solid #7C4DFF',
//                         backgroundColor: selectedDays.includes(day) ? '#7C4DFF' : '#FFFFFF',
//                         color: selectedDays.includes(day) ? '#FFFFFF' : '#000000',
//                     }}
//                 >
//                     {day}
//                 </div>
//             ))}
//             <OverlayTrigger
//                 placement="right"
//                 overlay={<Tooltip id="tooltip-right">All clear</Tooltip>}
//             >
//                 <div
//                     onClick={() => onChange([])}
//                     style={{
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         width: '40px',
//                         height: '40px',
//                         borderRadius: '50%',
//                         margin: '0 5px',
//                         cursor: 'pointer',
//                         backgroundColor: '#FF0000',
//                         color: '#FFFFFF !important',
//                     }}
//                 >

//                     <i className="fa fa-times text-white"></i>


//                 </div>
//             </OverlayTrigger>
//         </div>
//     );
// };

// export default DaySelector;
import React from 'react';

interface DaySelectorProps {
    selectedDays: string[];
    onChange: (days: string[]) => void;
}

const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const DaySelector: React.FC<DaySelectorProps> = ({ selectedDays, onChange }) => {
    const toggleDay = (day: string) => {
        if (selectedDays.includes(day)) {
            onChange(selectedDays.filter(d => d !== day));
        } else {
            onChange([...selectedDays, day]);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            {daysOfWeek.map(day => (
                <div
                    key={day}
                    onClick={() => toggleDay(day)}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        margin: '0 5px',
                        cursor: 'pointer',
                        border: '1px solid #7C4DFF',
                        backgroundColor: selectedDays.includes(day) ? '#7C4DFF' : '#FFFFFF',
                        color: selectedDays.includes(day) ? '#FFFFFF' : '#000000',
                    }}
                >
                    {day}
                </div>
            ))}
            <div
                onClick={() => onChange([])}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    margin: '0 5px',
                    cursor: 'pointer',
                    backgroundColor: '#FF0000',
                    color: '#FFFFFF',
                }}
            >
                <i className="fa fa-times text-white"></i>
            </div>
        </div>
    );
};

export default DaySelector;

