import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {PlatformDataType} from 'src/interfaces/Platform.interface'
import {fetchConnectedPlatformAsync} from '../actions/platformActions'

type fetchConnectedPlatformPayoad = {
  fetchedConnectedPlatformData: PlatformDataType[] | []
  fetchedConnectedPlatformLoading: boolean
}

const initialState: fetchConnectedPlatformPayoad = {
  fetchedConnectedPlatformData: [],
  fetchedConnectedPlatformLoading: false,
}

const fetchConnectedPlatformSlice = createSlice({
  name: 'fetchedConnectedPlatformSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(fetchConnectedPlatformAsync.pending), (state, action) => {
      state.fetchedConnectedPlatformLoading = true
    })
    builder.addMatcher(isAnyOf(fetchConnectedPlatformAsync.fulfilled), (state, action) => {
      state.fetchedConnectedPlatformLoading = false
      state.fetchedConnectedPlatformData = action?.payload?.data
    })
    builder.addMatcher(isAnyOf(fetchConnectedPlatformAsync.rejected), (state) => {
      state.fetchedConnectedPlatformLoading = false
    })
  },
  reducers: {
    setFetchedConnectedPlatformData: () => initialState,
  },
});

export const {setFetchedConnectedPlatformData} = fetchConnectedPlatformSlice.actions
export default fetchConnectedPlatformSlice.reducer;
