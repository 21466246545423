import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import AppDateFilter from 'src/utils/AppDateFilter';
import moment from 'moment';

interface ReportTableProps {
    columns: { name: string; selector: (row: any) => any; sortable?: boolean; cell?: (row: any) => any; center?: boolean; }[];
    data: any[];
    task: string;
    executionTime: string;
}

const ReportTable: FC<ReportTableProps> = ({ columns, data, task, executionTime }) => {
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    const [paginationData, setPaginationData] = useState({
        page_num: 1,
        page_limit: 10,
        from_date: '',
        to_date: '',
        q: '',
        sync_status: '',
        sort_direction: '', // DES for descending
        sort_by: '',
    });

    return (
        <div>
            {/* <div className='my-2'>
        <Link to={'/listing-channels'} className='btn btn-sm btn-outline btn-outline-primary'>
          <i className="bi bi-arrow-left-circle-fill fs-2"></i> Go Back
        </Link>
      </div> */}
            <div className='card p-4 bg-white ' style={{ height: '76vh' }}>
                <p className='page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-2'>
                    Task Report
                </p>
                <div className='card-header align-items-center gap-2 gap-md-5'>
                    {/* <div className='card-title'>
                        <div className='d-flex align-items-center position-relative my-1'> */}
                    {/* <div className='card-header d-flex flex-column gap-2 gap-md-5'> */}
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <p><strong>Task:</strong> {task}</p>
                            <p><strong>Execution Time:</strong> {moment(executionTime).format('DD-MM-YYYY HH:mm:ss')}</p>
                        </div>
                        {/* </div> */}
                    </div>
                    {/* <div className='card-title'>
                        <div className='d-flex align-items-center position-relative my-1'>
                            <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-4'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                            </i>{' '}
                            <input
                                type='text'
                                data-kt-ecommerce-product-filter='search'
                                className='form-control form-control-solid form-control-sm w-250px ps-12'
                                placeholder='Search Inventory'
                                value={paginationData.q}
                                onChange={(ev) => {
                                    setPaginationData((pre) => ({ ...pre, q: ev.target.value }));
                                }}
                            />
                        </div>
                    </div> */}
                    {/* <div className='card-toolbar flex-row-fluid justify-content-end gap-2 gap-md-5'>
                        <div className='w-100 w-md-50 mw-200px '>
                            <button
                                className='form-select form-select-solid form-select-sm'
                                onClick={() => setShowDatePicker(true)}
                            >
                                {paginationData.from_date || paginationData.to_date
                                    ? moment(paginationData.from_date).format('DD/MMM/YY') +
                                    ' - ' +
                                    moment(paginationData.to_date).format('DD/MMM/YY')
                                    : 'Pick Date Range'}
                            </button>
                        </div>
                        <button
                            className='btn btn-info btn-sm'
                            onClick={() => {
                                setPaginationData({
                                    page_num: 1,
                                    page_limit: 10,
                                    from_date: '',
                                    to_date: '',
                                    q: '',
                                    sync_status: '',
                                    sort_direction: '', // DES for descending
                                    sort_by: '',
                                });
                            }}
                        >
                            Reset Filters
                        </button>
                    </div> */}
                </div>
                <div className='card-body'>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        paginationServer
                        paginationPerPage={10}
                        onChangePage={(pageNum) => {
                            setPaginationData((pre) => {
                                return { ...pre, page_num: pageNum };
                            });
                        }}
                        onChangeRowsPerPage={(currentRowPerPage) => {
                            setPaginationData((pre) => {
                                return { ...pre, page_limit: currentRowPerPage };
                            });
                        }}
                        customStyles={{
                            table: {
                                style: { height: '50vh' },
                            },
                        }}
                    />
                </div>
            </div>
            <AppDateFilter
                isVisible={showDatePicker}
                handleClose={() => setShowDatePicker(false)}
                onPickRange={(dateRange) => {
                    setPaginationData((pre) => ({
                        ...pre,
                        from_date: dateRange.startDate,
                        to_date: dateRange.endDate,
                    }));
                }}
            />
        </div>
    );
};

export default ReportTable;
