import { FC, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import clsx from 'clsx';
import { useAppDispatch } from 'src/redux/storeHooks';
import { createPlatformAsync, fetchUserAvailableDbAsync, fetchUserDbAsync } from 'src/redux/actions/platformActions';
import useAppToast from 'src/hooks/useAppToast';
import {jwtDecode} from 'jwt-decode';
import AddNewDatabase from '../../template-and-pricing/components/AddNewDatabase';
// import DatabaseOptionModal from '../../template-and-pricing/components/DatabaseOptionModal';

// // type AddNewPlatformPropsType = {
// //     isVisible: boolean;
//     closeModal: () => void;
    // onPlatformAdded: () => void;
// // };

// // const initialValues = {
// //     platformName: '',
// //     identifier: '',
// //     shopToken: '',
// //     database: '',
// //     databaseType : '',
// // };

// // const creationSchemaPlatform = Yup.object().shape({
// //     platformName: Yup.string()
// //         .min(2, 'Minimum 2 characters')
// //         .max(50, 'Maximum 50 characters')
// //         .matches(/^[a-zA-Z0-9- ]*$/, 'Only alphanumeric characters, spaces, and hyphens are allowed')
// //         .required('Platform name is required'),

// //     shopToken: Yup.string()
// //         .min(10, 'Token seems too short')
// //         .matches(/^[a-zA-Z0-9_\-/]*$/, 'Token must be alphanumeric')
// //         .required('Access token is required'),

// //     databaseType: Yup.string().required('Database type is required'),
// //     database: Yup.string().when('databaseType', {
// //         is: 'OWN',
// //         then: schema => schema.required('Database is required'),
// //         otherwise: schema => schema.notRequired(),
// //       }),

// // });

// // const ConnectNewPlatform: FC<AddNewPlatformPropsType> = ({ isVisible, closeModal, onPlatformAdded }) => {
// //     interface CustomJwtPayload {
// //         user_id?: number;
// //     }

// //     const token = sessionStorage.getItem('access_token') || '';
// //     const decoded_token = jwtDecode<CustomJwtPayload>(token);
// //     const decoded_user_id = decoded_token?.user_id;

// //     const [loading, setLoading] = useState(false);
// //     const [databases, setDatabases] = useState<{ id: number; db_name: string }[]>([]);
// //     const { successToast, errorToast } = useAppToast();
// //     // const [showDatabaseOptionModal, setShowDatabaseOptionModal] = useState(false);
// //     const [showDatabaseModel, setShowDatabaseModel] = useState(false);
// //     const [optionselected, setOptionSelected] = useState<string>("OWN");
// //     const dispatch = useAppDispatch();

// //     useEffect(() => {
// //         const fetchDatabases = async () => {
// //             try {
// //                 const response = await dispatch(fetchUserAvailableDbAsync({ user_id: decoded_user_id as number })).unwrap();
// //                 // console.log("response", response, "\n\nresponse data", response.data )
// //                 if (response ) {
// //                     const databaseList = response.map((db: any) => ({ id: db.id, db_name: db.db_name }));
// //                     setDatabases(databaseList);
// //                 } else {
// //                     throw new Error('No data found');
// //                 }
// //             } catch (error) {
// //                 console.error('Error fetching databases:', error);
// //                 errorToast('Error fetching databases.');
// //             }
// //         };

// //         fetchDatabases();
// //     }, []);



// //     const onClickAddNewDatabase = () => {
// //         console.log('Opening Database Option Modal...');
// //         setShowDatabaseModel(true); 
// //     };

// //     // const handleDatabaseOptionSelected = (choice) => {
// //     //     if (choice === 'own') {
// //     //       setShowDatabaseModel(true); 
// //     //     } else {
// //     //       // Handle connection to Chanakya database here
// //     //       console.log('Connecting to Chanakya database...');
// //     //     }
// //     // };
// //     const handleDatabaseAdded = async () => {
// //         const user_id = decoded_user_id as number
// //         if (user_id) {
// //           await dispatch(fetchUserAvailableDbAsync({ user_id })).unwrap();
// //           setShowDatabaseModel(false);
// //         } else {
// //           console.error('user_id is undefined');
// //         }
// //     };

// //     const formik = useFormik({
// //         initialValues,
// //         // initialTouched: {
// //         //     database: false, // Add this line
// //         //   },
// //         validationSchema: creationSchemaPlatform,
// //         onSubmit: async (values, { setStatus, setSubmitting }) => {
// //             setLoading(true);
// //             try {
// //                 // const database_selected = values.database === 'CHANAKYA' ? 'CHANAKYA' : values.database;

// //                 console.log(
// //                     values.platformName, 
// //                     values.shopToken, 
// //                     decoded_user_id,
// //                     values.database,
// //                     optionselected

// //                 )
// //                 const response = await dispatch(
// //                     createPlatformAsync({
// //                         platformName: values.platformName,
// //                         shopToken: values.shopToken,
// //                         user_id: decoded_user_id as number,
// //                         database: values.database,
// //                         databaseType: optionselected,
// //                     })
// //                 ).unwrap();
// //                 console.log("values", values)
// //                 console.log("response", response)
// //                 if (response && response.status === 'success') {
// //                     successToast(response.status);
// //                     setSubmitting(false);
// //                     closeModal();
// //                     onPlatformAdded();
// //                 } else {
// //                     errorToast(response.status);
// //                     setSubmitting(false);
// //                 }
// //             } catch (error) {
// //                 console.error(error);
// //                 setLoading(false);
// //             } finally {
// //                 setLoading(false);
// //             }
// //         },
// //     });

// //     const handleSelection = (choice: string) => {
// //         setOptionSelected(choice);
// //         // formik.setTouched({ ...formik.touched, database: true }); // Update touched state
// //     };
      

// //     return (
// //         <Modal size='lg' backdrop='static' centered show={isVisible} onHide={closeModal}>
// //             <form onSubmit={formik.handleSubmit}>
// //                 <Modal.Header>
// //                     <Modal.Title>Add new platform</Modal.Title>
// //                     <div className='d-inline-flex'>
// //                         <Button size='sm' className='mx-2' disabled={loading} onClick={closeModal}>
// //                             Close
// //                         </Button>
// //                         <Button size='sm' variant='success' disabled={loading} type='submit'>
// //                             {loading ? 'Saving...' : 'Save Changes'}
// //                         </Button>
// //                     </div>
// //                 </Modal.Header>
// //                 <Modal.Body>
// //                     {formik.status && (
// //                         <div className='mb-lg-15 alert alert-danger'>
// //                             <div className='alert-text font-weight-bold'>{formik.status}</div>
// //                         </div>
// //                     )}

// //                     <div className='mb-8'>
// //                         <label className='form-label'>Platform name</label>
// //                         <input
// //                             type='text'
// //                             placeholder='Enter platform name'
// //                             autoComplete='off'
// //                             {...formik.getFieldProps('platformName')}
// //                             className={clsx('form-control', 'bg-transparent', {
// //                                 'is-invalid': formik.touched.platformName && formik.errors.platformName,
// //                                 'is-valid': formik.touched.platformName && !formik.errors.platformName,
// //                             })}
// //                         />
// //                         {formik.touched.platformName && formik.errors.platformName && (
// //                             <div className='fv-plugins-message-container'>
// //                                 <div className='fv-help-block'>
// //                                     <span role='alert'>{formik.errors.platformName}</span>
// //                                 </div>
// //                             </div>
// //                         )}
// //                     </div>
// //                     <div className='mb-8'>
// //                         <label className='form-label'>Access Token</label>
// //                         <input
// //                             type='text'
// //                             placeholder='Enter access token'
// //                             autoComplete='off'
// //                             {...formik.getFieldProps('shopToken')}
// //                             className={clsx('form-control', 'bg-transparent', {
// //                                 'is-invalid': formik.touched.shopToken && formik.errors.shopToken,
// //                                 'is-valid': formik.touched.shopToken && !formik.errors.shopToken,
// //                             })}
// //                         />
// //                         {formik.touched.shopToken && formik.errors.shopToken && (
// //                             <div className='fv-plugins-message-container'>
// //                                 <div className='fv-help-block'>
// //                                     <span role='alert'>{formik.errors.shopToken}</span>
// //                                 </div>
// //                             </div>
// //                         )}
// //                     </div>

// //                     <div className='mb-8'>
// //                         <label className='form-label'>Select Database type</label>
// //                         <div key={`default-radio`} className="d-flex ">
// //                         <Form.Check
// //                             type="radio"
// //                             id={`own-database`}
// //                             label={`Use Own Database`}
// //                             name="databaseOption"
// //                             onChange={() => handleSelection('OWN')} // Updated to use handleSelection with uppercase 'S'
// //                         />
// //                         <Form.Check
// //                             className='mx-2'
// //                             type="radio"
// //                             id={`chanakya-database`}
// //                             label={`Use Chanakya Database`}
// //                             name="databaseOption"
// //                             onChange={() => handleSelection('CHANAKYA')} // Updated to use handleSelection with uppercase 'S'
// //                         />
// //                         </div>
// //                     </div>

// //                     {optionselected === 'OWN' && ( 
// //                         <div className='mb-8'>
// //                             <label className='form-label'>Use Database</label>
// //                                 <div className='d-flex flex-column'>
// //                                     <select
// //                                         placeholder='Select Database'
// //                                         {...formik.getFieldProps('database')}
// //                                         className={clsx('form-control', 'bg-transparent', {
// //                                             'is-invalid': formik.touched.database && formik.errors.database,
// //                                             'is-valid': formik.touched.database && !formik.errors.database,
// //                                         })}
// //                                     >
// //                                         <option value=''>Select a database</option>
// //                                         {databases.map((db) => (
// //                                             <option key={db.id} value={db.db_name}>
// //                                                 {db.db_name}
// //                                             </option>
// //                                         ))}
// //                                     </select>
// //                                     <div className='mt-3 align-self-end'>
// //                                         Want to connect another Database? 
// //                                         <button 
// //                                             type='button' 
// //                                             onClick={onClickAddNewDatabase} 
// //                                             className='p-1 text-primary bg-transparent border-0'
// //                                             style={{ cursor: 'pointer', textDecoration: 'underline' }}
// //                                         >
// //                                             Add new Database
// //                                         </button>
// //                                     </div>
// //                                 </div>    
// //                         <AddNewDatabase
// //                             isVisible={showDatabaseModel}
// //                             closeModal={() => setShowDatabaseModel(false)}
// //                             onDatabaseAdded={handleDatabaseAdded}
// //                         />
// //                         {/* <DatabaseOptionModal
// //                             isVisible={showDatabaseOptionModal}
// //                             closeModal={() => setShowDatabaseOptionModal(false)}
// //                             onOptionSelected={handleDatabaseOptionSelected}
// //                         /> */}
// //                         {formik.touched.database && formik.errors.database && (
// //                             <div className='fv-plugins-message-container'>
// //                                 <div className='fv-help-block'>
// //                                     <span role='alert'>{formik.errors.database}</span>
// //                                 </div>
// //                             </div>
// //                         )}
// //                     </div>
// //                 )
                    
// //                }

                    


// //                 </Modal.Body>
// //             </form>
// //         </Modal>
// //     );
// // };

// // export default ConnectNewPlatform;




// /* working  code niche vle */


// type AddNewPlatformPropsType = {
//     isVisible: boolean;
//     closeModal: () => void;
//     onPlatformAdded: () => void;
//     selectedPlatform: string; /* tanishq */
// };


// const ConnectNewPlatform: FC<AddNewPlatformPropsType> = ({ isVisible, closeModal, onPlatformAdded,selectedPlatform }) => { /* tanis added selectedPlatform */
//     interface CustomJwtPayload {
//         user_id?: number;
//     }

//     const token = sessionStorage.getItem('access_token') || '';
//     const decoded_token = jwtDecode<CustomJwtPayload>(token);
//     const decoded_user_id = decoded_token?.user_id;

//     const [loading, setLoading] = useState(false);
//     const [databases, setDatabases] = useState<{ id: number; db_name: string }[]>([]);
//     const { successToast, errorToast } = useAppToast();
//     // const [showDatabaseOptionModal, setShowDatabaseOptionModal] = useState(false);
//     const [showDatabaseModel, setShowDatabaseModel] = useState(false);
//     const [optionselected, setOptionSelected] = useState("")
//     const dispatch = useAppDispatch();

// /* tanishq added */

//     const initialValues = {
//         platformName: '',
//         identifier: '',
//         shopToken: '',
//         database: '',
//         databaseType : 'OWN',
//         username: '',  // Add this field   tanishq
//         password: ''   // Add this field tanishq
//     };
    
//     const creationSchemaPlatform = Yup.object().shape({
//         platformName: Yup.string()
//             .min(2, 'Minimum 2 characters')
//             .max(50, 'Maximum 50 characters')
//             .matches(/^[a-zA-Z0-9- ]*$/, 'Only alphanumeric characters, spaces, and hyphens are allowed')
//             .required('Platform name is required'),
    
//         shopToken:['ShipHero'].includes(selectedPlatform) ? Yup.string()
            
//             :Yup.string()
//             .min(10, 'Token seems too short')
//             .matches(/^[a-zA-Z0-9_\-/]*$/, 'Token must be alphanumeric')
//             .required('Access token is required'),
    
//         databaseType: Yup.string().required('Database type is required'),
        
//         database: Yup.string().when('databaseType', {
//             is: 'OWN',
//             then: schema => schema.required('Database is required'),
//             otherwise: schema => schema.notRequired(),
//           }),
    
    
//         //   changes tansihq
    
//         username: Yup.string().when('platformName', {
//             is: 'ShipHero',
//             then: schema => schema.required('Username is required'),
            
//             otherwise: schema => schema.notRequired(),
//         }),
//       //   changes tansihq
    
//         password: Yup.string().when('platformName', {
//             is: 'ShipHero',
//             then: schema => schema.required('Password is required'),
//             otherwise: schema => schema.notRequired(),
//         }),
//     });



//     useEffect(() => {
//         const fetchDatabases = async () => {
//             try {
//                 const response = await dispatch(fetchUserAvailableDbAsync({ user_id: decoded_user_id as number })).unwrap();
//                 console.log("response", response, "\n\nresponse data", response.data )
//                 if (response ) {
//                     const databaseList = response.map((db: any) => ({ id: db.id, db_name: db.db_name }));
//                     setDatabases(databaseList);
//                 } else {
//                     throw new Error('No data found');
//                 }
//             } catch (error) {
//                 console.error('Error fetching databases:', error);
//                 errorToast('Error fetching databases.');
//             }
//         };

//         fetchDatabases();
//     }, []);



//     const onClickAddNewDatabase = () => {
//         console.log('Opening Database Option Modal...');
//         setShowDatabaseModel(true); 
//     };

//     // const handleDatabaseOptionSelected = (choice) => {
//     //     if (choice === 'own') {
//     //       setShowDatabaseModel(true); 
//     //     } else {
//     //       // Handle connection to Chanakya database here
//     //       console.log('Connecting to Chanakya database...');
//     //     }
//     // };

// // tanishq chages
//     const handleDatabaseAdded = async () => {
//         const user_id = decoded_user_id as number
//         if (user_id) {
    
//          const response= await dispatch(fetchUserAvailableDbAsync({ user_id })).unwrap();

//           if (response ) {
//             const databaseList = response.map((db: any) => ({ id: db.id, db_name: db.db_name }));
//             setDatabases(databaseList);
//         } else {
//             throw new Error('No data found');
//         }
//           setShowDatabaseModel(false);
//         } else {
//           console.error('user_id is undefined');
//         }
//     };

//     const formik = useFormik({
//         // initialValues,  /* y clone code k hai  */

//         initialValues: {
//             ...initialValues,
//             platformName: selectedPlatform,
//         },   /* y code testing k lie hai tanishq */

//         validationSchema: creationSchemaPlatform,

//             // tanishq code start hue testing 
//         validateOnChange: true,
//     validateOnBlur: true,
//     validate: values => {
//         const errors: { [key: string]: string } = {};
//         try {
//             creationSchemaPlatform.validateSync(values, { 
//                 abortEarly: false, 
//                 context: { selectedPlatform } 
//             });
//         } catch (err) {
//             if (err instanceof Yup.ValidationError) {
//                 err.inner.forEach((error: Yup.ValidationError) => {
//                     errors[error.path as string] = error.message;
//                 });
//             } else {
//                 console.error('Unexpected error:', err);
//             }
//         }
//         return errors;
//     },
// // code end hue hai testing

//         onSubmit: async (values, { setStatus, setSubmitting }) => {
//             setLoading(true);
//             try {
//                 const database_selected = values.databaseType === 'CHANAKYA' ? 'CHANAKYA' : values.database;
//                 console.log(
//                     values.platformName, 
//                     values.shopToken, 
//                     decoded_user_id,
//                     database_selected,
//                     optionselected

//                 )
//                 const response = await dispatch(
//                     createPlatformAsync({
//                         platformName: values.platformName,
//                         shopToken: values.shopToken,
//                         user_id: decoded_user_id as number,
//                         database: database_selected,
//                         databaseType: optionselected,

//                         // tanishq
//                         username: selectedPlatform === 'ShipHero' ? values.username : undefined,
//                         // tanishq
//                         password: selectedPlatform === 'ShipHero' ? values.password : undefined,

                       
//                     })
//                 ).unwrap();
//                 console.log("values", values)
//                 if (response && response.status === 'success') {
//                     successToast(response.status);
//                     setSubmitting(false);
//                     closeModal();
//                     onPlatformAdded();
//                 } else {
//                     // errorToast(response.status);
//                     // errorToast(response.message || 'An unexpected error occurred');
//                     errorToast(response.message || 'Username exist');
//                     setSubmitting(false);
//                 }
//             } catch (error) {
//                 // console.error(error);
//                 // setLoading(false);
//                 if (error instanceof Error) {
//                     errorToast(error.message || 'An unexpected error occurred');
//                 } else {
//                     // Handle any other types of errors
//                     errorToast('An unexpected error occurred');
//                 }
//                 setSubmitting(false);
//             } finally {
//                 setLoading(false);
//             }
//         },
//     });

//     const handleSelection = (choice: string) => {
//         setOptionSelected(choice); // 'own' or 'chanakya'
        
//     };

  

//     return (
//         <Modal size='lg' backdrop='static' centered show={isVisible} onHide={closeModal}>
//             <form onSubmit={formik.handleSubmit}>
//                 <Modal.Header>
//                     <Modal.Title>Add new platform</Modal.Title>
//                     <div className='d-inline-flex'>
//                         <Button size='sm' className='mx-2' disabled={loading} onClick={closeModal}>
//                             Close
//                         </Button>
//                         <Button size='sm' variant='success' disabled={loading} type='submit'>
//                             {loading ? 'Saving...' : 'Save Changes'}
//                         </Button>
//                     </div>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {formik.status && (
//                         <div className='mb-lg-15 alert alert-danger'>
//                             <div className='alert-text font-weight-bold'>{formik.status}</div>
//                         </div>
//                     )}

//                     <div className='mb-8'>
//                         <label className='form-label'>Platform name</label>
//                         <input
//                             type='text'
//                             placeholder='Enter platform name'
//                             autoComplete='off'
//                             {...formik.getFieldProps('platformName')}
//                             className={clsx('form-control', 'bg-transparent', {
//                                 'is-invalid': formik.touched.platformName && formik.errors.platformName,
//                                 'is-valid': formik.touched.platformName && !formik.errors.platformName,
//                             })}
//                         />
//                         {formik.touched.platformName && formik.errors.platformName && (
//                             <div className='fv-plugins-message-container'>
//                                 <div className='fv-help-block'>
//                                     <span role='alert'>{formik.errors.platformName}</span>
//                                 </div>
//                             </div>
//                         )}
//                     </div>
                 
// {/* tanishq code start upr vle code prod k hai  */}
// {selectedPlatform !== 'ShipHero' && (
//                         <div className='mb-8'>
//                             <label className='form-label'>Access Token</label>
//                             <input
//                                 type='text'
//                                 placeholder='Enter access token'
//                                 autoComplete='off'
//                                 {...formik.getFieldProps('shopToken')}
//                                 className={clsx('form-control', 'bg-transparent', {
//                                     'is-invalid': formik.touched.shopToken && formik.errors.shopToken,
//                                     'is-valid': formik.touched.shopToken && !formik.errors.shopToken,
//                                 })}
//                             />
//                             {formik.touched.shopToken && formik.errors.shopToken && (
//                                 <div className='fv-plugins-message-container'>
//                                     <div className='fv-help-block'>
//                                         <span role='alert'>{formik.errors.shopToken}</span>
//                                     </div>
//                                 </div>
//                             )}
//                         </div>
//                     )}

//                     {/* tanishq code end */}


//                     {selectedPlatform === 'ShipHero' &&(
//     <>
//         <div className='mb-8'>
//             <label className='form-label'>Username</label>
//             <input
//                 type='text'
//                 placeholder='Enter username'
//                 autoComplete='off'
//                 {...formik.getFieldProps('username')}
//                 className={clsx('form-control', 'bg-transparent', {
//                     'is-invalid': formik.touched.username && formik.errors.username,
//                     'is-valid': formik.touched.username && !formik.errors.username,
//                 })}
//             />
//             {formik.touched.username && formik.errors.username && (
//                 <div className='fv-plugins-message-container'>
//                     <div className='fv-help-block'>
//                         <span role='alert'>{formik.errors.username}</span>
//                     </div>
//                 </div>
//             )}
//         </div>
//         <div className='mb-8'>
//             <label className='form-label'>Password</label>
//             <input
//                 type='password'
//                 placeholder='Enter password'
//                 autoComplete='off'
//                 {...formik.getFieldProps('password')}
//                 className={clsx('form-control', 'bg-transparent', {
//                     'is-invalid': formik.touched.password && formik.errors.password,
//                     'is-valid': formik.touched.password && !formik.errors.password,
//                 })}
//             />
//             {formik.touched.password && formik.errors.password && (
//                 <div className='fv-plugins-message-container'>
//                     <div className='fv-help-block'>
//                         <span role='alert'>{formik.errors.password}</span>
//                     </div>
//                 </div>
//             )}
//         </div>
//     </>
// )}

//                     <div className='mb-8'>
//                         <label className='form-label'>Select Database type</label>
//                         <div key={`default-radio`} className="d-flex ">
//                             <Form.Check
//                                 // className="mx-2 "
//                                 type="radio"
//                                 id={`own-database`}
//                                 label={`Use Own Database`}
//                                 name="databaseOption"
//                                 onChange={() => handleSelection('OWN')}
//                             />
//                             <Form.Check
//                                 className='mx-2'
//                                 type="radio"
//                                 id={`chanakya-database`}
//                                 label={`Use Chanakya Database`}
//                                 name="databaseOption"
//                                 onChange={() => handleSelection('CHANAKYA')}
//                             />
//                         </div>
//                     </div>

//                     {optionselected === 'OWN' && (
//                     <div className='mb-8'>
//                     <label className='form-label'>Use Database</label>
//                     <div className='d-flex flex-column'>
//                         <select
//                             placeholder='Select Database'
//                             {...formik.getFieldProps('database')}
//                             className={clsx('form-control', 'bg-transparent', {
//                                 'is-invalid': formik.touched.database && formik.errors.database,
//                                 'is-valid': formik.touched.database && !formik.errors.database,
//                             })}
//                         >
//                             <option value=''>Select a database</option>
//                             {databases.map((db) => (
//                                 <option key={db.id} value={db.db_name}>
//                                     {db.db_name}
//                                 </option>
//                             ))}
//                         </select>
//                         <div className='mt-3 align-self-end'>
//                             Want to connect another Database? 
//                             <button 
//                                 type='button' 
//                                 onClick={onClickAddNewDatabase} 
//                                 className='p-1 text-primary bg-transparent border-0'
//                                 style={{ cursor: 'pointer', textDecoration: 'underline' }}
//                             >
//                                 Add new Database
//                             </button>
//                         </div>
//                     </div>    
//                     <AddNewDatabase
//                         isVisible={showDatabaseModel}
//                         closeModal={() => setShowDatabaseModel(false)}
//                         onDatabaseAdded={handleDatabaseAdded}
//                     />
//                     {/* <DatabaseOptionModal
//                         isVisible={showDatabaseOptionModal}
//                         closeModal={() => setShowDatabaseOptionModal(false)}
//                         onOptionSelected={handleDatabaseOptionSelected}
//                     /> */}
//                     {formik.touched.database && formik.errors.database && (
//                         <div className='fv-plugins-message-container'>
//                             <div className='fv-help-block'>
//                                 <span role='alert'>{formik.errors.database}</span>
//                             </div>
//                         </div>
//                     )}

//                 </div>         

// )}
//                 </Modal.Body>
//             </form>
//         </Modal>
//     );
// };

// export default ConnectNewPlatform; 


/* ---------------------------- upr vle code chlte hue code hai ------------------------------------ */








type AddNewPlatformPropsType = {
    isVisible: boolean;
    closeModal: () => void;
    onPlatformAdded: () => void;
    selectedPlatform: string; /* tanishq */
};


const ConnectNewPlatform: FC<AddNewPlatformPropsType> = ({ isVisible, closeModal, onPlatformAdded,selectedPlatform }) => { /* tanis added selectedPlatform */
    interface CustomJwtPayload {
        user_id?: number;
    }

    const token = sessionStorage.getItem('access_token') || '';
    const decoded_token = jwtDecode<CustomJwtPayload>(token);
    const decoded_user_id = decoded_token?.user_id;

    const [loading, setLoading] = useState(false);
    const [databases, setDatabases] = useState<{ id: number; db_name: string }[]>([]);
    const { successToast, errorToast } = useAppToast();
    // const [showDatabaseOptionModal, setShowDatabaseOptionModal] = useState(false);
    const [showDatabaseModel, setShowDatabaseModel] = useState(false);
    const [optionselected, setOptionSelected] = useState("")
    const dispatch = useAppDispatch();

/* tanishq added */

    const initialValues = {
        platformName: '',
        identifier: '',
        shopToken: '',
        database: '',
        databaseType : 'OWN',
        username: '',  // Add this field   tanishq
        password: '',   // Add this field tanishq
        storeName:'',
        selectedPlatform: selectedPlatform,
       
    };
    // console.log("connect",selectedPlatform)
    const creationSchemaPlatform = Yup.object().shape({
        platformName: (['Shopify'].includes(selectedPlatform)) ?  Yup.string()
        :
        Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(50, 'Maximum 50 characters')
            .matches(/^[a-zA-Z0-9- ]*$/, 'Only alphanumeric characters, spaces, and hyphens are allowed')
            .required('Platform name is required') ,
    
        shopToken: ['ShipHero', 'Shopify'].includes(selectedPlatform) ? 
            Yup.string()
                // .required('Access token is required') 
            : Yup.string()
                .min(10, 'Token seems too short')
                .matches(/^[a-zA-Z0-9_\-/]*$/, 'Token must be alphanumeric')
                .required('Access token is required'),
        
    
        databaseType: Yup.string().required('Database type is required'),
    
        database: Yup.string().when('databaseType', {
            is: 'OWN',
            then: schema => schema.required('Database is required'),
            otherwise: schema => schema.notRequired(),
        }),
    
        // Conditional validation for `storeName`
        // storeName: Yup.string().when('platformName', {
        //     is: 'Shopify',
        //     then: schema => schema.required('Store name is required'),
        //     otherwise: schema => schema.notRequired(),
        // }),

        storeName:['Shopify'].includes(selectedPlatform)?Yup.string().required():Yup.string(),

        


    
        // Conditional validation for `username` and `password`
        username:['ShipHero'].includes(selectedPlatform)?Yup.string().required():Yup.string(),
        
        
        password:['ShipHero'].includes(selectedPlatform)?Yup.string().required():Yup.string(),
    });

    useEffect(() => {
        const fetchDatabases = async () => {
            try {
                const response = await dispatch(fetchUserAvailableDbAsync({ user_id: decoded_user_id as number })).unwrap();
                console.log("response", response, "\n\nresponse data", response.data )
                if (response ) {
                    const databaseList = response.map((db: any) => ({ id: db.id, db_name: db.db_name }));
                    setDatabases(databaseList);
                } else {
                    throw new Error('No data found');
                }
            } catch (error) {
                console.error('Error fetching databases:', error);
                errorToast('Error fetching databases.');
            }
        };

        fetchDatabases();
    }, []);



    const onClickAddNewDatabase = () => {
        console.log('Opening Database Option Modal...');
        setShowDatabaseModel(true); 
    };

    // const handleDatabaseOptionSelected = (choice) => {
    //     if (choice === 'own') {
    //       setShowDatabaseModel(true); 
    //     } else {
    //       // Handle connection to Chanakya database here
    //       console.log('Connecting to Chanakya database...');
    //     }
    // };

// tanishq chages
    const handleDatabaseAdded = async () => {
        const user_id = decoded_user_id as number
        if (user_id) {
    
         const response= await dispatch(fetchUserAvailableDbAsync({ user_id })).unwrap();

          if (response ) {
            const databaseList = response.map((db: any) => ({ id: db.id, db_name: db.db_name }));
            setDatabases(databaseList);
        } else {
            throw new Error('No data found');
        }
          setShowDatabaseModel(false);
        } else {
          console.error('user_id is undefined');
        }
    };

    const formik = useFormik({
        // initialValues,  /* y clone code k hai  */

        initialValues: {
            ...initialValues,
            platformName: selectedPlatform,
            selectedPlatform: selectedPlatform
        },   /* y code testing k lie hai tanishq */

        validationSchema: creationSchemaPlatform,

            // tanishq code start hue testing 
        validateOnChange: true,
    validateOnBlur: true,
    validate: values => {
        const errors: { [key: string]: string } = {};
        try {
            creationSchemaPlatform.validateSync(values, { 
                abortEarly: false, 
                context: { selectedPlatform } 
            });
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error: Yup.ValidationError) => {
                    errors[error.path as string] = error.message;
                });
            } else {
                console.error('Unexpected error:', err);
            }
        }
        return errors;
    },

        onSubmit: async (values, { setStatus, setSubmitting }) => {
            console.log('Form values before submission:', values);
            setLoading(true);
            try {
                const database_selected = values.databaseType === 'CHANAKYA' ? 'CHANAKYA' : values.database;
                console.log(
                    values.platformName, 
                    values.shopToken, 
                    decoded_user_id,
                    database_selected,
                    optionselected

                )
                
                // alert("checking comments")
                const response = await dispatch(
                    createPlatformAsync({
                        platformName: values.platformName,
                        
                        shopToken: values.shopToken,
                        user_id: decoded_user_id as number,
                        database: database_selected,
                        databaseType: optionselected,

                        // tanishq
                        username: selectedPlatform === 'ShipHero' ? values.username : undefined,
                        // tanishq
                        password: selectedPlatform === 'ShipHero' ? values.password : undefined,
                        storeName: selectedPlatform === 'Shopify' ? values.storeName : undefined,
                        selectedPlatform: selectedPlatform
                       
                    })
                ).unwrap();
                console.log("values", values)
                // console.log("testing errors",response)
                if (response && response.status === 'success') {
                    successToast(response.status);
                    setSubmitting(false);
                    closeModal();
                    onPlatformAdded();
                } else {
                    // errorToast(response.status);
                    // errorToast(response.message || 'An unexpected error occurred');
                    errorToast("response.message" || 'Username exist');
                    setSubmitting(false);
                }
             
            } catch (error:any) {
                console.error("error message",error);
                // setLoading(false);
                if (error) {
                    errorToast(error?.response?.data?.message || 'An unexpected error occurred');
                    
                } else {
                    // Handle any other types of errors
                    errorToast('An unexpected error occurred');
                }
                setSubmitting(false);
            } finally {
                setLoading(false);
            }
        },
    });

    const handleSelection = (choice: string) => {
        setOptionSelected(choice); // 'own' or 'chanakya'
        
    };

    console.log('Selected Platform:', selectedPlatform);
  

    console.log("not submit",formik.errors)
    return (
        <Modal size='lg' backdrop='static' centered show={isVisible} onHide={closeModal}>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header>
                    <Modal.Title>Add new platform</Modal.Title>
                    <div className='d-inline-flex'>
                        <Button size='sm' className='mx-2' disabled={loading} onClick={closeModal}>
                            Close
                        </Button>
                        <Button size='sm' variant='success' disabled={loading} type='submit'>
                            {loading ? 'Saving...' : 'Save Changes'}
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {formik.status && (
                        <div className='mb-lg-15 alert alert-danger'>
                            <div className='alert-text font-weight-bold'>{formik.status}</div>
                        </div>
                    )}
    
    
                    {/* Conditionally render Platform Name field */}
                    {selectedPlatform !== 'Shopify' && (
                        <div className='mb-8'>
                            <label className='form-label'>Platform name</label>
                            <input
                                type='text'
                                placeholder='Enter platform name'
                                autoComplete='off'
                                {...formik.getFieldProps('platformName')}
                                className={clsx('form-control', 'bg-transparent', {
                                    'is-invalid': formik.touched.platformName && formik.errors.platformName,
                                    'is-valid': formik.touched.platformName && !formik.errors.platformName,
                                })}
                            />
                            {formik.touched.platformName && formik.errors.platformName && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.platformName}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}


                                            {/* Store Name field for Shopify */}
                    {selectedPlatform === 'Shopify' && (
                        <div className='mb-8'>
                            <label className='form-label'>Store Name</label>
                            <input
                                type='text'
                                placeholder='Enter store name '
                                autoComplete='off'
                                {...formik.getFieldProps('storeName')}
                                className={clsx('form-control', 'bg-transparent', {
                                    'is-invalid': formik.touched.storeName && formik.errors.storeName,
                                    'is-valid': formik.touched.storeName && !formik.errors.storeName,
                                })}
                            />
                            {formik.touched.storeName && formik.errors.storeName && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.storeName}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
    


    
                    {/* Conditional Access Token field */}
                    
                        {selectedPlatform !== 'ShipHero' && (
                              <div className='mb-8'>
                        <label className='form-label'>Access Token</label>
                            <input
                                type='text'
                                placeholder='Enter access token'
                                autoComplete='off'
                                {...formik.getFieldProps('shopToken')}
                                className={clsx('form-control', 'bg-transparent', {
                                    'is-invalid': formik.touched.shopToken && formik.errors.shopToken,
                                    'is-valid': formik.touched.shopToken && !formik.errors.shopToken,
                                })}
                            />
                            </div>
                        )}
                        {formik.touched.shopToken && formik.errors.shopToken && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.shopToken}</span>
                                </div>
                            </div>
                        )}
                    
    
                    {/* ShipHero specific fields */}
                    {selectedPlatform === 'ShipHero' && (
                        <>
                            <div className='mb-8'>
                                <label className='form-label'>Username</label>
                                <input
                                    type='text'
                                    placeholder='Enter username'
                                    autoComplete='off'
                                    {...formik.getFieldProps('username')}
                                    className={clsx('form-control', 'bg-transparent', {
                                        'is-invalid': formik.touched.username && formik.errors.username,
                                        'is-valid': formik.touched.username && !formik.errors.username,
                                    })}
                                />
                                {formik.touched.username && formik.errors.username && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.username}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='mb-8'>
                                <label className='form-label'>Password</label>
                                <input
                                    type='password'
                                    placeholder='Enter password'
                                    autoComplete='off'
                                    {...formik.getFieldProps('password')}
                                    className={clsx('form-control', 'bg-transparent', {
                                        'is-invalid': formik.touched.password && formik.errors.password,
                                        'is-valid': formik.touched.password && !formik.errors.password,
                                    })}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.password}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
    
                    {/* Database type selection */}
                    <div className='mb-8'>
                        <label className='form-label'>Select Database type</label>
                        <div key={`default-radio`} className="d-flex ">
                            <Form.Check
                                type="radio"
                                id={`own-database`}
                                label={`Use Own Database`}
                                name="databaseOption"
                                onChange={() => handleSelection('OWN')}
                            />
                            <Form.Check
                                className='mx-2'
                                type="radio"
                                id={`chanakya-database`}
                                label={`Use Chanakya Database`}
                                name="databaseOption"
                                onChange={() => handleSelection('CHANAKYA')}
                            />
                        </div>
                    </div>
    
                    {optionselected === 'OWN' && (
                        <div className='mb-8'>
                            <label className='form-label'>Use Database</label>
                            <div className='d-flex flex-column'>
                                <select
                                    placeholder='Select Database'
                                    {...formik.getFieldProps('database')}
                                    className={clsx('form-control', 'bg-transparent', {
                                        'is-invalid': formik.touched.database && formik.errors.database,
                                        'is-valid': formik.touched.database && !formik.errors.database,
                                    })}
                                >
                                    <option value=''>Select a database</option>
                                    {databases.map((db) => (
                                        <option key={db.id} value={db.db_name}>
                                            {db.db_name}
                                        </option>
                                    ))}
                                </select>
                                <div className='mt-3 align-self-end'>
                                    Want to connect another Database? 
                                    <button 
                                        type='button' 
                                        onClick={onClickAddNewDatabase} 
                                        className='p-1 text-primary bg-transparent border-0'
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                    >
                                        Add new Database
                                    </button>
                                </div>
                            </div>    
                            <AddNewDatabase
                                isVisible={showDatabaseModel}
                                closeModal={() => setShowDatabaseModel(false)}
                                onDatabaseAdded={handleDatabaseAdded}
                            />
                            {formik.touched.database && formik.errors.database && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.database}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                      {/* Hidden field to store selectedPlatform */}
                      {/* <input type='hidden' name='selectedPlatform' value={formik.values.selectedPlatform} /> */}
                </Modal.Body>
            </form>
        </Modal>
    );
    
    
};

export default ConnectNewPlatform; 





