import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import { getUserInviteListAsync } from '../actions/authActions'

const initialState: {
  invitedUserLoader:boolean
  invitedUserList:any[]
  totalInvitedUser:number
} = {
invitedUserList:[],
invitedUserLoader:false,
totalInvitedUser:0  
}

const invitedUserSlice = createSlice({
  name: 'invitedUserSlice',
  initialState,
  extraReducers: (builder) => {
    // login start
    builder.addMatcher(isAnyOf(getUserInviteListAsync.pending), (state) => {
      console.log('getUserInviteListAsync is in pending state ')
      state.invitedUserLoader = true
    })
    builder.addMatcher(isAnyOf(getUserInviteListAsync.rejected), (state) => {
      console.log('getUserInviteListAsync is in rejected state ')
      state.invitedUserLoader = false
    })
    builder.addMatcher(isAnyOf(getUserInviteListAsync.fulfilled), (state, action) => {
        if(action.payload?.results){
            state.invitedUserList = action.payload?.results
            state.totalInvitedUser = action.payload?.total_records
        }
        state.invitedUserLoader = false    
    })

   
  },
  reducers: {},
})

export const {} = invitedUserSlice.actions

export default invitedUserSlice.reducer
