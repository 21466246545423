import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { UserAgentSetting } from 'src/interfaces/Platform.interface'; // Ensure you have this interface defined
import { fetchConnectedAgentAsync } from '../actions/gptModelsActions';

type FetchConnectedAgentsPayload = {
  fetchedAgentsData: UserAgentSetting[] | [];
  fetchedAgentsLoading: boolean;
};

const initialState: FetchConnectedAgentsPayload = {
  fetchedAgentsData: [],
  fetchedAgentsLoading: false,
};

const fetchConnectedAgentsSlice = createSlice({
  name: 'fetchConnectedAgents',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(fetchConnectedAgentAsync.pending), (state) => {
      state.fetchedAgentsLoading = true;
    });
    builder.addMatcher(isAnyOf(fetchConnectedAgentAsync.fulfilled), (state, action) => {
      // console.log("payload", action.payload)
      state.fetchedAgentsData = action.payload; 
      state.fetchedAgentsLoading = false;
    });
    builder.addMatcher(isAnyOf(fetchConnectedAgentAsync.rejected), (state) => {
      state.fetchedAgentsLoading = false; 
    });
  },
  reducers: {
    resetFetchedAgentsData: (state) => {
      state.fetchedAgentsData = [];
      state.fetchedAgentsLoading = false;
    },
  },
});

export const { resetFetchedAgentsData } = fetchConnectedAgentsSlice.actions;
export default fetchConnectedAgentsSlice.reducer;
