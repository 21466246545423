// availablePlatformsSlice.js

import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchAvailablePlatformsAsync } from "../actions/platformActions";
import { PlatformInfoType } from "src/interfaces/Platform.interface";

type AvailablePlatformsState = {
  loading: boolean,
  data: PlatformInfoType[] | null,
  error: string | null,
}

const initialState: AvailablePlatformsState = {
  loading: false,
  data: null,
  error: null,
};

const availablePlatformsSlice = createSlice({
  name: "availablePlatforms",
  initialState,
  reducers: {
    resetAvailablePlatforms: () => initialState, // Reset state to initial
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailablePlatformsAsync.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error on new fetch
      })
      .addCase(fetchAvailablePlatformsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data || [];
      })
      .addCase(fetchAvailablePlatformsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch available platforms";
      });
  },
});

export const { resetAvailablePlatforms } = availablePlatformsSlice.actions;
export default availablePlatformsSlice.reducer;
