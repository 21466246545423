import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosClient from "../AxiosClient";
import { PlatformSettingsDataType, connectedPlatformPayload } from "src/interfaces/Platform.interface";
import axios from 'axios';


export const fetchPlatformsAsync = createAsyncThunk(
  "platform/fetchPlatforms",
  async function (payload, toolkit) {
    return await AxiosClient("GET", "api/available-platform", [], toolkit, [])
  }
);

export const createPlatformAsync = createAsyncThunk(
  "platform/createNewPlatform",
  async function (payload: {
    platformName: string,
    // identifier: string,
    shopToken: string,
    user_id: number,
    database: string,
    databaseType: string,
    username?: string;  // Optional tanishq
    password?: string;  // Optional tanishq

    storeName?: string; //tanishq added
    accessToken?: string;// tanishq added
    selectedPlatform: string;
  },
    toolkit) {
    return await AxiosClient("POST", "api/create-platform/", payload, toolkit, [])
  }
);

export const createDatabaseFormAsync = createAsyncThunk(
  "database/createNewDatabase",
  async function (payload: {
    host: string,
    name: string,
    user: string,
    password: string
    user_id: number
  },
    toolkit) {
    return await AxiosClient("POST", "api/create-user-db-setting/", payload, toolkit, [])
  }
);

// Async action to connect a new platform
export const connectPlatformAsync = createAsyncThunk(
  "platform/connectPlatform",
  async function (payload: connectedPlatformPayload, toolkit) {
    return await AxiosClient("POST", "api/platform", payload, toolkit, []);
  }
);

export const fetchConnectedPlatformAsync = createAsyncThunk(
  "platform/fetchConnectedPlatform",
  async function (payload: { user_id: number }, toolkit) {
    const url = `api/get-platforms/${payload.user_id}`
    return await AxiosClient("GET", url, [], toolkit, [])
  }
);

export const fetchUserDbAsync = createAsyncThunk(
  "platform/fetchUserDb",
  async function (payload: { user_id: number }, toolkit) {
    const url = `api/get-user-db-setting/${payload.user_id}`
    return await AxiosClient("GET", url, [], toolkit, [])
  }
);
export const fetchUserAvailableDbAsync = createAsyncThunk(
  "platform/fetchUserDb",
  async function (payload: { user_id: number }, toolkit) {
    const url = `api/get-available-user-db-setting/${payload.user_id}`
    return await AxiosClient("GET", url, [], toolkit, [])
  }
);


export const fetchConnectedDatabaseAsync = createAsyncThunk(
  "platform/fetchConnectedDatabase",
  async function (payload: { user_id: number }, toolkit) {
    const url = `api/get-user-db-setting/${payload.user_id}`
    return await AxiosClient("GET", url, [], toolkit, [])
  }
);

export const deletePlatformAsync = createAsyncThunk(
  "platform/deletePlatform",
  async function (payload: { id: number }, toolkit) {
    const url = `api/delete-platforms/${payload.id}`
    return await AxiosClient("DELETE", url, [], toolkit, [])
  }

);

export const deleteDatabaseAsync = createAsyncThunk(
  "platform/deleteDatabase",
  async function (payload: { id: number }, toolkit) {
    const url = `api/delete-user-db-setting/${payload.id}`
    return await AxiosClient("DELETE", url, [], toolkit, [])
  }

);

export const retrivePlatformDataAsync = createAsyncThunk(
  "platform/retrivePlatformData",
  async function (payload: { id: number }, toolkit) {
    const url = `/api/get-platform-sync-settings/${payload.id}`;
    return await AxiosClient("GET", url, [], toolkit, [])
  }
);

// export const retrivePlatformSettingAsync = createAsyncThunk(
//   "platform/retrivePlatformData",
//   async function (payload: { id: number }, toolkit) {
//     const url = `/api/get-platform-sync-settings/${payload.id}`;
//     return await AxiosClient("GET", url, [], toolkit, [])
//   }
// );

export const createPlatformSettingAsync = createAsyncThunk(
  "platform/createPlatformSetting",
  async function (payload: PlatformSettingsDataType, toolkit) {
    const url = `/api/create-platform-sync-settings/${payload.platformId}/`;
    delete payload.platformId;

    return await AxiosClient("POST", url, payload, toolkit, [])
  }
);

export const fetchAvailablePlatformsAsync = createAsyncThunk(
  "platform/fetchAvailablePlatforms",
  async function (payload, toolkit) {
    const url = `api/get-available-platforms/`
    return await AxiosClient("GET", url, [], toolkit, [])
  }
);

export const askQueryAsync = createAsyncThunk(
  "agent/askQuery",
  async function (payload: { userQuery: string }, toolkit) {
    return await AxiosClient("POST", "api/ask-question/", payload, toolkit, []);
  }
);

// export const setInstructionsAsync = createAsyncThunk(
//   "agent/setInstruction",
//   async function (payload: { user_id: number, instructions: string, update_after: string }, toolkit) {
//     console.log("in here")
//     return await AxiosClient("POST", "api/create-instructions", payload, toolkit, []);
//   }
// );
export const createInstructionsAsync = createAsyncThunk(
  "agent/setInstruction",
  async function (payload: { user_id: number, instructions: string, update_after: string }, toolkit) {
    console.log("in here")
    return await AxiosClient("POST", "api/create-instructions/", payload, toolkit, []);
  }
);
// export const setInstructionsAsync = createAsyncThunk(
//   "agent/setInstruction",
//   async function (payload:  {user_id : number, instructions: string, update_after: string}, toolkit) {
//     return await AxiosClient("POST", "api/generate-ai-response", payload, toolkit, []);
//   }
// );

export const GenrateReportAsync = createAsyncThunk(
  "agent/genrateReport",
  async function (
    payload: { "user_id": number, userEmail: string, timePeriod: string, platform_id: string },
    toolkit
  ) {
    return await AxiosClient("POST", "api/genrate-report", payload, toolkit, []);
  }
);



export const getPlatformOrderData = createAsyncThunk(
  "platform/orderData",
  async function (payload: { selectedPlatformId: number, params: any }, toolkit) {
    return await AxiosClient("GET", `api/get-event-log-data/${payload.selectedPlatformId}`, payload, toolkit, payload.params);
  }
);  


export const getPlatformProductData = createAsyncThunk(
  "platform/orderData",
  async function (payload: { selectedPlatformId: number }, toolkit) {
    return await AxiosClient("GET", `api/get-platform-product-data/${payload.selectedPlatformId}`, payload, toolkit, []);
  }
);


// export const getInventoriesAsync = createAsyncThunk(
//   "platform/inventoryData",
//   async function (params: { selectedPlatformId: number, otherParams: any }, toolkit) {
//     return await AxiosClient("GET", `api/get-event-log-data/${params.selectedPlatformId}`, [], toolkit, params.otherParams);
//   }
// );


export const getInventoriesAsync = createAsyncThunk(
  "platform/inventoryData",
  async function (payload: { selectedPlatformId: number, params: any}, toolkit) {
    return await AxiosClient("GET", `api/get-event-log-data/${payload.selectedPlatformId}`, payload, toolkit, payload.params);
  }
);






export const getAllInstructionsAsync = createAsyncThunk(
  "platform/allInstructions",
  async function (params: { id: number, otherParams: any | null }, toolkit) {
    return await AxiosClient("GET", `api/get-instructions/${params.id}`, [], toolkit, params.otherParams);
  }
);


export const getSingleInstructionAsync = createAsyncThunk(
  "platform/allInstructions",
  async function (params: { id: number, otherParams: any | null }, toolkit) {
    return await AxiosClient("GET", `api/get-single-instruction/${params.id}`, [], toolkit, params.otherParams);
  }
);

// export const UpdateInstructionAsync = createAsyncThunk(
//   "platform/allInstructions",
//   async function (params: { id: number, otherParams: any | null }, toolkit) {
//     console.log("in updated instructions function")
//     return await AxiosClient("GET", `api/update-single-instruction/${params.id}`, [], toolkit, params.otherParams);
//   }
// );

export const UpdateInstructionAsync = createAsyncThunk(
  "platform/updateInstruction",
  async function (params: { id: number, payload: any }, toolkit) {
    console.log("in updated instructions function");
    return await AxiosClient("PUT", `api/update-single-instruction/${params.id}`, [], toolkit, params.payload);
  }
);


export const deleteInstructionAsync = createAsyncThunk(
  "platform/allInstructions",
  async function (params: { id: number, otherParams: any | null }, toolkit) {
    return await AxiosClient("DELETE", `api/delete-single-instruction/${params.id}`, [], toolkit, params.otherParams);
  }
);


export const fetchConnectedPlatformSettingAsync = createAsyncThunk(
  "platform/fetchConnectedPlatformSettingAsync",
  async function (params: { platform_id: number }, toolkit) {
    const url = `api/get-platform-sync-settings/${params.platform_id}`
    return await AxiosClient("GET", url, [], toolkit, [])
  }
);
