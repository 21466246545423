import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { askQueryAsync } from '../actions/platformActions'; 


interface ChatMessage {
  text: string;
  sender: 'user' | 'bot';
}


interface ChatState {
  messages: ChatMessage[];
  isLoading: boolean;
  error: string | null;
}

const initialState: ChatState = {
  messages: [{ text: 'Hello! How can I assist you today?', sender: 'bot' }], // Initial bot greeting
  isLoading: false,
  error: null,
};

const agentSlice = createSlice({
  name: 'chatWithBot',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<ChatMessage>) => {
      state.messages.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(askQueryAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(askQueryAsync.fulfilled, (state, action: PayloadAction<{
        response
        : string
      }>) => {
        state.isLoading = false;
        console.log("action", action.payload.response)
        state.messages.push({
          text: action.payload.response
          , sender: 'bot'
        });
      })
      .addCase(askQueryAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to send message to the chatbot.';
      });
  },
});

export const { addMessage } = agentSlice.actions;
export const selectChatMessages = (state) => state.chatWithBot.messages;
export default agentSlice.reducer;
