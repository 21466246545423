/* eslint-disable react/jsx-no-target-blank */

const SidebarFooter = () => {
  return (
    <>
    
    </>
    
  )
}

export {SidebarFooter}
