import React, {FC, ReactNode} from 'react'
import {Modal} from 'react-bootstrap'

type AlertModalPropsType = {
  isVisible: boolean
  closeModal: () => void
  onConfirm: () => void
  loading?:boolean
  title?:string
  subtitle?:ReactNode
}

const AlertModal: FC<AlertModalPropsType> = ({isVisible, closeModal, onConfirm, loading=false, title='Are you sure?', subtitle="you won't be able to revert this!"  }) => {
  return (
    <Modal show={isVisible} centered onHide={closeModal}>
      <div>
        <div className='alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10'>
          <button
            type='button'
            className='position-absolute top-0 end-0 m-2 btn btn-icon btn-icon-danger'
            data-bs-dismiss='alert'
          >
            <span className='svg-icon svg-icon-1'></span>
          </button>

          <span className='svg-icon svg-icon-5tx svg-icon-danger mb-5'></span>

          <div className='text-center'>
            <h5 className='fw-bolder fs-2 mb-5'>{title}</h5>

            <div className='separator separator-dashed border-danger opacity-25 mb-5'></div>

            <div className='mb-9'>
              {subtitle}
            </div>
            <div className='d-flex flex-center flex-wrap'>
              <button
                className='btn btn-outline btn-outline-danger btn-active-danger m-2'
                onClick={closeModal}
                disabled={loading}
              >
                Cancel
              </button>
              <button className='btn btn-danger m-2' disabled={loading} onClick={() => {
                  onConfirm(); 
                  // closeModal();
                }}>
                Yes, delete it!
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AlertModal
