import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {loginAsync, registrationAsync} from '../actions/authActions'
import {USER_DATA} from 'src/app/_interfaces/auth.interface'

type LOGIN_PAYLOAD = {
  isLoggedIn: boolean
  userData: {first_name:string, last_name:string, email:string} | null
  status?: number; 
  
}

const initialState: {
  authLoading: boolean
  isLoggedIn: boolean
  userDetail: null |  {first_name:string, last_name:string, email:string}
  status?: number; 
} = {
  authLoading: false,
  isLoggedIn: false,
  userDetail: null,
  status:undefined
  
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  extraReducers: (builder) => {
    // login start
    builder.addMatcher(isAnyOf(loginAsync.pending), (state) => {
      console.log('loginAsync is in pending state ')
    })
    builder.addMatcher(isAnyOf(loginAsync.rejected), (state) => {
      console.log('loginAsync is in rejected state ')
    })
    builder.addMatcher(isAnyOf(loginAsync.fulfilled), (state, action) => {
      

      
      // state.userDetail = action.payload.data
      if(action?.payload?.data.access){
         state.isLoggedIn = true
      }
      
      state.status = action?.payload?.payload?.status; 
      state.userDetail = {
        first_name: action?.payload?.data.user_first_name,
        email: action?.payload?.data.user_email ,
        last_name: action?.payload?.data.user_last_name
      }
     
    })

    // registration end
    builder.addMatcher(isAnyOf(registrationAsync.pending), (state) => {
      console.log('registrationAsync is in pending state ')
    })
    builder.addMatcher(isAnyOf(registrationAsync.rejected), (state) => {
      console.log('registrationAsync is in rejected state ')
    })
    builder.addMatcher(isAnyOf(registrationAsync.fulfilled), (state, action) => {
      state.authLoading = false
    })
    // registration end
  },
  reducers: {
    // use this reducer for login logout
    setLoggingData: (state, action: {payload: LOGIN_PAYLOAD; type: string}) => {
      state.isLoggedIn = action.payload?.isLoggedIn
      state.userDetail = action.payload.userData
    },
  },
})

export const {setLoggingData} = authSlice.actions

export default authSlice.reducer
