// import React, { FC, useState, useEffect } from 'react';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider, DateTimePicker, DateTimePickerSlots } from '@mui/x-date-pickers';
// import dayjs, { Dayjs } from 'dayjs';
// import utc from 'dayjs/plugin/utc'; // Import UTC plugin
// import TextField from '@mui/material/TextField';
// import Box from '@mui/material/Box';
// import Tooltip from '@mui/material/Tooltip';
// import InfoIcon from '@mui/icons-material/Info';

// dayjs.extend(utc);

// // Custom text field component
// const CustomTextField: React.FC<any> = (props) => {
//   return (
//     <TextField
//       {...props}
//       variant="outlined"
//       fullWidth
//       InputProps={{
//         style: {
//           fontSize: '1rem',
//         },
//         endAdornment: props.InputProps?.endAdornment,
//       }}
//       InputLabelProps={{
//         style: {
//           fontSize: '1rem',
//         },
//       }}
//     />
//   );
// };

// // Custom slots configuration
// const CustomDateTimePickerSlots: DateTimePickerSlots<Dayjs> = {
//   textField: (props) => <CustomTextField {...props} />
// };

// interface ShipHeroSettingsProps {
//   settings: {
//     showPurchaseOrder: boolean;
//     showInventory: boolean;
//     showTransferOrder: boolean;
//     purchaseOrderDateTime?: string;
//     transferOrderDateTime?: string;
//   };
//   setSettings: (settings: {
//     showPurchaseOrder?: boolean;
//     showInventory?: boolean;
//     showTransferOrder?: boolean;
//     purchaseOrderDateTime?: string;
//     transferOrderDateTime?: string;
//   }) => void;
//   platformId: number;
//   isReadonly: boolean;
//   onDateChange: (field: string, date: Date | null) => void
// }

// const ShipHeroSettings: FC<ShipHeroSettingsProps> = ({ settings, setSettings, platformId, isReadonly }) => {
//   // Initialize state with UTC date
//   console.log("checking const value",settings)
//   const [purchaseOrderDateTime, setPurchaseOrderDateTime] = useState<Dayjs | null | string> (
//      null
//   );
//   const [transferOrderDateTime, setTransferOrderDateTime] = useState<Dayjs | null | string>(
//     null
//   );

//   useEffect(() => {
//     console.log("checking setting",settings)
//     // Convert to UTC when settings change
//     // setPurchaseOrderDateTime(settings.purchaseOrderDateTime ? dayjs.utc(settings.purchaseOrderDateTime) : null);
//     // setTransferOrderDateTime(settings.transferOrderDateTime ? dayjs.utc(settings.transferOrderDateTime) : null);
  
//     console.log("date time check ")
//     console.log(purchaseOrderDateTime)
//   }, [settings]);


//   const handlePurchaseOrderDateChange = (date: Dayjs | null) => {
//     if (date && date.isValid()) {
//       setPurchaseOrderDateTime(date.utc(true).toString());
//       console.log("------ checking datetine t",date.toString())
//       setSettings({
//         ...settings,
//         purchaseOrderDateTime: date.utc(true).toString(), // Convert to UTC before saving
       
//       });
//     }
//   };

//   const handleTransferOrderDateChange = (date: Dayjs | null) => {
//     if (date && date.isValid()) {
//       setTransferOrderDateTime(date.utc(true).toString());
//       console.log("------ checking datetine t",date)
//       setSettings({
//         ...settings,
//         transferOrderDateTime: date.utc(true).toString(), // Convert to UTC before saving
//       });
//     }
//   };

//   const now = dayjs.utc(); // Use UTC for current time
// console.log("check time",purchaseOrderDateTime,settings["purchaseOrderDateTime"])


//   return (
//     <>
//       {/* Purchase Order Section */}
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Purchase Order</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <div className='form-check form-switch form-check-custom form-check-solid me-10'>
//             <label className='form-check-label text-dark h4' htmlFor='purchaseOrderSync'>
//               Sync Purchase Order
//             </label>
//             <input
//               className='ms-8 form-check-input h-20px w-40px'
//               type='checkbox'
//               id='purchaseOrderSync'
//               checked={settings.showPurchaseOrder}
//               onChange={() => setSettings({
//                 ...settings,
//                 showPurchaseOrder: !settings.showPurchaseOrder
//               })}
//               // disabled={isReadonly}
//             />
//           </div>
//           {settings.showPurchaseOrder && (
//             <Box sx={{ marginTop: '20px', width: '100%', maxWidth: '500px' }}>
//               <div style={{ marginBottom: '8px', fontSize: '1rem', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
//                 Select Purchase Order Start Date(UTC)
//                 <Tooltip
//                   title={
//                     <div style={{ 
//                       backgroundColor: '#f0f0f0', 
//                       color: '#000', 
//                       fontSize: '0.875rem', 
//                       padding: '8px', 
//                       maxWidth: '300px',
//                       wordBreak: 'break-word',
//                       borderRadius: '4px' // Optional, if you want rounded corners
//                     }}>
//                       <h3 style={{ fontSize: '1rem', margin: '0 0 8px' }}>This date you can choose from which you want to fetch the order.</h3>
//                       <h3 style={{ fontSize: '1.2rem', margin: '0' }}><strong>Note:</strong> Once saved this date cannot be changed. If you want to change the date, you can connect Support or create a new connection.</h3>
//                     </div>
//                   }
//                   arrow
//                 >
//                   <InfoIcon sx={{ marginLeft: '8px', fontSize: '2rem', color: '#6c757d' }} />
//                 </Tooltip>
//               </div>
//               <LocalizationProvider dateAdapter={AdapterDayjs}>
//                 <DateTimePicker
//                   value={(purchaseOrderDateTime==null && settings["purchaseOrderDateTime"])?dayjs.utc(settings["purchaseOrderDateTime"]):purchaseOrderDateTime?dayjs.utc(purchaseOrderDateTime):null}
//                   onChange={(day)=>{
//                     console.log("----------handledate-----------",day?.utc(true).toString())
//                     handlePurchaseOrderDateChange(day)
//                   }}
//                   format="DD-MMM-YY, HH:mm  "
//                   slots={CustomDateTimePickerSlots}
//                   readOnly={isReadonly}
                  
//                   ampm={false} // Ensures 24-hour format
//                   minDate={undefined} // Allow past and present dates
//                   maxDate={now} // Restrict to present and past dates
//                   disabled={(purchaseOrderDateTime==null && settings["purchaseOrderDateTime"])?Boolean(settings["purchaseOrderDateTime"]):false}
//                   closeOnSelect={true}
//                   slotProps={{
//                     textField: { 
//                       fullWidth: true,
//                       // inputFormat: "YYYY-MM-DD HH:mm", // Format for display
//                     }
//                   }}
//                 />
//               </LocalizationProvider>
//             </Box>
//           )}
//         </div>
//       </div>

//       {/* Inventory Section */}
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Inventory</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <div className='form-check form-switch form-check-custom form-check-solid me-10'>
//             <label className='form-check-label text-dark h4' htmlFor='inventorySync'>
//               Sync Inventory
//             </label>
//             <input
//               className='ms-8 form-check-input h-20px w-40px'
//               type='checkbox'
//               id='inventorySync'
//               checked={settings.showInventory}
//               onChange={() => setSettings({
//                 ...settings,
//                 showInventory: !settings.showInventory
//               })}
//               // disabled={isReadonly}
//             />
//           </div>
//         </div>
//       </div>

//       {/* Transfer Order Section */}
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Transfer Order</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <div className='form-check form-switch form-check-custom form-check-solid me-10'>
//             <label className='form-check-label text-dark h4' htmlFor='transferOrderSync'>
//               Sync Transfer Order
//             </label>
//             <input
//               className='ms-8 form-check-input h-20px w-40px'
//               type='checkbox'
//               id='transferOrderSync'
//               checked={settings.showTransferOrder}
//               onChange={() => setSettings({
//                 ...settings,
//                 showTransferOrder: !settings.showTransferOrder
//               })}
//               // disabled={isReadonly}
//             />
//           </div>
//           {settings.showTransferOrder && (
//             <Box sx={{ marginTop: '20px', width: '100%', maxWidth: '500px' }}>
//               <div style={{ marginBottom: '8px', fontSize: '1rem', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
//                 Select Transfer Order Start Date(UTC)
//                 <Tooltip
//                   title={
//                     <div style={{ 
//                       backgroundColor: '#f0f0f0', 
//                       color: '#000', 
//                       fontSize: '0.875rem', 
//                       padding: '8px', 
//                       maxWidth: '300px',
//                       wordBreak: 'break-word',
//                       borderRadius: '4px' // Optional, if you want rounded corners
//                     }}>
//                       <h2 style={{ fontSize: '1rem', margin: '0 0 8px' }}>This date you can choose from which you want to fetch the order.</h2>
//                       <h3 style={{ fontSize: '1.2rem', margin: '0' }}><strong>Note:</strong> Once saved this date cannot be changed. If you want to change the date, you can connect Support or create a new connection.</h3>
//                     </div>
//                   }
//                   arrow
//                 >
//                   <InfoIcon sx={{ marginLeft: '8px', fontSize: '2rem', color: '#6c757d' }} />
//                 </Tooltip>
//               </div>
//               <LocalizationProvider dateAdapter={AdapterDayjs}>
//                 <DateTimePicker
//                 value={(transferOrderDateTime==null && settings["transferOrderDateTime"])?dayjs.utc(settings["transferOrderDateTime"]):transferOrderDateTime?dayjs.utc(transferOrderDateTime):null}

//                   // value={dayjs("2024-08-18T22:50:00Z")}
//                   onChange={(day)=>{
//                     console.log("---------- transfer handledate -----------",day?.utc(true).toString())
//                     handleTransferOrderDateChange(day)
//                   }}
//                   slots={CustomDateTimePickerSlots}
//                   readOnly={isReadonly}
//                   ampm={false} // Ensures 24-hour format
//                   minDate={undefined} // Allow past and present dates
//                   maxDate={now} // Restrict to present and past dates
//                   format="DD-MMM-YY, HH:mm  "
//                   disabled={(transferOrderDateTime==null && settings["transferOrderDateTime"])?Boolean(settings["transferOrderDateTime"]):false}

//                   // defaultValue={}
//                   slotProps={{
//                     textField: { 
//                       fullWidth: true,
//                       // inputFormat: "YYYY-MM-DD HH:mm", // Format for display
//                     }
//                   }}
//                 />
//               </LocalizationProvider>
//             </Box>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default ShipHeroSettings;

/* upr vlr code sahi hai ekdm */






/* chlte hue code hai  */

// import React, { FC, useState, useEffect } from 'react';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
// import dayjs, { Dayjs } from 'dayjs';
// import TextField from '@mui/material/TextField';

// // Dummy function to simulate data fetching
// const fetchData = async (dateTime: string) => {
//   console.log(`Fetching data for date-time: ${dateTime}`);
//   return new Promise((resolve) => setTimeout(() => resolve(`Data fetched for ${dateTime}`), 1000));
// };

// const CustomTextField: React.FC<any> = (props) => {
//   return <TextField {...props} />;
// };

// interface ShipHeroSettingsProps {
//   settings: {
//     showPurchaseOrder: boolean;
//     showInventory: boolean;
//     showTransferOrder: boolean;
//     purchaseOrderDateTime?: string;
//     transferOrderDateTime?: string;
//   };
//   setSettings: (settings: {
//     showPurchaseOrder?: boolean;
//     showInventory?: boolean;
//     showTransferOrder?: boolean;
//     purchaseOrderDateTime?: string;
//     transferOrderDateTime?: string;
//   }) => void;
//   platformId: number; // Added to identify which platform settings to update
// }

// const ShipHeroSettings: FC<ShipHeroSettingsProps> = ({ settings, setSettings, platformId }) => {
//   const [purchaseOrderDateTime, setPurchaseOrderDateTime] = useState<Dayjs | null>(
//     settings.purchaseOrderDateTime ? dayjs(settings.purchaseOrderDateTime) : null
//   );
//   const [transferOrderDateTime, setTransferOrderDateTime] = useState<Dayjs | null>(
//     settings.transferOrderDateTime ? dayjs(settings.transferOrderDateTime) : null
//   );

//   const [isPurchaseOrderPickerFrozen, setIsPurchaseOrderPickerFrozen] = useState<boolean>(!!settings.purchaseOrderDateTime);
//   const [isTransferOrderPickerFrozen, setIsTransferOrderPickerFrozen] = useState<boolean>(!!settings.transferOrderDateTime);

//   // Handle date-time change for Purchase Order
//   const handlePurchaseOrderDateChange = (date: Dayjs | null) => {
//     if (date && date.isValid()) {
//       setPurchaseOrderDateTime(date);
//       setSettings({
//         ...settings,
//         purchaseOrderDateTime: date.toISOString(),
//       });
//     }
//   };

//   // Handle date-time change for Transfer Order
//   const handleTransferOrderDateChange = (date: Dayjs | null) => {
//     if (date && date.isValid()) {
//       setTransferOrderDateTime(date);
//       setSettings({
//         ...settings,
//         transferOrderDateTime: date.toISOString(),
//       });
//     }
//   };

//   // Handle the save of settings to the backend
//   const handleSaveSettings = async () => {
//     console.log('handleSaveSettings called');

//     const dataToSend = {
//       order_setting: {
//         isOrderSyncEnabled: settings.showTransferOrder,
//         transferSyncStartDate: transferOrderDateTime ? transferOrderDateTime.toISOString() : null
//       },
//       product_setting: {
//         isProductSyncEnabled: settings.showPurchaseOrder,
//         purchase_order_sync_startdate: purchaseOrderDateTime ? purchaseOrderDateTime.toISOString() : null
//       }
//     };

//     console.log('Data to send:', dataToSend); // Debugging statement

  //   try {
  //     const response = await fetch(`/api/create-platform-sync-settings/${platformId}/`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(dataToSend)
  //     });
  //     const result = await response.json();
  //     console.log('Response from server:', result);
  //   } catch (error) {
  //     console.error('Error saving settings:', error);
  //   }
  // };

//   const handleToggle = (key: 'showPurchaseOrder' | 'showInventory' | 'showTransferOrder') => {
//     setSettings({
//       ...settings,
//       [key]: !settings[key]
//     });
//   };

//   // Use useEffect to automatically save settings whenever relevant state changes
//   // useEffect(() => {
//   //   handleSaveSettings();
//   // }, [purchaseOrderDateTime, transferOrderDateTime, settings.showPurchaseOrder, settings.showTransferOrder]);

//   // Inline styles for bold picker
//   const boldPickerStyle = {
//     fontWeight: 'bold',
//     color: '#000', // Optional: Change text color if needed
//     marginTop: '20px'
//   };

//   return (
//     <>
//       {/* Purchase Order Section */}
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Purchase Order</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <div className='form-check form-switch form-check-custom form-check-solid me-10'>
//             <label className='form-check-label text-dark h4' htmlFor='purchaseOrderSync'>
//               Sync Purchase Order
//             </label>
//             <input
//               className='ms-8 form-check-input h-20px w-40px'
//               type='checkbox'
//               id='purchaseOrderSync'
//               checked={settings.showPurchaseOrder}
//               onChange={() => handleToggle('showPurchaseOrder')}
//             />
//           </div>
//           {settings.showPurchaseOrder && (
//             <div style={isPurchaseOrderPickerFrozen ? boldPickerStyle : { marginTop: '20px' }}>
//               <LocalizationProvider dateAdapter={AdapterDayjs}>
//                 <DateTimePicker
//                   label="Select Purchase order start date"
//                   value={purchaseOrderDateTime}
//                   onChange={handlePurchaseOrderDateChange}
//                   slots={{
//                     textField: (textFieldProps) => <CustomTextField {...textFieldProps} />
//                   }}
//                   ampm={true}
//                   disabled={isPurchaseOrderPickerFrozen}
//                   closeOnSelect={false}
//                   readOnly={isPurchaseOrderPickerFrozen}
//                   onAccept={() => {
//                     setIsPurchaseOrderPickerFrozen(true);
//                     alert("Date and time selected");
//                   }}
//                 />
//               </LocalizationProvider>
//             </div>
//           )}
//         </div>
//       </div>

//       {/* Inventory Section */}
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Inventory</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <div className='form-check form-switch form-check-custom form-check-solid me-10'>
//             <label className='form-check-label text-dark h4' htmlFor='inventorySync'>
//               Sync Inventory
//             </label>
//             <input
//               className='ms-8 form-check-input h-20px w-40px'
//               type='checkbox'
//               id='inventorySync'
//               checked={settings.showInventory}
//               onChange={() => handleToggle('showInventory')}
//             />
//           </div>
//         </div>
//       </div>

//       {/* Transfer Order Section */}
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Transfer Order</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <div className='form-check form-switch form-check-custom form-check-solid me-10'>
//             <label className='form-check-label text-dark h4' htmlFor='transferOrderSync'>
//               Sync Transfer Order
//             </label>
//             <input
//               className='ms-8 form-check-input h-20px w-40px'
//               type='checkbox'
//               id='transferOrderSync'
//               checked={settings.showTransferOrder}
//               onChange={() => handleToggle('showTransferOrder')}
//             />
//           </div>
//           {settings.showTransferOrder && (
//             <div style={isTransferOrderPickerFrozen ? boldPickerStyle : { marginTop: '20px' }}>
//               <LocalizationProvider dateAdapter={AdapterDayjs}>
//                 <DateTimePicker
//                   label="Select Transfer order start date"
//                   value={transferOrderDateTime}
//                   onChange={handleTransferOrderDateChange}
//                   slots={{
//                     textField: (textFieldProps) => <CustomTextField {...textFieldProps} />
//                   }}
//                   ampm={true}
//                   disabled={isTransferOrderPickerFrozen}
//                   closeOnSelect={false}
//                   readOnly={isTransferOrderPickerFrozen}
//                   onAccept={() => {
//                     setIsTransferOrderPickerFrozen(true);
//                     alert("Date and time selected");
//                   }}
//                 />
//               </LocalizationProvider>
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default ShipHeroSettings;


/* vle code chlte hue hai */

/* testing 2 isme ek issue the ki select krte readonly hrhe niche vle m */ 

// import React, { FC, useState } from 'react';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
// import dayjs, { Dayjs } from 'dayjs';
// import TextField from '@mui/material/TextField';
// import Box from '@mui/material/Box';

// // Custom text field component
// const CustomTextField: React.FC<any> = (props) => {
//   return (
//     <TextField
//       {...props}
//       variant="outlined"
//       fullWidth
//       InputProps={{
//         style: {
//           fontSize: '1rem',
//         },
//         endAdornment: (
//           <>
//             {props.InputProps?.endAdornment}
//           </>
//         ),
//       }}
//       InputLabelProps={{
//         style: {
//           fontSize: '1rem',
//         },
//       }}
//     />
//   );
// };

// interface ShipHeroSettingsProps {
//   settings: {
//     showPurchaseOrder: boolean;
//     showInventory: boolean;
//     showTransferOrder: boolean;
//     purchaseOrderDateTime?: string;
//     transferOrderDateTime?: string;
//   };
//   setSettings: (settings: {
//     showPurchaseOrder?: boolean;
//     showInventory?: boolean;
//     showTransferOrder?: boolean;
//     purchaseOrderDateTime?: string;
//     transferOrderDateTime?: string;
//   }) => void;
//   platformId: number;
// }

// const ShipHeroSettings: FC<ShipHeroSettingsProps> = ({ settings, setSettings, platformId }) => {
//   const [purchaseOrderDateTime, setPurchaseOrderDateTime] = useState<Dayjs | null>(
//     settings.purchaseOrderDateTime ? dayjs(settings.purchaseOrderDateTime) : null
//   );
//   const [transferOrderDateTime, setTransferOrderDateTime] = useState<Dayjs | null>(
//     settings.transferOrderDateTime ? dayjs(settings.transferOrderDateTime) : null
//   );

//   const [isPurchaseOrderPickerFrozen, setIsPurchaseOrderPickerFrozen] = useState<boolean>(!!settings.purchaseOrderDateTime);
//   const [isTransferOrderPickerFrozen, setIsTransferOrderPickerFrozen] = useState<boolean>(!!settings.transferOrderDateTime);

//   const handlePurchaseOrderDateChange = (date: Dayjs | null) => {
//     if (date && date.isValid()) {
//       setPurchaseOrderDateTime(date);
//       setSettings({
//         ...settings,
//         purchaseOrderDateTime: date.toISOString(),
//       });
//     }
//   };

//   const handleTransferOrderDateChange = (date: Dayjs | null) => {
//     if (date && date.isValid()) {
//       setTransferOrderDateTime(date);
//       setSettings({
//         ...settings,
//         transferOrderDateTime: date.toISOString(),
//       });
//     }
//   };

//   const handleSaveSettings = async () => {
//     const dataToSend = {
//       order_setting: {
//         isOrderSyncEnabled: settings.showTransferOrder,
//         transferSyncStartDate: transferOrderDateTime ? transferOrderDateTime.toISOString() : null
//       },
//       product_setting: {
//         isProductSyncEnabled: settings.showPurchaseOrder,
//         purchase_order_sync_startdate: purchaseOrderDateTime ? purchaseOrderDateTime.toISOString() : null
//       }
//     };

//     try {
//       const response = await fetch(`/api/create-platform-sync-settings/${platformId}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(dataToSend)
//       });
//       const result = await response.json();
//       console.log('Response from server:', result);
//     } catch (error) {
//       console.error('Error saving settings:', error);
//     }
//   };

//   const handleToggle = (key: 'showPurchaseOrder' | 'showInventory' | 'showTransferOrder') => {
//     setSettings({
//       ...settings,
//       [key]: !settings[key]
//     });
//   };

//   return (
//     <>
//       {/* Purchase Order Section */}
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Purchase Order</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <div className='form-check form-switch form-check-custom form-check-solid me-10'>
//             <label className='form-check-label text-dark h4' htmlFor='purchaseOrderSync'>
//               Sync Purchase Order
//             </label>
//             <input
//               className='ms-8 form-check-input h-20px w-40px'
//               type='checkbox'
//               id='purchaseOrderSync'
//               checked={settings.showPurchaseOrder}
//               onChange={() => handleToggle('showPurchaseOrder')}
//             />
//           </div>
//           {settings.showPurchaseOrder && (
//             <Box sx={{ marginTop: '20px', width: '100%', maxWidth: '400px' }}>
//               <div style={{ marginBottom: '8px', fontSize: '1rem', fontWeight: 'bold' }}>
//                 Select Purchase Order Start Date
//               </div>
//               <LocalizationProvider dateAdapter={AdapterDayjs}>
//                 <DateTimePicker
//                   value={purchaseOrderDateTime}
//                   onChange={handlePurchaseOrderDateChange}
//                   slots={{
//                     textField: (textFieldProps) => <CustomTextField {...textFieldProps} />,
//                   }}
//                   ampm={true}
//                   disabled={isPurchaseOrderPickerFrozen}
//                   onAccept={() => {
//                     setIsPurchaseOrderPickerFrozen(true);
//                     alert("Date and time selected");
//                   }}
//                 />
//               </LocalizationProvider>
//             </Box>
//           )}
//         </div>
//       </div>

//       {/* Inventory Section */}
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Inventory</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <div className='form-check form-switch form-check-custom form-check-solid me-10'>
//             <label className='form-check-label text-dark h4' htmlFor='inventorySync'>
//               Sync Inventory
//             </label>
//             <input
//               className='ms-8 form-check-input h-20px w-40px'
//               type='checkbox'
//               id='inventorySync'
//               checked={settings.showInventory}
//               onChange={() => handleToggle('showInventory')}
//             />
//           </div>
//         </div>
//       </div>

//       {/* Transfer Order Section */}
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Transfer Order</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <div className='form-check form-switch form-check-custom form-check-solid me-10'>
//             <label className='form-check-label text-dark h4' htmlFor='transferOrderSync'>
//               Sync Transfer Order
//             </label>
//             <input
//               className='ms-8 form-check-input h-20px w-40px'
//               type='checkbox'
//               id='transferOrderSync'
//               checked={settings.showTransferOrder}
//               onChange={() => handleToggle('showTransferOrder')}
//             />
//           </div>
//           {settings.showTransferOrder && (
//             <Box sx={{ marginTop: '20px', width: '100%', maxWidth: '400px' }}>
//               <div style={{ marginBottom: '8px', fontSize: '1rem', fontWeight: 'bold' }}>
//                 Select Transfer Order Start Date
//               </div>
//               <LocalizationProvider dateAdapter={AdapterDayjs}>
//                 <DateTimePicker
//                   value={transferOrderDateTime}
//                   onChange={handleTransferOrderDateChange}
//                   slots={{
//                     textField: (textFieldProps) => <CustomTextField {...textFieldProps} />,
//                   }}
//                   ampm={true}
//                   disabled={isTransferOrderPickerFrozen}
//                   onAccept={() => {
//                     setIsTransferOrderPickerFrozen(true);
//                     alert("Date and time selected");
//                   }}
//                 />
//               </LocalizationProvider>
//             </Box>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default ShipHeroSettings;




/* niche check krege ki save krne k bd readonly hoye */


// import React, { FC, useState, useEffect } from 'react';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider, DateTimePicker, DateTimePickerSlots } from '@mui/x-date-pickers';
// import dayjs, { Dayjs } from 'dayjs';
// import TextField from '@mui/material/TextField';
// import Box from '@mui/material/Box';

// // Custom text field component
// const CustomTextField: React.FC<any> = (props) => {
//   return (
//     <TextField
//       {...props}
//       variant="outlined"
//       fullWidth
//       InputProps={{
//         style: {
//           fontSize: '1rem',
//         },
//         endAdornment: props.InputProps?.endAdornment,
//       }}
//       InputLabelProps={{
//         style: {
//           fontSize: '1rem',
//         },
//       }}
//     />
//   );
// };

// // Custom slots configuration
// const CustomDateTimePickerSlots: DateTimePickerSlots<Dayjs> = {
//   textField: (props) => <CustomTextField {...props} />
// };

// interface ShipHeroSettingsProps {
//   settings: {
//     showPurchaseOrder: boolean;
//     showInventory: boolean;
//     showTransferOrder: boolean;
//     purchaseOrderDateTime?: string;
//     transferOrderDateTime?: string;
//   };
//   setSettings: (settings: {
//     showPurchaseOrder?: boolean;
//     showInventory?: boolean;
//     showTransferOrder?: boolean;
//     purchaseOrderDateTime?: string;
//     transferOrderDateTime?: string;
//   }) => void;
//   platformId: number;
//   isReadonly: boolean;
// }

// const ShipHeroSettings: FC<ShipHeroSettingsProps> = ({ settings, setSettings, platformId, isReadonly }) => {
//   const [purchaseOrderDateTime, setPurchaseOrderDateTime] = useState<Dayjs | null>(
//     settings.purchaseOrderDateTime ? dayjs(settings.purchaseOrderDateTime) : null
//   );
//   const [transferOrderDateTime, setTransferOrderDateTime] = useState<Dayjs | null>(
//     settings.transferOrderDateTime ? dayjs(settings.transferOrderDateTime) : null
//   );

//   useEffect(() => {
//     setPurchaseOrderDateTime(settings.purchaseOrderDateTime ? dayjs(settings.purchaseOrderDateTime) : null);
//     setTransferOrderDateTime(settings.transferOrderDateTime ? dayjs(settings.transferOrderDateTime) : null);
//   }, [settings]);

//   const handlePurchaseOrderDateChange = (date: Dayjs | null) => {
//     if (date && date.isValid()) {
//       setPurchaseOrderDateTime(date);
//       setSettings({
//         ...settings,
//         purchaseOrderDateTime: date.toISOString(),
//       });
//     }
//   };

//   const handleTransferOrderDateChange = (date: Dayjs | null) => {
//     if (date && date.isValid()) {
//       setTransferOrderDateTime(date);
//       setSettings({
//         ...settings,
//         transferOrderDateTime: date.toISOString(),
//       });
//     }
//   };

//   return (
//     <>
//       {/* Purchase Order Section */}
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Purchase Order</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <div className='form-check form-switch form-check-custom form-check-solid me-10'>
//             <label className='form-check-label text-dark h4' htmlFor='purchaseOrderSync'>
//               Sync Purchase Order
//             </label>
//             <input
//               className='ms-8 form-check-input h-20px w-40px'
//               type='checkbox'
//               id='purchaseOrderSync'
//               checked={settings.showPurchaseOrder}
//               onChange={() => setSettings({
//                 ...settings,
//                 showPurchaseOrder: !settings.showPurchaseOrder
//               })}
//               disabled={isReadonly}
//             />
//           </div>
//           {settings.showPurchaseOrder && (
//             <Box sx={{ marginTop: '20px', width: '100%', maxWidth: '400px' }}>
//               <div style={{ marginBottom: '8px', fontSize: '1rem', fontWeight: 'bold' }}>
//                 Select Purchase Order Start Date
//               </div>
//               <LocalizationProvider dateAdapter={AdapterDayjs}>
//                 <DateTimePicker
//                   value={purchaseOrderDateTime}
//                   onChange={handlePurchaseOrderDateChange}
//                   slots={CustomDateTimePickerSlots}
//                   ampm={true}
//                   // readOnly={isReadonly}
//                 />
//               </LocalizationProvider>
//             </Box>
//           )}
//         </div>
//       </div>

//       {/* Inventory Section */}
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Inventory</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <div className='form-check form-switch form-check-custom form-check-solid me-10'>
//             <label className='form-check-label text-dark h4' htmlFor='inventorySync'>
//               Sync Inventory
//             </label>
//             <input
//               className='ms-8 form-check-input h-20px w-40px'
//               type='checkbox'
//               id='inventorySync'
//               checked={settings.showInventory}
//               onChange={() => setSettings({
//                 ...settings,
//                 showInventory: !settings.showInventory
//               })}
//               // disabled={isReadonly}
//             />
//           </div>
//         </div>
//       </div>

//       {/* Transfer Order Section */}
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Transfer Order</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <div className='form-check form-switch form-check-custom form-check-solid me-10'>
//             <label className='form-check-label text-dark h4' htmlFor='transferOrderSync'>
//               Sync Transfer Order
//             </label>
//             <input
//               className='ms-8 form-check-input h-20px w-40px'
//               type='checkbox'
//               id='transferOrderSync'
//               checked={settings.showTransferOrder}
//               onChange={() => setSettings({
//                 ...settings,
//                 showTransferOrder: !settings.showTransferOrder
//               })}
//               // disabled={isReadonly}
//             />
//           </div>
//           {settings.showTransferOrder && (
//             <Box sx={{ marginTop: '20px', width: '100%', maxWidth: '400px' }}>
//               <div style={{ marginBottom: '8px', fontSize: '1rem', fontWeight: 'bold' }}>
//                 Select Transfer Order Start Date
//               </div>
//               <LocalizationProvider dateAdapter={AdapterDayjs}>
//                 <DateTimePicker
//                   value={transferOrderDateTime}
//                   onChange={handleTransferOrderDateChange}
//                   slots={CustomDateTimePickerSlots}
//                   ampm={true}
//                   readOnly={isReadonly}
//                 />
//               </LocalizationProvider>
//             </Box>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default ShipHeroSettings;







// import React, { FC, useState, useEffect } from 'react';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider, DateTimePicker, DateTimePickerSlots } from '@mui/x-date-pickers';
// import dayjs, { Dayjs } from 'dayjs';
// import TextField from '@mui/material/TextField';
// import Box from '@mui/material/Box';
// import Tooltip from '@mui/material/Tooltip';
// import InfoIcon from '@mui/icons-material/Info';

// // Custom text field component
// const CustomTextField: React.FC<any> = (props) => {
//   return (
//     <TextField
//       {...props}
//       variant="outlined"
//       fullWidth
//       InputProps={{
//         style: {
//           fontSize: '1rem',
//         },
//         endAdornment: props.InputProps?.endAdornment,
//       }}
//       InputLabelProps={{
//         style: {
//           fontSize: '1rem',
//         },
//       }}
//       // Customize the date format here if needed
//       // You can format the input value in this component if required
//     />
//   );
// };

// // Custom slots configuration
// const CustomDateTimePickerSlots: DateTimePickerSlots<Dayjs> = {
//   textField: (props) => <CustomTextField {...props} />
// };

// interface ShipHeroSettingsProps {
//   settings: {
//     showPurchaseOrder: boolean;
//     showInventory: boolean;
//     showTransferOrder: boolean;
//     purchaseOrderDateTime?: string;
//     transferOrderDateTime?: string;
//   };
//   setSettings: (settings: {
//     showPurchaseOrder?: boolean;
//     showInventory?: boolean;
//     showTransferOrder?: boolean;
//     purchaseOrderDateTime?: string;
//     transferOrderDateTime?: string;
//   }) => void;
//   platformId: number;
//   isReadonly: boolean;
// }

// const ShipHeroSettings: FC<ShipHeroSettingsProps> = ({ settings, setSettings, platformId, isReadonly }) => {
//   const [purchaseOrderDateTime, setPurchaseOrderDateTime] = useState<Dayjs | null>(
//     settings.purchaseOrderDateTime ? dayjs(settings.purchaseOrderDateTime) : null
//   );
//   const [transferOrderDateTime, setTransferOrderDateTime] = useState<Dayjs | null>(
//     settings.transferOrderDateTime ? dayjs(settings.transferOrderDateTime) : null
//   );

//   useEffect(() => {
//     setPurchaseOrderDateTime(settings.purchaseOrderDateTime ? dayjs(settings.purchaseOrderDateTime) : null);
//     setTransferOrderDateTime(settings.transferOrderDateTime ? dayjs(settings.transferOrderDateTime) : null);
//   }, [settings]);

//   const handlePurchaseOrderDateChange = (date: Dayjs | null) => {
//     if (date && date.isValid()) {
//       setPurchaseOrderDateTime(date);
//       setSettings({
//         ...settings,
//         purchaseOrderDateTime: date.toISOString(),
//       });
//     }
//   };

//   const handleTransferOrderDateChange = (date: Dayjs | null) => {
//     if (date && date.isValid()) {
//       setTransferOrderDateTime(date);
//       setSettings({
//         ...settings,
//         transferOrderDateTime: date.toISOString(),
//       });
//     }
//   };

//   const now = dayjs();

//   return (
//     <>
//       {/* Purchase Order Section */}
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Purchase Order</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <div className='form-check form-switch form-check-custom form-check-solid me-10'>
//             <label className='form-check-label text-dark h4' htmlFor='purchaseOrderSync'>
//               Sync Purchase Order
//             </label>
//             <input
//               className='ms-8 form-check-input h-20px w-40px'
//               type='checkbox'
//               id='purchaseOrderSync'
//               checked={settings.showPurchaseOrder}
//               onChange={() => setSettings({
//                 ...settings,
//                 showPurchaseOrder: !settings.showPurchaseOrder
//               })}
//               disabled={isReadonly}
//             />
//           </div>
//           {settings.showPurchaseOrder && (
//             <Box sx={{ marginTop: '20px', width: '100%', maxWidth: '500px' }}>
//               <div style={{ marginBottom: '8px', fontSize: '1rem', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
//                 Select Purchase Order Start Date
//                 <Tooltip
//                   title={
//                     <div style={{ 
//                       backgroundColor: '#f0f0f0', 
//                       color: '#000', 
//                       fontSize: '0.875rem', 
//                       padding: '8px', 
//                       maxWidth: '300px',
//                       wordBreak: 'break-word',
//                       borderRadius: '4px' // Optional, if you want rounded corners
//                     }}>
//                       <h3 style={{ fontSize: '1rem', margin: '0 0 8px' }}>This date you can choose from which you want to fetch the order.</h3>
//                       <h3 style={{ fontSize: '1.2rem', margin: '0' }}><strong>Note:</strong> Once saved this date cannot be changed. If you want to change the date, you can connect Support or create a new connection.</h3>
//                     </div>
//                   }
//                   arrow
//                 >
//                   <InfoIcon sx={{ marginLeft: '8px', fontSize: '2rem', color: '#6c757d' }} />
//                 </Tooltip>
//               </div>
//               <LocalizationProvider dateAdapter={AdapterDayjs}>
//                 <DateTimePicker
//                   value={purchaseOrderDateTime}
//                   onChange={handlePurchaseOrderDateChange}
//                   slots={CustomDateTimePickerSlots}
//                   readOnly={isReadonly}
//                   ampm={false}
//                   minDate={undefined} // Allow past and present dates
//                   maxDate={now} // Restrict to present and past dates
//                   slotProps={{
//                     textField: { 
//                       fullWidth: true,
//                       // You can set the format of the text field here if needed
//                       // Example: format the display format of the date in the text field
//                       // However, DateTimePicker does not directly support inputFormat
//                     }
//                   }}
//                 />
//               </LocalizationProvider>
//             </Box>
//           )}
//         </div>
//       </div>

//       {/* Inventory Section */}
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Inventory</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <div className='form-check form-switch form-check-custom form-check-solid me-10'>
//             <label className='form-check-label text-dark h4' htmlFor='inventorySync'>
//               Sync Inventory
//             </label>
//             <input
//               className='ms-8 form-check-input h-20px w-40px'
//               type='checkbox'
//               id='inventorySync'
//               checked={settings.showInventory}
//               onChange={() => setSettings({
//                 ...settings,
//                 showInventory: !settings.showInventory
//               })}
//               disabled={isReadonly}
//             />
//           </div>
//         </div>
//       </div>

//       {/* Transfer Order Section */}
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Transfer Order</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <div className='form-check form-switch form-check-custom form-check-solid me-10'>
//             <label className='form-check-label text-dark h4' htmlFor='transferOrderSync'>
//               Sync Transfer Order
//             </label>
//             <input
//               className='ms-8 form-check-input h-20px w-40px'
//               type='checkbox'
//               id='transferOrderSync'
//               checked={settings.showTransferOrder}
//               onChange={() => setSettings({
//                 ...settings,
//                 showTransferOrder: !settings.showTransferOrder
//               })}
//               // disabled={isReadonly}
//             />
//           </div>
//           {settings.showTransferOrder && (
//             <Box sx={{ marginTop: '20px', width: '100%', maxWidth: '500px' }}>
//               <div style={{ marginBottom: '8px', fontSize: '1rem', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
//                 Select Transfer Order Start Date
//                 <Tooltip
//                   title={
//                     <div style={{ 
//                       backgroundColor: '#f0f0f0', 
//                       color: '#000', 
//                       fontSize: '0.875rem', 
//                       padding: '8px', 
//                       maxWidth: '300px',
//                       wordBreak: 'break-word',
//                       borderRadius: '4px' // Optional, if you want rounded corners
//                     }}>
//                       <h2 style={{ fontSize: '1rem', margin: '0 0 8px' }}>This date you can choose from which you want to fetch the order.</h2>
//                       <h3 style={{ fontSize: '1.2rem', margin: '0' }}><strong>Note:</strong> Once saved this date cannot be changed. If you want to change the date, you can connect Support or create a new connection.</h3>
//                     </div>
//                   }
//                   arrow
//                 >
//                   <InfoIcon sx={{ marginLeft: '8px', fontSize: '2rem', color: '#6c757d' }} />
//                 </Tooltip>
//               </div>
//               <LocalizationProvider dateAdapter={AdapterDayjs}>
//                 <DateTimePicker
//                   value={transferOrderDateTime}
//                   onChange={handleTransferOrderDateChange}
//                   slots={CustomDateTimePickerSlots}
//                   readOnly={isReadonly}
//                   minDate={undefined} // Allow past and present dates
//                   maxDate={now} // Restrict to present and past dates
//                   ampm={false}
//                   slotProps={{
//                     textField: { 
//                       fullWidth: true,
//                       // You can set the format of the text field here if needed
//                     }
//                   }}
//                 />
//               </LocalizationProvider>
//             </Box>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default ShipHeroSettings;














import React, { FC, useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateTimePicker, DateTimePickerSlots } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc'; // Import UTC plugin
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

dayjs.extend(utc);

// Custom text field component
const CustomTextField: React.FC<any> = (props) => {
  return (
    <TextField
      {...props}
      variant="outlined"
      fullWidth
      InputProps={{
        style: {
          fontSize: '1rem',
        },
        endAdornment: props.InputProps?.endAdornment,
      }}
      InputLabelProps={{
        style: {
          fontSize: '1rem',
        },
      }}
    />
  );
};

// Custom slots configuration
const CustomDateTimePickerSlots: DateTimePickerSlots<Dayjs> = {
  textField: (props) => <CustomTextField {...props} />
};

interface ShipHeroSettingsProps {
  settings: {
    showPurchaseOrder: boolean;
    showInventory: boolean;
    showTransferOrder: boolean;
    purchaseOrderDateTime?: string;
    transferOrderDateTime?: string;
  };
  setSettings: (settings: {
    showPurchaseOrder?: boolean;
    showInventory?: boolean;
    showTransferOrder?: boolean;
    purchaseOrderDateTime?: string;
    transferOrderDateTime?: string;
  }) => void;
  platformId: number;
  isReadonly: boolean;
  onDateChange: (field: string, date: Date | null) => void
}

const ShipHeroSettings: FC<ShipHeroSettingsProps> = ({ settings, setSettings, platformId, isReadonly }) => {
  // Initialize state with UTC date
  console.log("checking const value",settings)
  const [purchaseOrderDateTime, setPurchaseOrderDateTime] = useState<Dayjs | null | string> (
     null
  );
  const [transferOrderDateTime, setTransferOrderDateTime] = useState<Dayjs | null | string>(
    null
  );

  useEffect(() => {
    console.log("checking setting",settings)
    // Convert to UTC when settings change
    // setPurchaseOrderDateTime(settings.purchaseOrderDateTime ? dayjs.utc(settings.purchaseOrderDateTime) : null);
    // setTransferOrderDateTime(settings.transferOrderDateTime ? dayjs.utc(settings.transferOrderDateTime) : null);
  
    console.log("date time check ")
    console.log(purchaseOrderDateTime)
  }, [settings]);


  const handlePurchaseOrderDateChange = (date: Dayjs | null) => {
    if (date && date.isValid()) {
      setPurchaseOrderDateTime(date.utc(true).toString());
      console.log("------ checking datetine t",date.toString())
      setSettings({
        ...settings,
        purchaseOrderDateTime: date.utc(true).toString(), // Convert to UTC before saving
       
      });
    }
  };

  const handleTransferOrderDateChange = (date: Dayjs | null) => {
    if (date && date.isValid()) {
      setTransferOrderDateTime(date.utc(true).toString());
      console.log("------ checking datetine t",date)
      setSettings({
        ...settings,
        transferOrderDateTime: date.utc(true).toString(), // Convert to UTC before saving
      });
    }
  };

  const now = dayjs.utc(); // Use UTC for current time
console.log("check time",purchaseOrderDateTime,settings["purchaseOrderDateTime"])



  return (
    <>
      {/* Purchase Order Section */}
      <div className='card m-2 p-4 shadow'>
        <div>
          <p className='h3'>Purchase Order</p>
        </div>
        <div className='border-bottom my-2 border-2'></div>
        <div className='p-4'>
          <div className='form-check form-switch form-check-custom form-check-solid me-10'>
            <label className='form-check-label text-dark h4' htmlFor='purchaseOrderSync'>
              Sync Purchase Order
            </label>
            <input
              className='ms-8 form-check-input h-20px w-40px'
              type='checkbox'
              id='purchaseOrderSync'
              checked={settings.showPurchaseOrder}
              onChange={() => setSettings({
                ...settings,
                showPurchaseOrder: !settings.showPurchaseOrder
              })}
              // disabled={isReadonly}
              style={{
                backgroundColor: settings.showPurchaseOrder ? '#3E97FF' : 'silver',
                // opacity: isReadonly ? 0.5 : 1,
                height: '20px',
                width: '40px',
                cursor: isReadonly ? 'not-allowed' : 'pointer'
              }}
            />
          </div>
          {settings.showPurchaseOrder && (
            <Box sx={{ marginTop: '20px', width: '100%', maxWidth: '500px' }}>
              <div style={{ marginBottom: '8px', fontSize: '1rem', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                Select Purchase Order Start Date(UTC)
                <Tooltip
                  title={
                    <div style={{ 
                      backgroundColor: '#f0f0f0', 
                      color: '#000', 
                      fontSize: '0.875rem', 
                      padding: '8px', 
                      maxWidth: '300px',
                      wordBreak: 'break-word',
                      borderRadius: '4px' // Optional, if you want rounded corners
                    }}>
                      <h3 style={{ fontSize: '1rem', margin: '0 0 8px' }}>This date you can choose from which you want to fetch the order.</h3>
                      <h3 style={{ fontSize: '1.2rem', margin: '0' }}><strong>Note:</strong> Once saved this date cannot be changed. If you want to change the date, you can connect Support or create a new connection.</h3>
                    </div>
                  }
                  arrow
                >
                  <InfoIcon sx={{ marginLeft: '8px', fontSize: '2rem', color: '#6c757d' }} />
                </Tooltip>
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  value={(purchaseOrderDateTime==null && settings["purchaseOrderDateTime"])?dayjs.utc(settings["purchaseOrderDateTime"]):purchaseOrderDateTime?dayjs.utc(purchaseOrderDateTime):null}
                  onChange={(day)=>{
                    console.log("----------handledate-----------",day?.utc(true).toString())
                    handlePurchaseOrderDateChange(day)
                  }}
                  format="DD-MMM-YY, HH:mm  "
                  slots={CustomDateTimePickerSlots}
                  readOnly={isReadonly}
                  
                  ampm={false} // Ensures 24-hour format
                  minDate={undefined} // Allow past and present dates
                  maxDate={now} // Restrict to present and past dates
                  disabled={(purchaseOrderDateTime==null && settings["purchaseOrderDateTime"])?Boolean(settings["purchaseOrderDateTime"]):false}
                  closeOnSelect={true}
                  slotProps={{
                    textField: { 
                      fullWidth: true,
                      // inputFormat: "YYYY-MM-DD HH:mm", // Format for display
                    }
                  }}
                />
              </LocalizationProvider>
            </Box>
          )}
        </div>
      </div>

      {/* Inventory Section */}
      <div className='card m-2 p-4 shadow'>
        <div>
          <p className='h3'>Inventory</p>
        </div>
        <div className='border-bottom my-2 border-2'></div>
        <div className='p-4'>
          <div className='form-check form-switch form-check-custom form-check-solid me-10'>
            <label className='form-check-label text-dark h4' htmlFor='inventorySync'>
              Sync Inventory
            </label>
            <input
              className='ms-8 form-check-input h-20px w-40px'
              type='checkbox'
              id='inventorySync'
              checked={settings.showInventory}
              onChange={() => setSettings({
                ...settings,
                showInventory: !settings.showInventory
              })}
              // disabled={isReadonly}
              style={{
                backgroundColor: settings.showInventory ? '#3E97FF' : 'silver',
                // opacity: isReadonly ? 0.5 : 1,
                height: '20px',
                width: '40px',
                cursor: isReadonly ? 'not-allowed' : 'pointer'
              }}
            />
          </div>
        </div>
      </div>

      {/* Transfer Order Section */}
      <div className='card m-2 p-4 shadow'>
        <div>
          <p className='h3'>Transfer Order</p>
        </div>
        <div className='border-bottom my-2 border-2'></div>
        <div className='p-4'>
          <div className='form-check form-switch form-check-custom form-check-solid me-10'>
            <label className='form-check-label text-dark h4' htmlFor='transferOrderSync'>
              Sync Transfer Order
            </label>
            <input
              className='ms-8 form-check-input h-20px w-40px'
              type='checkbox'
              id='transferOrderSync'
              checked={settings.showTransferOrder}
              onChange={() => setSettings({
                ...settings,
                showTransferOrder: !settings.showTransferOrder
              })}
              // disabled={isReadonly}
              style={{
                backgroundColor: settings.showTransferOrder ? '#3E97FF' : 'silver',
                height: '20px',
                width: '40px',
                cursor: isReadonly ? 'not-allowed' : 'pointer'
              }}
            />
          </div>
          {settings.showTransferOrder && (
            <Box sx={{ marginTop: '20px', width: '100%', maxWidth: '500px' }}>
              <div style={{ marginBottom: '8px', fontSize: '1rem', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                Select Transfer Order Start Date(UTC)
                <Tooltip
                  title={
                    <div style={{ 
                      backgroundColor: '#f0f0f0', 
                      color: '#000', 
                      fontSize: '0.875rem', 
                      padding: '8px', 
                      maxWidth: '300px',
                      wordBreak: 'break-word',
                      borderRadius: '4px' // Optional, if you want rounded corners
                    }}>
                      <h2 style={{ fontSize: '1rem', margin: '0 0 8px' }}>This date you can choose from which you want to fetch the order.</h2>
                      <h3 style={{ fontSize: '1.2rem', margin: '0' }}><strong>Note:</strong> Once saved this date cannot be changed. If you want to change the date, you can connect Support or create a new connection.</h3>
                    </div>
                  }
                  arrow
                >
                  <InfoIcon sx={{ marginLeft: '8px', fontSize: '2rem', color: '#6c757d' }} />
                </Tooltip>
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                value={(transferOrderDateTime==null && settings["transferOrderDateTime"])?dayjs.utc(settings["transferOrderDateTime"]):transferOrderDateTime?dayjs.utc(transferOrderDateTime):null}

                  // value={dayjs("2024-08-18T22:50:00Z")}
                  onChange={(day)=>{
                    console.log("---------- transfer handledate -----------",day?.utc(true).toString())
                    handleTransferOrderDateChange(day)
                  }}
                  slots={CustomDateTimePickerSlots}
                  readOnly={isReadonly}
                  ampm={false} // Ensures 24-hour format
                  minDate={undefined} // Allow past and present dates
                  maxDate={now} // Restrict to present and past dates
                  format="DD-MMM-YY, HH:mm  "
                  disabled={(transferOrderDateTime==null && settings["transferOrderDateTime"])?Boolean(settings["transferOrderDateTime"]):false}

                  // defaultValue={}
                  slotProps={{
                    textField: { 
                      fullWidth: true,
                      // inputFormat: "YYYY-MM-DD HH:mm", // Format for display
                    }
                  }}
                />
              </LocalizationProvider>
            </Box>
          )}
        </div>
      </div>
    </>
  );
};

export default ShipHeroSettings;
