import React from 'react'
import {Tab, Tabs} from 'react-bootstrap'

interface AssignTasksNavProps {
  selectedTab: string
  setSelectedTab: (tab: string) => void
}

const AssiginTasksNav: React.FC<AssignTasksNavProps> = ({ selectedTab, setSelectedTab }) => {
    return (
      <div>
        {/* <Tabs
          activeKey={selectedTab}
          onSelect={(k) => {
            if (k) setSelectedTab(k);
          }}
        >
          <Tab eventKey="assign-tasks" title="Assign Tasks"></Tab>
          <Tab eventKey="generate-reports" title="Generate Reports"></Tab>
        </Tabs> */}
      </div>
    );
  };
  
  export default AssiginTasksNav;