import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAvailableGPTModelsAsync } from '../actions/gptModelsActions';
import { AvailableOpenAIModels } from "src/interfaces/Platform.interface";

interface GPTModelsState {
  loading: boolean;
  data: AvailableOpenAIModels[] | null;
  error: string | null;
}

const initialState: GPTModelsState = {
  loading: false,
  data: null, // Adjusted from [] to null to match the type.
  error: null,
};

const gptModelsSlice = createSlice({
  name: 'gptModels',
  initialState,
  reducers: {
    resetGPTModelsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableGPTModelsAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAvailableGPTModelsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload || [];
        state.error = null;
      })
      .addCase(fetchAvailableGPTModelsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ? action.error.message : "Failed to fetch available gpt models";
      });
  },
});

export default gptModelsSlice.reducer;
