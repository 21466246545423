import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'src/redux/storeHooks';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'src/utils/Loader';
import {
  getAllInstructionsAsync,
  getSingleInstructionAsync,
  UpdateInstructionAsync,
  deleteInstructionAsync,
} from '../../../redux/actions/platformActions';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import AppDateFilter from 'src/utils/AppDateFilter';
import DataTable from 'react-data-table-component';
import { ErrorMessage, Field, Formik } from 'formik';
import * as Yup from 'yup';
import AlertModal from 'src/utils/modals/AlertModal';
import DaySelector from './DaySelector';

interface LocationState {
  user_id: string;
}

interface Instruction {
  id: number;
  platform: { name: string; code: string };
  task: string;
  last_checked_at: string;
  time_to_check: string;
  next_schedule_time: string
  mail_send_time: string;
  selected_days: string[];
}

const InstructionsTable: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState<Instruction[]>([]);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModelOpen] = useState<boolean>(false);
  const [totalInstructions, setTotalInstructions] = useState<number>(0);
  const [editData, setEditData] = useState<Instruction | null>(null);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [instructionToDelete, setInstructionToDelete] = useState<number | null>(null);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    page_limit: 10,
    from_date: '',
    to_date: '',
    q: '',
    sort_direction: '', // DES for descending
    sort_by: '',
  });

  const handleModalClose = () => setIsEditModelOpen(false);

  const columns = [
    {
      name: 'Platform',
      selector: (row: Instruction) => `${row.platform.name} - ${row.platform.code}`,
      sortable: true,
    },
    {
      name: 'Instruction',
      selector: (row: Instruction) => row.task,
      sortable: true,
    },
    {
      name: 'Next run time',
      selector: (row: Instruction) => row.next_schedule_time,
      sortable: true,
      cell: (row: Instruction) =>
        row?.next_schedule_time
          ? moment.utc(new Date(row.next_schedule_time)).format('DD-MM-YY - HH:mm:ss')
          : '',
    },
    {
      name: 'Frequency',
      selector: (row: Instruction) => row.time_to_check,

    },
    {
      name: 'Mail send at',
      selector: (row: Instruction) => row.mail_send_time,
      cell: (row: Instruction) =>
        row?.mail_send_time
          ? moment.utc(new Date(row.mail_send_time)).format('DD-MM-YY - HH:mm:ss')
          : '',
    },
    {
      name: 'Action',
      cell: (row: Instruction) => (
        <div className='d-flex gap-2'>
          <Button
            onClick={() => handleOpenInstructionsEditModal(row.id)}
            className='btn btn-sm btn-secondary'
          >
            Edit
          </Button>
          <Button
            onClick={() => handleDeleteClick(row.id)}
            className='btn btn-sm btn-danger'
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleAddInstructionsClick = () => {
    navigate('/add-instructions');
  };

  const location = useLocation();
  const state = location.state as LocationState;

  const user_id = state?.user_id;
  if (user_id === undefined) {
    console.error('User id not found');
  }
  const id = Math.floor(Number(user_id));
  const fetchAllInstructions = async () => {
    try {
      setIsLoading(true);
      const response = await dispatch(
        getAllInstructionsAsync({ id: id, otherParams: paginationData })
      ).unwrap();
      console.log('API response:', response);
      console.log('Status', response.status_code);
  
      if (response.status_code === 200) {
        setTotalInstructions(response.data.count);
        console.log('results - - - ', response.data?.results);
        setTasks(response.data?.results);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  
  // Call fetchAllInstructions inside useEffect
  useEffect(() => {
    if (user_id) {
      fetchAllInstructions();
    }
  }, [paginationData, user_id]);
  

  // useEffect(() => {
  //   const fetchAllInstructions = async () => {
  //     try {
  //       setIsLoading(true);
  //       const response = await dispatch(
  //         getAllInstructionsAsync({ id: id, otherParams: paginationData })
  //       ).unwrap();
  //       console.log('API response:', response);
  //       console.log('Status', response.status_code);

  //       if (response.status_code === 200) {
  //         setTotalInstructions(response.data.count);
  //         console.log('results - - - ', response.data?.results);
  //         setTasks(response.data?.results);
  //         setIsLoading(false);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       setIsLoading(false);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
  //   if (user_id) {
  //     fetchAllInstructions();
  //   }
  // }, [paginationData, user_id]);

  const handleInstructionsEdit = async (values: { instruction: string; frequency: string; selectedDays: string[] }) => {
    console.log('Form submitted', values);
    try {
      console.log('Inside edit function');
      if (!editData) return;
      setIsLoading(true);
  
      const updatedPayload = {
        instruction: values.instruction,
        frequency: values.frequency,
        selected_days: values.selectedDays // Use the updated selected days
      };
  
      const res = await dispatch(
        UpdateInstructionAsync({
          id: editData.id,
          payload: updatedPayload,
        })
      ).unwrap();
      console.log('API response:', res);
      console.log('Status', res);
      if (res === "Task updated") {
        toast.success('Instruction updated successfully');
        setIsEditModelOpen(false);
        setEditData(null);
        fetchAllInstructions(); // Fetch the latest data
      }
    } catch (error) {
      console.error('Error updating Instruction data: ', error);
      toast.error('Failed to update Instruction');
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleOpenInstructionsEditModal = async (id: number) => {
    try {
      console.log('Inside edit model open function');
      const response = await dispatch(
        getSingleInstructionAsync({ id: id, otherParams: null })
      ).unwrap();
      if (response?.status_code === 200) {
        setEditData(response?.data);
        setSelectedDays(response?.data?.selected_days || []);
        setIsEditModelOpen(true);
      }
    } catch (error) {
      console.error('Error fetching Instruction data: ', error);
    }
  };

  const handleInstructionsDelete = async () => {
    if (instructionToDelete === null) return;

    try {
      setIsLoading(true);
      const res = await dispatch(deleteInstructionAsync({ id: instructionToDelete, otherParams: null })).unwrap();
      if (res.status_code === 200) {
        toast.success("Instruction Deleted successfully");
        setTasks((prevTasks) => prevTasks.filter((task: Instruction) => task.id !== instructionToDelete));
        setInstructionToDelete(null); // Reset the state after deletion
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(`Error in deleting instruction. Please try Again. - ${error}`);
      console.error('Error fetching Instruction data: ', error);
    } finally {
      setIsLoading(false);
      setShowAlertModal(false); // Hide the modal after deletion
    }
  };

  const handleDeleteClick = (id: number) => {
    setInstructionToDelete(id);
    setShowAlertModal(true);
  };


  const validationSchema = Yup.object({
    instruction: Yup.string().required('Instruction is required'),
    frequency: Yup.string().required('Frequency is required'),
  });

  const handleSubmit = (values: { instruction: string; frequency: string }) => {
    const formattedValues = {
      ...values,
      selectedDays: selectedDays.filter(day => day !== '(' && day !== ')' && day !== ',' && day !== "'"), // Ensure selectedDays is passed correctly and remove invalid characters
    };
    handleInstructionsEdit(formattedValues);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='mb-5 d-flex justify-content-end'>
            <Button className='btn btn-info btn-sm' onClick={handleAddInstructionsClick}>
              Add Instructions
            </Button>
          </div>
          <div className='card p-4 bg-white ' style={{ height: '71vh' }}>
            <p className='page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0'>
              Instructions
            </p>
            <div className='card-header align-items-center gap-2 gap-md-5'>
              <div className='card-title'>
                <div className='d-flex align-items-center position-relative my-1'>
                  <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-4'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>{' '}
                  <input
                    type='text'
                    data-kt-ecommerce-product-filter='search'
                    className='form-control form-control-solid form-control-sm w-250px ps-12'
                    placeholder='Search Instructions'
                    value={paginationData.q}
                    onChange={(ev) => {
                      setPaginationData((pre) => ({ ...pre, q: ev.target.value }));
                    }}
                  />
                </div>
              </div>
              <div className='card-toolbar flex-row-fluid justify-content-end gap-2 gap-md-5'>
                <div className='w-100 w-md-50 mw-200px '>
                  <button
                    className='form-select form-select-solid form-select-sm'
                    onClick={() => setShowDatePicker(true)}
                  >
                    {paginationData.from_date || paginationData.to_date
                      ? moment(paginationData.from_date).format('DD/MMM/YY') +
                      ' - ' +
                      moment(paginationData.to_date).format('DD/MMM/YY')
                      : 'Pick Date Range'}
                  </button>
                </div>

                <button
                  className='btn btn-info btn-sm'
                  onClick={() => {
                    setPaginationData({
                      page: 1,
                      page_limit: 10,
                      from_date: '',
                      to_date: '',
                      q: '',
                      sort_direction: 'desc', // DES for descending
                      sort_by: 'last_checked_at',
                    });
                  }}
                >
                  Reset Filters
                </button>
              </div>
            </div>

            <div className='card-body'>
              <DataTable
                columns={columns}
                fixedHeader
                fixedHeaderScrollHeight='100%'
                data={tasks}
                pagination
                paginationServer
                paginationPerPage={10}
                paginationTotalRows={totalInstructions}
                onChangePage={(page) => {
                  setPaginationData((pre) => {
                    return { ...pre, page: page };
                  });
                }}
                onChangeRowsPerPage={(currentRowPerPage) => {
                  setPaginationData((pre) => {
                    return { ...pre, page_limit: currentRowPerPage };
                  });
                }}
                customStyles={{
                  table: {
                    style: { height: '46vh' },
                  },
                }}
              />
            </div>
          </div>
          <AppDateFilter
            isVisible={showDatePicker}
            handleClose={() => setShowDatePicker(false)}
            onPickRange={(dateRange) => {
              setPaginationData((pre) => ({
                ...pre,
                from_date: dateRange.startDate,
                to_date: dateRange.endDate,
              }));
            }}
          />
          <Modal style={{ width: '100vw' }} show={isEditModalOpen} onHide={handleModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Instruction</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {editData && (
                <Formik
                  initialValues={{
                    instruction: editData?.task || '',
                    frequency: editData?.time_to_check || '',
                    selectedDays: selectedDays || [],
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Instruction</Form.Label>
                        <Field
                          type='text'
                          name='instruction'
                          placeholder='Enter instruction'
                          className='form-control'
                        />
                        <ErrorMessage
                          name='instruction'
                          component='div'
                          className='invalid-feedback'
                        />
                      </Form.Group>
                      <Form.Group className='mb-3'>
                        <Form.Label>Select duration</Form.Label>
                        <div className='d-flex'>
                          <div className='d-flex flex-column'>
                            <Field as='select' name='frequency' className='form-control'>
                              <option value="3:00">In every 3:00 hours</option>
                              <option value="6:00">In every 6:00 hours</option>
                              <option value="12:00">In every 12:00 hours</option>
                            </Field>
                          </div>
                        </div>
                        <ErrorMessage
                          name='frequency'
                          component='div'
                          className='invalid-feedback'
                        />
                      </Form.Group>
                      <Form.Group className='mb-3'>
                        <Form.Label>Select Days</Form.Label>
                        <div className="d-flex mt-3 mb-4 align-items-center text-black">
                          <DaySelector selectedDays={selectedDays} onChange={setSelectedDays} />
                        </div>
                      </Form.Group>
                      <Button variant='primary' type='submit' disabled={isSubmitting}>
                        Submit
                      </Button>
                    </Form>
                  )}
                </Formik>
              )}
            </Modal.Body>
          </Modal>

          <AlertModal
            title={`Are you sure?`}
            subtitle={
              <p>
                {' '}
                You are going to delete <b>Instruction</b> you won't be able to revert this!{' '}
              </p>
            }
            isVisible={showAlertModal}
            closeModal={() => setShowAlertModal(false)}
            onConfirm={handleInstructionsDelete}
          />
        </>
      )}
    </>
  );
};

export default InstructionsTable;



