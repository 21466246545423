import React, { FC } from 'react';
import { PlatformSettingsProps } from 'src/interfaces/Platform.interface';

interface VeeqoSettingsProps extends PlatformSettingsProps {
  settings: {
    showOrderSync: boolean;
    showProductSync: boolean;
    dateRange?: { startDate: string; endDate: string }; // Add dateRange to settings

  };
  setSettings: (settings: {
    showOrderSync?: boolean;
    showProductSync?: boolean;
    dateRange?: { startDate: string; endDate: string }; // Add dateRange to settings


  }) => void;
  onDateChange: (field: string, date: Date | null) => void
}





const VeeqoSettings: FC<VeeqoSettingsProps> = ({ settings, setSettings, isReadonly  }) => (
  <>
    <div className='card m-2 p-4 shadow'>
      <div>
        <p className='h3'>Orders</p>
      </div>
      <div className='border-bottom my-2 border-2'></div>
      <div className='p-4'>
        <div className='form-check form-switch form-check-custom form-check-solid me-10'>
          <label className='form-check-label text-dark h4' htmlFor='orderSync'>
            Sync Orders
          </label>
          <input
            className='ms-8 form-check-input h-20px w-40px'
            type='checkbox'
            id='orderSync'
            checked={settings.showOrderSync}
            onChange={() => setSettings({ showOrderSync: !settings.showOrderSync })}
            style={{
              backgroundColor: settings. showOrderSync? '#3E97FF' : 'silver',
             
              height: '20px',
              width: '40px',
              cursor: isReadonly ? 'not-allowed' : 'pointer'
            }}
          />
        </div>
      </div>
    </div>
    <div className='card m-2 p-4 shadow'>
      <div>
        <p className='h3'>Product</p>
      </div>
      <div className='border-bottom my-2 border-2'></div>
      <div className='p-4'>
        <div className='form-check form-switch form-check-custom form-check-solid me-10'>
          <label className='form-check-label text-dark h4' htmlFor='productSync'>
            Sync Product
          </label>
          <input
            className='ms-8 form-check-input h-20px w-40px'
            type='checkbox'
            id='productSync'
            checked={settings.showProductSync}
            onChange={() => setSettings({ showProductSync: !settings.showProductSync })}

            style={{
              backgroundColor: settings.showProductSync ? '#3E97FF' : 'silver',
             
              height: '20px',
              width: '40px',
              cursor: isReadonly ? 'not-allowed' : 'pointer'
            }}
          />
        </div>
      </div>
    </div>
  </>
);

export default VeeqoSettings;



// import React, { FC, useState } from 'react';
// import { PlatformSettingsProps } from 'src/interfaces/Platform.interface';
// import AppDateFilter from 'src/utils/AppDateFilter'; // Ensure this path is correct

// interface VeeqoSettingsProps extends PlatformSettingsProps {
//   settings: {
//     showOrderSync: boolean;
//     showProductSync: boolean;
//     dateRange?: { startDate: string; endDate: string }; // Add dateRange to settings
//   };
//   setSettings: (settings: {
//     showOrderSync?: boolean;
//     showProductSync?: boolean;
//     dateRange?: { startDate: string; endDate: string }; // Add dateRange to settings
//   }) => void;
// }

// const VeeqoSettings: FC<VeeqoSettingsProps> = ({ settings, setSettings }) => {
//   const [showDatePicker, setShowDatePicker] = useState(false);
//   const [selectedDateRange, setSelectedDateRange] = useState<{ startDate: string; endDate: string } | null>(settings.dateRange || null);

//   const handleToggle = (key: 'showOrderSync' | 'showProductSync') => {
//     setSettings({
//       [key]: !settings[key],
//     });
//   };

//   const handleDateRangeChange = (dateRange: { startDate: string; endDate: string }) => {
//     setSelectedDateRange(dateRange);
//     setSettings({
//       ...settings,
//       dateRange,
//     });
//   };

//   return (
//     <>
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Date Range</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <button
//             className='btn btn-info'
//             onClick={() => setShowDatePicker(true)}
//           >
//             {selectedDateRange ? `${selectedDateRange.startDate} - ${selectedDateRange.endDate}` : 'Pick Date Range'}
//           </button>
//           <AppDateFilter
//             isVisible={showDatePicker}
//             handleClose={() => setShowDatePicker(false)}
//             onPickRange={(dateRange) => {
//               handleDateRangeChange(dateRange);
//               setShowDatePicker(false);
//             }}
//           />
//         </div>
//       </div>
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Orders</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <div className='form-check form-switch form-check-custom form-check-solid me-10'>
//             <label className='form-check-label text-dark h4' htmlFor='orderSync'>
//               Sync Orders
//             </label>
//             <input
//               className='ms-8 form-check-input h-20px w-40px'
//               type='checkbox'
//               id='orderSync'
//               checked={settings.showOrderSync}
//               onChange={() => handleToggle('showOrderSync')}
//             />
//           </div>
//         </div>
//       </div>
//       <div className='card m-2 p-4 shadow'>
//         <div>
//           <p className='h3'>Product</p>
//         </div>
//         <div className='border-bottom my-2 border-2'></div>
//         <div className='p-4'>
//           <div className='form-check form-switch form-check-custom form-check-solid me-10'>
//             <label className='form-check-label text-dark h4' htmlFor='productSync'>
//               Sync Product
//             </label>
//             <input
//               className='ms-8 form-check-input h-20px w-40px'
//               type='checkbox'
//               id='productSync'
//               checked={settings.showProductSync}
//               onChange={() => handleToggle('showProductSync')}
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default VeeqoSettings;
