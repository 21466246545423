// platformSlice.js

import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {  connectPlatformAsync } from "../actions/platformActions";
import {  connectedPlatformPayload } from "src/interfaces/Platform.interface";

type connectedPayload={
  connectedPlatformLoading:boolean,
    connectedPlatformData:connectedPlatformPayload| null
}


const initialState:connectedPayload = {
  connectedPlatformLoading:false,
    connectedPlatformData: null,

};

const connectedPlatformSlice = createSlice({
  name: "connectedPlatformSlice",
  initialState,
  extraReducers: (builder) => {
   
    builder.addMatcher(isAnyOf(connectPlatformAsync.pending), (state,action)=>{
      state.connectedPlatformLoading=true; 
    })
    builder.addMatcher(isAnyOf(connectPlatformAsync.fulfilled), (state,action)=>{
      
        state.connectedPlatformLoading=false; 
    
        state.connectedPlatformData = action?.payload?.data;
      })
    builder.addMatcher(isAnyOf(connectPlatformAsync.rejected), (state)=>{
      state.connectedPlatformLoading=true; 
    })
      
  },
  reducers: { 
    setconnectedPlatformData :()=> initialState},
});


export const {setconnectedPlatformData} = connectedPlatformSlice.actions
export default connectedPlatformSlice.reducer;
