import { FC } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'
import useAppToast from 'src/hooks/useAppToast'
import { jwtDecode } from 'jwt-decode'
import { createDatabaseFormAsync } from 'src/redux/actions/platformActions'

type AddNewDatabasePropsType = {
    isVisible: boolean
    closeModal: () => void
    onDatabaseAdded: () => Promise<void>;

}

const initialValues = {
    host: '',
    name: '',
    user: '',
    password: '',
}

const creationSchemaDatabase = Yup.object().shape({

    host: Yup.string()
        .min(2, 'Minimum 2 characters')
        .max(255, 'Maximum 255 characters')
        // .matches(
        //     /^([a-zA-Z0-9]([-a-zA-Z0-9]{0,61}[a-zA-Z0-9])?\.)*[a-zA-Z]{2,}$/i,
        //     'Invalid host format'
        // )
        .required('Host is required'),
    
    name: Yup.string()
        .min(2, 'Minimum 2 characters')
        .max(255, 'Maximum 255 characters')
        .matches(
                /^[a-zA-Z0-9_]+$/,
                'Only alphanumeric characters are allowed'
        )
        .required('Database name is required'),

    user: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(16, 'Maximum 16 characters')
        // .matches(
        //     /^[a-zA-Z0-9_]+$/,
        //     'Only alphanumeric characters and underscores are allowed'
        // )
        // .required('User name is required'),

    // password: Yup.string()
    //     .min(8, 'Minimum 8 characters')
    //     .max(128, 'Maximum 128 characters')
    //     .matches(

    //         /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    //         'Password must include at least one uppercase letter, one lowercase letter, one number, and one special character'
    //     )
    //     .required('Password is required'),

})
const AddNewDatabase: FC<AddNewDatabasePropsType & { onDatabaseAdded: () => void }> = ({ isVisible, closeModal, onDatabaseAdded }) => {
    interface CustomJwtPayload {
        user_id?: number
    }
    const token = sessionStorage.getItem('access_token') || '';
    const decoded_token = jwtDecode<CustomJwtPayload>(token);
    const decoded_user_id = decoded_token?.user_id

    const [loading, setLoading] = useState(false)
    const { successToast, errorToast } = useAppToast()
    const dispatch = useAppDispatch()
    const { } = useAppSelector((state) => state)

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchemaDatabase,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            try {
                dispatch(
                    createDatabaseFormAsync({
                        host: values.host,
                        name: values.name,
                        user: values.user,
                        password: values.password,
                        user_id: decoded_user_id as number,
                        // user_type: 'USER',
                        // is_superuser: false,
                        // is_staff: false,
                    })
                )
                    .then((response) => {
                        console.log('response', response)
                        if (response && response.payload?.status_code === 201) {
                            successToast(response?.payload?.message);
                            // successToast("Success");
                            closeModal();
                            onDatabaseAdded();

                        } 
                        
                        if(response.payload?.response?.data?.status_code=='409') {
                            console.log("response", response.payload)
                            errorToast(response.payload?.response?.data?.message);
                        }

                    }).finally(() => {
                        setLoading(false);
                        setSubmitting(false);
                    });
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        },
    })

    return (
        <>
            <Modal size={'lg'} centered show={isVisible} onHide={closeModal}>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header>
                        <Modal.Title>Add new database</Modal.Title>
                        <div className='d-inline-flex'>
                            <Button size='sm' disabled={loading} className='mx-2' onClick={closeModal}>
                                Close
                            </Button>
                            <Button size='sm' disabled={loading} variant='success' type='submit'>
                                Save Changes
                            </Button>
                        </div>
                    </Modal.Header>
                    <Modal.Body>


                        {formik.status && (
                            <div className='mb-lg-15 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{formik.status}</div>
                            </div>
                        )}

                        <div className='mb-8'>
                            <label className='form-label'>Database Name</label>
                            <input
                                type='text'
                                placeholder='Enter Database Name '
                                autoComplete='off'
                                {...formik.getFieldProps('name')}
                                className={clsx(
                                    'form-control',
                                    'bg-transparent',
                                    {
                                        'is-invalid': formik.touched.name && formik.errors.name,
                                        'is-valid': formik.touched.name && !formik.errors.name,
                                    }
                                )}
                            />
                            {
                                formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.name}</span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className='mb-8'>
                            <label className='form-label'>Database Host Name</label>
                            <input
                                type='text'
                                placeholder='Enter Database Host Name'
                                autoComplete='off'
                                {...formik.getFieldProps('host')}
                                className={clsx(
                                    'form-control',
                                    'bg-transparent',
                                    {
                                        'is-invalid': formik.touched.host && formik.errors.host,
                                        'is-valid': formik.touched.host && !formik.errors.host,
                                    }
                                )}
                            />
                            {
                                formik.touched.host && formik.errors.host && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.host}</span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className='mb-8'>
                            <label className='form-label'>Database User</label>
                            <input
                                type='text'
                                placeholder='Enter User Name'
                                autoComplete='off'
                                {...formik.getFieldProps('user')}
                                className={clsx(
                                    'form-control', // Keep the static class here
                                    'bg-transparent', // Moved this from the second className definition
                                    {
                                        'is-invalid': formik.touched.user && formik.errors.user,
                                        'is-valid': formik.touched.user && !formik.errors.user,
                                    }
                                )}
                            />
                            {
                                formik.touched.user && formik.errors.user && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.user}</span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className='mb-8'>
                            <label className='form-label'>Database Password</label>
                            <input
                                type='password'
                                placeholder='Enter Password'
                                autoComplete='off'
                                {...formik.getFieldProps('password')}
                                className={clsx(
                                    'form-control', // Keep the static class here
                                    'bg-transparent', // Moved this from the second className definition
                                    {
                                        'is-invalid': formik.touched.password && formik.errors.password,
                                        'is-valid': formik.touched.password && !formik.errors.password,
                                    }
                                )}
                            />
                            {
                                formik.touched.password && formik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.password}</span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>

                    </Modal.Body>
                </form>
            </Modal>
        </>
    )
}

export default AddNewDatabase