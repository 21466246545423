import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'


export const loginAsync = createAsyncThunk(
  'auth/loginAsync',
  async function (payload: {email: string; password: string}, toolkit) {
    return await AxiosClient('POST', 'login/', payload, toolkit, [])
  }
)

export const registrationAsync = createAsyncThunk(
  'auth/registrationAsync',
  async function (
    payload: {
      firstName: string,
      lastName: string,
      email: string
      password: string
      user_type: 'USER'
      is_superuser: boolean
      is_staff: boolean
    },
    toolkit
  ) {
    return await AxiosClient('POST', 'register/', payload, toolkit, [])
  }
)

export const logoutAsync = createAsyncThunk(
  'auth/logoutAsync',
  async function (payload: {email: string; password: string}, toolkit) {
    return await AxiosClient('POST', '/user/logout', payload, toolkit, [])
  }
)

export const changePassword = createAsyncThunk(
  'auth/registrationAsync',
  async function (
    payload: {
      previousPassword: string,
      newPassword: string,
      confirmPassword: string
    },
    toolkit
  ) {
    return await AxiosClient('PUT', 'update-password-confirm/', payload, toolkit, [])
  }
)

export const getUserInviteListAsync = createAsyncThunk(
  'auth/getUserInviteListAsync',
  async function (params:any, toolkit) {
    return await AxiosClient('GET', 'send-mail/', [], toolkit, params)
  }
)
