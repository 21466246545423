import React, { CSSProperties, useState, useEffect, useRef } from 'react';
import { Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks';
import { askQueryAsync } from 'src/redux/actions/platformActions'
import { addMessage } from 'src/redux/slices/agentSlice'
import { fetchConnectedAgentAsync } from 'src/redux/actions/gptModelsActions';
import Loader from 'src/utils/Loader';
import NoAgentFound from 'src/utils/NoAgentFound';
const ChatWithBot = () => {
    const modalBodyStyle: CSSProperties = {
        backgroundColor: "#f1f1f2;",
        borderRadius: "8px",
        padding: "10px",
        overflowY: "auto", // This is now correctly typed
        height: "500px", // Adjust as necessary
    };


    const messageBotStyle = {
        margin: "5px",
        padding: "10px",
        backgroundColor: "#e0e0e0",
        borderRadius: "15px",
        display: "inline-block",
        maxWidth: "80%",
    };

    const messageUserStyle = {
        margin: "5px",
        padding: "10px",
        backgroundColor: "#007bff",
        color: "white",
        borderRadius: "15px",
        display: "inline-block",
        maxWidth: "80%",
        marginLeft: "auto",
    };

    const inputGroupStyle = {
        padding: "10px",
        backgroundColor: "#fff",
        borderTop: "1px solid #ccc",
    };

    const dispatch = useAppDispatch();

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const messages = useAppSelector((state) => state.agent.messages);
    const {fetchedAgentsLoading, fetchedAgentsData } = useAppSelector(state=>state.fetchConnectedAgents)

    useEffect(() => {
        dispatch(fetchConnectedAgentAsync())
    }, [])
    

    const [inputValue, setInputValue] = useState('');
    // const [msg, setMs] = useState([
    //     { text: 'Hello! How can I assist you today?', sender: 'bot' },
    // ]);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    //  new
    
    
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(scrollToBottom, [messages]);



    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        const textarea = textareaRef.current;
        if (!textarea) return;
        textarea.style.height = 'auto';
        const maxHeight = 80;
        textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight)}px`;
    };
    

    const handleSendMessage = async () => {
        if (!inputValue.trim()) return;
        const userMessage = { text: inputValue, sender: 'user' as 'user' };
        dispatch(addMessage(userMessage));
        setInputValue('');
        dispatch(askQueryAsync({ userQuery: inputValue }));
        setInputValue(''); // Clear input after sending
    };

    // const handleSendMessage = async () => {
    //     if (!inputValue.trim()) return;
    //     const userMessage = { text: inputValue, sender: 'user' };
    //     // setMessages([...messages, userMessage]);
    //     setMessages(prevMessages => [...prevMessages, userMessage]);
    //     setInputValue(''); // Clear input after sending

    //     // Dispatch the askQueryAsync action with the user's message
    //     try {
    //         const response = await dispatch(askQueryAsync({ userQuery: inputValue })).unwrap();
    //         const botReply = { text: response.answer, sender: 'bot' };
    //         setMessages(prevMessages => [...prevMessages, botReply]);
    //     } catch (error) {
    //         // toast.error('Failed to send message.');
    //         console.error('Error sending message:', error);
    //     }
    // }

    // const handleInputChange = (event) => {
    //     setInputValue(event.target.value);

    //     // Reset the height to auto to correctly calculate the new scrollHeight
    //     event.target.style.height = 'auto';
    //     // Set height based on scrollHeight, up to 4 lines (assuming 20px line height)
    //     const maxHeight = 80; // Adjust line height (20px) * max lines (4)
    //     event.target.style.height = `${Math.min(event.target.scrollHeight, maxHeight)}px`;
    // };

    // const renderMessage = (message, index) => {
    //     const isBot = message.sender === 'bot';
    //     return (
    //         <div key={index} className={`d-flex ${isBot ? 'justify-content-start' : 'justify-content-end'} mb-2`}>
    //             <div className={`p-2 ${isBot ? 'bg-light' : 'bg-info text-white'} rounded`}>
    //                 {message.text}
    //             </div>
    //         </div>
    //     );
    // };
    const renderMessage = (message, index) => {
        const isBot = message.sender === 'bot';
        return (
            <div key={index} className={`d-flex mb-2 ${isBot ? 'justify-content-start' : 'justify-content-end'}`}>
                <div style={isBot ? messageBotStyle : messageUserStyle}>
                    {message.text}
                </div>
            </div>
        );
    };

if(fetchedAgentsLoading) return <Loader/>

if(fetchedAgentsData.length || fetchedAgentsData.length ===undefined ) return <NoAgentFound/>

    return (
        <>
            <Modal.Header>
                <select disabled defaultValue={'a'} className='form-select form-select-sm w-25' >
                    <option value="">Select a Platform</option>
                    <option value="a">Platform A</option>
                    <option value="b">Platform B</option>
                    <option value="c">Platform C</option>
                </select>
            </Modal.Header>
            <div className="separator mt-5 border-secondary"></div>
            <Modal.Body style={modalBodyStyle}>
                {messages.map(renderMessage)}
                <div ref={messagesEndRef} />
            </Modal.Body>
            <Modal.Footer className='border-top-0'>
                <InputGroup>
                    <FormControl
                        as="textarea"
                        placeholder='Type your query...'
                        value={inputValue}
                        onChange={handleInputChange}
                        ref={textareaRef}
                        style={{ resize: 'none', overflowY: 'auto', height: '40px' }} // Starting height of one line
                        // onKeyPress={event => {
                        //     if (event.key === 'Enter' && !event.shiftKey) {
                        //         event.preventDefault(); // Prevent the default action to avoid line break
                        //         handleSendMessage();
                        //     }
                        // }}
                    />
                    <Button disabled={inputValue==''} variant='secondary' onClick={handleSendMessage}>
                        Send
                    </Button>
                </InputGroup>
                {/* <ToastContainer /> */}
            </Modal.Footer>
        </>
    );
};

export default ChatWithBot;
