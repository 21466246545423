import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { INVENTORY_ITEM } from 'src/interfaces/Platform.interface';
import {
  getInventoriesAsync,
    getPlatformOrderData,} from 'src/redux/actions/platformActions'; 
// Assuming a basic structure for an order, adjust according to your actual order structure
export type Order = {
  api_code: string;
  items: string;
  status: string;
  total: string;
  date: string;
};

type OrderState = {
  orders: Order[] | [];
  totalOrders:number
  loading: boolean;
  error: string | null;
  inventories:INVENTORY_ITEM[]|[]
  totalInventories:number
  inventoryLoader:boolean,
  platformName: string,
  
  
};

const initialState: OrderState = {
  orders: [],
  totalOrders:0,
  loading: false,
  error: null,
  inventories:[],
  totalInventories:0,
  inventoryLoader:false,
  platformName:'',
  
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    resetOrderState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(getPlatformOrderData.pending), (state) => {
        state.loading = true;
        state.error = null;
      })
      .addMatcher(isAnyOf(getPlatformOrderData.fulfilled), (state, action) => {
        console.log('Fetched orders:', action.payload); // Debug log
        state.loading = false;
        if(action.payload?.data){
          state.orders = action.payload.data
          state.totalOrders = action.payload?.total_records
        }
      })
      .addMatcher(isAnyOf(getPlatformOrderData.rejected), (state, action) => {
        state.loading = false;
        state.error = action.error?.message || 'Failed to fetch orders';
      })
      // .addMatcher(isAnyOf(getPlatformOrderData.fulfilled), (state, action) => {
      //   // Ensure state.orders is an array before trying to spread it
      //   const newOrder: Order = action.payload;
      //   state.orders = state.orders ? [...state.orders, newOrder] : [newOrder];
      // })

      builder.addMatcher(isAnyOf(getInventoriesAsync.pending),(state)=>{
        state.inventoryLoader = true
      })
      builder.addMatcher(isAnyOf(getInventoriesAsync.fulfilled),(state, action)=>{
        console.log('inventories', action.payload);
        // console.log('Platform Name:', action.payload?.platform_name);

        if(action.payload?.data){
          state.totalInventories = action.payload?.total_records
          state.inventories = action.payload?.data
          state.platformName = action.payload?.platform_name || ''; 
        }
        state.inventoryLoader = false
      })
      builder.addMatcher(isAnyOf(getInventoriesAsync.rejected),(state)=>{
        state.inventoryLoader = false
      })
  },
});

export const { resetOrderState } = ordersSlice.actions;
export default ordersSlice.reducer;
