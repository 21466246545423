import React, { CSSProperties, useState, useEffect } from 'react';
import { Button, Modal, Form, FormGroup, FormLabel, FormControl, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks';
import 'react-toastify/dist/ReactToastify.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { createInstructionsAsync, fetchConnectedPlatformAsync } from '../../../redux/actions/platformActions';
import { fetchConnectedAgentAsync } from 'src/redux/actions/gptModelsActions';
import Loader from 'src/utils/Loader';
import { jwtDecode } from 'jwt-decode';
import { useNavigate, Link } from 'react-router-dom';
import DaySelector from './DaySelector'; // Import the new component
import { UserAgentSetting } from 'src/interfaces/Platform.interface';
import { Card } from 'react-bootstrap';
import NoAgentFound from 'src/utils/NoAgentFound';

interface Option {
    value: string;
    label: string;
}

interface CustomJwtPayload {
    user_id?: number;
}

const AddInstructions: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [instructions, setInstructions] = useState<string>("");
    const [updateAfterHour, setUpdateAfterHour] = useState<string>('3');
    const [inputDisabled, setInputDisabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [platform, setPlatform] = useState<Option[]>([]);
    const [selectedPlatform, setSelectedPlatform] = useState<Option | null>(null);
    const [updateFrequency, setUpdateFrequency] = useState<'hourly' | 'days'>('hourly');
    const [selectedDays, setSelectedDays] = useState<string[]>([]); // New state for selected days
    const [hasAgent, setHasAgent] = useState<boolean>(false); // New state to check if user has an agent

    const token = sessionStorage.getItem('access_token') || '';
    const decoded_token = jwtDecode<CustomJwtPayload>(token);
    const user_id = decoded_token?.user_id;
    if (user_id === undefined) {
        throw new Error('User ID is undefined');
    }

    const styles: { [key: string]: CSSProperties } = {
        dateTimeStyle: {
            padding: '15px',
            width: '100%'
        },
        radioLabelBlack: {
            color: 'black'
        },
        modalBodyStyle: {
            backgroundColor: "#f9f9f9",
            borderRadius: "8px",
            padding: "10px",
            overflowY: "auto",
            marginTop: '20px',
        },
        customMargin: {
            marginRight: "0.5rem"
        },
        dropDownStyles: {
            margin: "10px 10px"
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await dispatch(fetchConnectedPlatformAsync({ user_id })).unwrap();
                const platformOptions = response?.data.map((item: any) => ({
                    value: item.id.toString(),
                    label: item.display_name || item.name
                }));
                setPlatform(platformOptions);

                // Fetch connected agents
                const agentResponse = await dispatch(fetchConnectedAgentAsync()).unwrap();
                // console.log("agentResponse=>", agentResponse)
                // console.log("length=>", agentResponse.length)

                setHasAgent(agentResponse.length > 0);


            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [dispatch, user_id]);

    const handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!instructions) {
            toast.error('Instructions cannot be empty.');
            return;
        }

        if (!selectedPlatform) {
            toast.error('Please select a platform.');
            return;
        }

        if (!user_id) {
            toast.error('User ID is missing.');
            return;
        }

        if (updateFrequency === 'days' && selectedDays.length === 0) {
            toast.error('Please select at least one day.');
            return;
        }

        const updateAfter = `${updateAfterHour}:00`;
        if (updateAfter === '00:00') {
            toast.error('Please set a valid update time.');
            return;
        }

        setIsLoading(true);

        const dataToSubmit = {
            user_id,
            instructions,
            update_after: updateAfter,
            platform_id: selectedPlatform?.value,
            frequency: updateFrequency,
            selected_days: selectedDays,
        };

        try {
            console.log("dataToSubmit=>", dataToSubmit)
            const response = await dispatch(createInstructionsAsync(dataToSubmit)).unwrap();
            const { status, message } = response;
            console.log("response=>", response);

            if (status === 'success') {
                toast.success(message);
            } else {
                toast.warning(`Warning: ${message}`);
            }
        } catch (error) {
            console.error('Error submitting query:', error);
            toast.error('An error occurred. Please try again later.');
        } finally {
            setIsLoading(false);
            setInputDisabled(false);
            setInstructions('');
            setSelectedDays([]); // Reset selected days
        }
    };

    const generateDropdownOptions = (start: number, end: number): JSX.Element[] => {
        const options: JSX.Element[] = [];
        for (let i = start; i <= end; i++) {
            const value = i < 10 ? `0${i}` : `${i}`;
            options.push(<option key={value} value={value}>{value}</option>);
        }
        return options;
    };

    const handleAllInstructionsClick = () => {
        navigate('/all-tasks', { state: { user_id } });
    };

    const handlePlatformSelect = (eventKey: string | null) => {
        const selected = platform.find(option => option.value === eventKey);
        if (selected) {
            setSelectedPlatform(selected);
        }
    };

    const handleAddAgentClick = () => {
        navigate('/crafted/pages/profile/openai-key'); // Use the correct path to navigate
    };


    return (
        <>
            {isLoading ? (
                <Loader />
            ) : hasAgent ? (
                <div className='mt-2'>
                    <div className='d-flex justify-content-between justify-content-start'>
                        <h1 onClick={handleAllInstructionsClick}>
                            Assign Task
                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip id="tooltip-right">Generate an alert or summary for your task</Tooltip>}
                            >
                                <i className="fa fa-info-circle" style={{ fontSize: '22px', color: 'black', marginLeft: '0.5rem', cursor: 'pointer' }}></i>
                            </OverlayTrigger>
                        </h1>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <Button className='btn btn-light-info btn-sm' onClick={handleAllInstructionsClick}>All Instructions</Button>
                    </div>
                    <div className='shadow' style={styles.modalBodyStyle}>
                        <div className='m-5' style={{ fontSize: '1.05rem', fontWeight: '500' }}>
                            Select the platform for which you want to assign task
                        </div>
                        <Dropdown onSelect={handlePlatformSelect} style={styles.dropDownStyles}>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ width: '100%' }}>
                                {selectedPlatform ? selectedPlatform.label : "Select Platform"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ width: '100%', textAlign: 'center' }}>
                                {platform.length === 0 ? (
                                    <Dropdown.Item disabled>No platforms available</Dropdown.Item>
                                ) : (
                                    platform.map((option) => (
                                        <Dropdown.Item key={option.value} eventKey={option.value}>
                                            {option.label}
                                        </Dropdown.Item>
                                    ))
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Form style={styles.modalBodyStyle} onSubmit={handleFormSubmit}>
                            <FormGroup>
                                <FormLabel>Enter task summary
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip id="tooltip-right">Eg: Give me all the orders greater than $50 or alert me when any product's quantity reaches below 5.</Tooltip>}
                                    >
                                        <i className="fa fa-info-circle" style={{ fontSize: '12px', color: 'black', marginLeft: '0.5rem', cursor: 'pointer' }}></i>
                                    </OverlayTrigger>
                                </FormLabel>
                                <FormControl as='textarea'
                                    rows={5}
                                    style={{ maxHeight: "200px", resize: 'none' }}
                                    value={instructions}
                                    onChange={(e) => setInstructions(e.target.value)}
                                    disabled={inputDisabled}
                                />
                            </FormGroup>
                            <FormGroup style={styles.dateTimeStyle}>
                                <FormLabel>How often you want your Agent to perform the mentioned task</FormLabel>
                                <div className="d-flex mt-3 mb-4 align-items-center text-black">
                                    <DaySelector selectedDays={selectedDays} onChange={setSelectedDays} />
                                </div>
                                <FormLabel>Select duration
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip id="tooltip-right">It will run at every selected interval. For example, if 3 hours is chosen, it will run every 3 hours.</Tooltip>}
                                    >
                                        <i className="fa fa-info-circle" style={{ fontSize: '12px', color: 'black', marginLeft: '0.5rem', cursor: 'pointer' }}></i>
                                    </OverlayTrigger>
                                </FormLabel>
                                <FormControl
                                    as="select"
                                    className="me-2 mt-2"
                                    value={updateAfterHour}
                                    onChange={(e) => setUpdateAfterHour(e.target.value)}
                                    style={{ width: '160px' }}
                                >
                                    <option value="3">In every 3:00 hours</option>
                                    <option value="6">In every 6:00 hours</option>
                                    <option value="12">In every 12:00 hours</option>
                                </FormControl>
                                <Button className='btn btn-info float-end mt-5' type="submit">
                                    Submit
                                </Button>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
            ) : (
                <NoAgentFound/>
            )}
        </>
    );
};

export default AddInstructions;
