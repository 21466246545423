import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosClient from "../AxiosClient";


export const createConnectionWithAIModelAsync = createAsyncThunk(
    "gptModels/createConnectionWithAIModel",
    async function (payload: {
        model_name: string,
        token: string,
    }, toolkit) {
        return await AxiosClient("POST", "api/user-settings/", payload, toolkit, [])
    }
);

export const fetchConnectedAgentAsync = createAsyncThunk(
    "gptModels/fetchConnectedAgent",
    async function (payload, toolkit) {
        const url = `api/user-settings/`
        return await AxiosClient("GET", url, [], toolkit, [])
    }
);

export const fetchAvailableGPTModelsAsync = createAsyncThunk(
    "gptModels/fetchAvailableGPTModel",
    async function (payload, toolkit) {
        const url = `api/available-models/`
        return await AxiosClient("GET", url, [], toolkit, [])
    }
);

export const deleteAgentAsync = createAsyncThunk(
    "gptModels/deleteAgent",
    async function (payload: { id: number }, toolkit) {
      const url = `api/user-settings/${payload.id}`
      return await AxiosClient("DELETE", url, [], toolkit, [])
    }
  
  );

export const addAgentAsync = createAsyncThunk(
    'agent/add',
    async function (params: { friendlyName: string; token: string; model: string }, toolkit) {
      try {
        return await AxiosClient('POST', 'api/user-settings/', params, toolkit, {});
      } catch (error) {
        return toolkit.rejectWithValue(error);
      }
    }
  );


export const updateAgentAsync = createAsyncThunk(
    'agent/update',
    async function (params: { id: number; friendlyName: string; token: string; model: string }, toolkit) {
      try {
        return await AxiosClient('PUT', 'api/user-settings/', params, toolkit, {});
      } catch (error) {
        return toolkit.rejectWithValue(error);
      }
    }
  );



  