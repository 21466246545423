import React, { FC } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

type DatabaseOptionModalPropsType = {
    isVisible: boolean;
    closeModal: () => void;
    onOptionSelected: (choice: string) => void;
};

const DatabaseOptionModal: FC<DatabaseOptionModalPropsType> = ({
    isVisible,
    closeModal,
    onOptionSelected,
}) => {
    const handleSelection = (choice: string) => {
        onOptionSelected(choice); // 'own' or 'chanakya'
        closeModal(); // Close modal after selection
    };

    return (
        <Modal show={isVisible} onHide={closeModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>Choose Database Option</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div key={`default-radio`} className="mb-3 form-check">
                        <Form.Check
                            className="mb-3 "
                            type="radio"
                            id={`own-database`}
                            label={`Use Own Database`}
                            name="databaseOption"
                            onChange={() => handleSelection('own')}
                        />
                        <Form.Check
                            type="radio"
                            id={`chanakya-database`}
                            label={`Use Chanakya Database`}
                            name="databaseOption"
                            onChange={() => handleSelection('chanakya')}
                        />
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DatabaseOptionModal;
