import { useEffect, useState } from 'react';
import AlertModal from 'src/utils/modals/AlertModal';
import ConnectDatabaseStrip from './components/ConnectDatabaseStrip';
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks';
import {
  createDatabaseFormAsync,
  fetchUserDbAsync,
  deleteDatabaseAsync,
} from 'src/redux/actions/platformActions';
import { ConnectedDatabase } from 'src/interfaces/Platform.interface';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import AddNewDatabase from './components/AddNewDatabase';
import DatabaseOptionModal from './components/DatabaseOptionModal';
import Loader from 'src/utils/Loader';



const DatabaseConnectivity = () => {
  interface CustomJwtPayload {
    user_id?: number;
  }
  const dispatch = useAppDispatch();
  const token = sessionStorage.getItem('access_token') || '';
  const decoded_token = jwtDecode<CustomJwtPayload>(token);
  const user_id = decoded_token?.user_id;

  const [showDatabaseModel, setShowDatabaseModel] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [selectedDatabase, setSelectedDatabase] = useState<ConnectedDatabase|null>(null);

  const [showDatabaseOptionModal, setShowDatabaseOptionModal] = useState(false);

  const { databases, loading : dbsLoading } = useAppSelector(state => state.database);

  useEffect(() => {
    if (user_id) {
      dispatch(fetchUserDbAsync({ user_id }));
    }
  }, [user_id, dispatch]);


  // const onClickAddNewDatabase = () => setShowDatabaseModel(true);

  const onClickAddNewDatabase = () => {
    console.log('Opening Database Option Modal...');
    setShowDatabaseOptionModal(true);
  };
  

  const handleDatabaseOptionSelected = (choice) => {
    if (choice === 'own') {
      setShowDatabaseModel(true); 
    } else {
      // Handle connection to Chanakya database here
      console.log('Connecting to Chanakya database...');
    }
  };


  const onDeleteDatabase = (database) => {
    setSelectedDatabase(database);
    setShowAlertModal(true);
  };

  const handleDatabaseAdded = async () => {
    if (user_id) {

      await dispatch(fetchUserDbAsync({ user_id })).unwrap();
      setShowDatabaseModel(false);
    } else {
      console.error('user_id is undefined');
    }
  };



  const deleteDatabase = async () => {
    try {
      if (selectedDatabase === null) {
        console.error('Selected database ID is null');
        return;
      }
      await dispatch(deleteDatabaseAsync({ id: selectedDatabase.id }));
      toast.success('Database deleted successfully!');
      handleDatabaseAdded();
    } catch (error) {
      console.error('Error deleting database:', error);
      toast.error('Failed to delete database.');
    } finally {
      setShowAlertModal(false);
    }
  };

  if(dbsLoading) return <Loader/>

  return (
    <>
      <div id='test1' className='p-4 pt-0 rounded'>
        <div className='d-flex justify-content-end'>
          <button className='btn btn-light-info' onClick={onClickAddNewDatabase}>
            Connect Database
          </button>
        </div>
      </div>
      <div className='py-2 pt-0'>
        {(databases || []).length > 0 ? (
          (databases || []).map((database: ConnectedDatabase) => (
            <ConnectDatabaseStrip
              key={database.id}
              database={database}
              onDelete={() => onDeleteDatabase(database)}
            />
          ))
        ) : (
          <p>No databases connected.</p>
        )}
      </div>
      <AlertModal
        title='Are you sure?'
        subtitle={<p>Want to delete <b>{selectedDatabase?.db_name}</b>  after this you will lost this Database </p>}
        isVisible={showAlertModal}
        closeModal={() => setShowAlertModal(false)}
        onConfirm={deleteDatabase}
      />
      <AddNewDatabase
        isVisible={showDatabaseModel}
        closeModal={() => setShowDatabaseModel(false)}
        onDatabaseAdded={handleDatabaseAdded}
      />
      <DatabaseOptionModal
        isVisible={showDatabaseOptionModal}
        closeModal={() => setShowDatabaseOptionModal(false)}
        onOptionSelected={handleDatabaseOptionSelected}
      />

      {/* <ToastContainer /> */}
    </>
  );
};

export default DatabaseConnectivity;
