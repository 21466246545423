
// import clsx from 'clsx'
// import React, { CSSProperties, FC, useState } from 'react'
// import { Link } from 'react-router-dom'
// import { ConnectedPlatform } from 'src/interfaces/Platform.interface'
// import { KTSVG, toAbsoluteUrl } from 'src/marketConnect/helpers'

// type ConnectedPlatformStripe = {
//     platform: ConnectedPlatform
//     onDelete: () => void
//     openSettingsModal: () => void
//     openPlatformdata: () => void
// }

// const ConnectPlatformStrip: FC<ConnectedPlatformStripe> = ({ platform, onDelete, openSettingsModal, openPlatformdata }) => {

//     const menuStyle: CSSProperties = { zIndex: 105, position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate3d(-20px, 60px, 0px)" }

//     const [menuOpen, setMenuOpen] = useState(false)

//     // Function to render menu items based on available platforms
//     const renderMenuItems = () => {
//         console.log("------platformnamechecking--------",platform)
//         const availablePlatforms = platform.available_platforms || [];
//         if (availablePlatforms.length === 0) return null;

//         const platformName = availablePlatforms[0]?.name || '';
//         console.log("------platformnamechecking--------",platformName)
//         switch (platformName) {
            
//             case 'VEEQO':
//                 return (
//                     <>
//                         <div className='menu-item'>
//                             <Link className='menu-link fs-5 bg-light fw-bold text-info' to={`/orders/order/${platform.id}`}>
//                                 View Order
//                             </Link>
//                         </div>
//                         <div className='separator my-2'></div>
//                         <div className='menu-item'>
//                             <Link className='menu-link fs-5 bg-light fw-bold text-info' to={`/inventories/${platform.id}`}>
//                                 View Inventory
//                             </Link>
//                         </div>
//                     </>
//                 );
//             case 'ShipHero':
//                 return (
//                     <>
                        
//                         <div className='separator my-2'></div>
//                         <div className='menu-item'>
//                             <Link className='menu-link fs-5 bg-light fw-bold text-info' to={`/inventories/${platform.id}`}>
//                                 View Inventory
//                             </Link>
//                         </div>
//                         <div className='separator my-2'></div>
//                         <div className='menu-item'>
//                             <Link className='menu-link fs-5 bg-light fw-bold text-info' to={`/orders/transfers/${platform.id}`}>
//                                 View Transfer 
//                             </Link>
//                         </div>
//                         <div className='separator my-2'></div>
//                         <div className='menu-item'>
//                             <Link className='menu-link fs-5 bg-light fw-bold text-info' to={`/orders/purchases/${platform.id}`}>
//                                 View Purchase 
//                             </Link>
//                         </div>
//                     </>
//                 );
//                 case 'Shopify':
//                     return (
//                         <>
//                             <div className='menu-item'>
//                                 <Link className='menu-link fs-5 bg-light fw-bold text-info' to={`/orders/order/${platform.id}`}>
//                                     View Order
//                                 </Link>
//                             </div>
//                             <div className='separator my-2'></div>
//                             <div className='menu-item'>
//                                 <Link className='menu-link fs-5 bg-light fw-bold text-info' to={`/inventories/${platform.id}`}>
//                                     View Inventory
//                                 </Link>
//                             </div>
//                         </>
//                     );
//             // Add more cases for other platform names as needed
//             default:
//                 return null;
//         }
//     }
// console.log("--------------stripchecking----------------",platform)
//     return (
//         <div className='col-12'>
//             <div className='p-2 card shadow my-2'>
//                 <div className='p-2 d-flex flex-column justify-content-between border border-light-primary rounded'>
//                     <div className='d-flex justify-content-between'>
//                         <div className='align-self-center my-2 p-2'>
//                             <div className='d-flex align-items-center'>
//                                 <h4 className='title mb-0'>{platform.display_name}</h4>
//                                 <span
//                                     className={`badge ms-4 ${platform?.isConnected ? 'badge-success' : 'badge-danger'}`}
//                                 >
//                                     {platform?.isConnected ? 'Connected' : 'Not Connected'}
//                                 </span>
//                                 <span
//                                     className={`badge ms-4 ${platform?.isConnected ? 'badge-primary' : 'badge-danger'}`}
//                                 >
//                                     {<KTSVG path="" className="bi bi-database p-2 text-white" />}
//                                     {platform?.isConnected && platform.database?.db_name !== "" ? platform.database?.db_name : 'Database name not found'}
//                                 </span>
//                             </div>
//                         </div>

//                         <div className='d-inline-flex align-items-baseline align-self-center'>
//                             <div className='d-none d-md-inline-flex align-items-center justify-content-end'>
//                                 <button type="button" onClick={() => setMenuOpen(!menuOpen)} className="btn p-2" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
//                                     {/* <img src={toAbsoluteUrl("/favicon.ico")} className="h-40px w-40px" /> */}
//                                     <KTSVG path="" className="bi bi-three-dots-vertical p-2 fs-2 text-dark" />
//                                 </button>
//                                 <div
//                                     className={clsx("menu menu-sub menu-sub-dropdown w-100px w-md-200px", {
//                                         'show': menuOpen
//                                     })}
//                                     data-kt-menu="true"
//                                     key={menuOpen + ''}
//                                     data-popper-placement="start"
//                                     style={menuOpen ? menuStyle : {}}
//                                 >
//                                     <div className="px-7 py-5">
//                                         {renderMenuItems()}
//                                     </div>
//                                 </div>
//                             </div>
//                             <button className='btn p-2' onClick={openSettingsModal}>
//                                 {/* <KTSVG path="media/icons/duotune/coding/cod009.svg" className="svg-icon-muted svg-icon-2hx" /> */}
//                                 <KTSVG path="" className="bi bi-gear p-2 fs-2 text-dark" />
//                             </button>
//                             <button className='btn p-2 btn-active-color-danger btn-icon' onClick={onDelete}>
//                                 {/* <KTSVG path="media/icons/duotune/general/gen034.svg" className="svg-icon-muted svg-icon-2hx" /> */}
//                                 <KTSVG path="" className="bi bi-trash p-2 fs-2 text-dark " />

//                             </button>
//                         </div>
//                     </div>
//                     <div className='d-md-none d-inline-flex align-items-center justify-content-end'>
//                         <Link className='btn btn-sm btn-info' to={`/orders/${platform.id}`}>
//                             View Order
//                         </Link>
//                         <Link className='btn btn-sm btn-info ms-4' to={`/inventories/${platform.id}`}>
//                             View Inventory
//                         </Link>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default ConnectPlatformStrip








import clsx from 'clsx'
import React, { CSSProperties, FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { ConnectedPlatform } from 'src/interfaces/Platform.interface'
import { KTSVG, toAbsoluteUrl } from 'src/marketConnect/helpers'

type ConnectedPlatformStripe = {
    platform: ConnectedPlatform
    onDelete: () => void
    openSettingsModal: () => void
    openPlatformdata: () => void
}

const ConnectPlatformStrip: FC<ConnectedPlatformStripe> = ({ platform, onDelete, openSettingsModal, openPlatformdata }) => {

    const menuStyle: CSSProperties = { zIndex: 105, position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate3d(-20px, 60px, 0px)" }

    const [menuOpen, setMenuOpen] = useState(false)

    // Function to render menu items based on available platforms
    const renderMenuItems = () => {
        console.log("------platformnamechecking--------",platform)
        const availablePlatforms = platform.available_platforms || [];
        if (availablePlatforms.length === 0) return null;

        const platformName = availablePlatforms[0]?.name || '';
        console.log("------platformnamechecking--------",platformName)
        switch (platformName) {
            
            case 'VEEQO':
                return (
                    <>
                        <div className='menu-item'>
                            <Link className='menu-link fs-5 bg-light fw-bold text-info' to={`/orders/order/${platform.id}`}>
                                View Order
                            </Link>
                        </div>
                        <div className='separator my-2'></div>
                        <div className='menu-item'>
                            <Link className='menu-link fs-5 bg-light fw-bold text-info' to={`/inventories/inventories/${platform.id}`}>
                                View Inventory
                            </Link>
                        </div>
                    </>
                );
            case 'ShipHero':
                return (
                    <>
                        
                        <div className='separator my-2'></div>
                        <div className='menu-item'>
                            <Link className='menu-link fs-5 bg-light fw-bold text-info' to={`/inventories/inventories/${platform.id}`}>
                                View Inventory
                            </Link>
                        </div>
                        <div className='separator my-2'></div>
                        <div className='menu-item'>
                            <Link className='menu-link fs-5 bg-light fw-bold text-info' to={`/orders/transfers/${platform.id}`}>
                                View Transfer 
                            </Link>
                        </div>
                        <div className='separator my-2'></div>
                        <div className='menu-item'>
                            <Link className='menu-link fs-5 bg-light fw-bold text-info' to={`/orders/purchases/${platform.id}`}>
                                View Purchase 
                            </Link>
                        </div>
                    </>
                );
                case 'Shopify':
                    return (
                        <>
                            <div className='menu-item'>
                                <Link className='menu-link fs-5 bg-light fw-bold text-info' to={`/orders/order/${platform.id}`}>
                                    View Order
                                </Link>
                            </div>
                            <div className='separator my-2'></div>
                            <div className='menu-item'>
                                <Link className='menu-link fs-5 bg-light fw-bold text-info' to={`/inventories/products/${platform.id}`}>
                                    View Products
                                </Link>
                            </div>
                        </>
                    );
            // Add more cases for other platform names as needed
            default:
                return null;
        }
    }
console.log("--------------stripchecking----------------",platform)
    return (
        <div className='col-12'>
            <div className='p-2 card shadow my-2'>
                <div className='p-2 d-flex flex-column justify-content-between border border-light-primary rounded'>
                    <div className='d-flex justify-content-between'>
                        <div className='align-self-center my-2 p-2'>
                            <div className='d-flex align-items-center'>
                                <h4 className='title mb-0'>{platform.display_name}</h4>
                                <span
                                    className={`badge ms-4 ${platform?.isConnected ? 'badge-success' : 'badge-danger'}`}
                                >
                                    {platform?.isConnected ? 'Connected' : 'Not Connected'}
                                </span>
                                <span
                                    className={`badge ms-4 ${platform?.isConnected ? 'badge-primary' : 'badge-danger'}`}
                                >
                                    {<KTSVG path="" className="bi bi-database p-2 text-white" />}
                                    {platform?.isConnected && platform.database?.db_name !== "" ? platform.database?.db_name : 'Database name not found'}
                                </span>
                            </div>
                        </div>

                        <div className='d-inline-flex align-items-baseline align-self-center'>
                            <div className='d-none d-md-inline-flex align-items-center justify-content-end'>
                                <button type="button" onClick={() => setMenuOpen(!menuOpen)} className="btn p-2" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                                    {/* <img src={toAbsoluteUrl("/favicon.ico")} className="h-40px w-40px" /> */}
                                    <KTSVG path="" className="bi bi-three-dots-vertical p-2 fs-2 text-dark" />
                                </button>
                                <div
                                    className={clsx("menu menu-sub menu-sub-dropdown w-100px w-md-200px", {
                                        'show': menuOpen
                                    })}
                                    data-kt-menu="true"
                                    key={menuOpen + ''}
                                    data-popper-placement="start"
                                    style={menuOpen ? menuStyle : {}}
                                >
                                    <div className="px-7 py-5">
                                        {renderMenuItems()}
                                    </div>
                                </div>
                            </div>
                            <button className='btn p-2' onClick={openSettingsModal}>
                                {/* <KTSVG path="media/icons/duotune/coding/cod009.svg" className="svg-icon-muted svg-icon-2hx" /> */}
                                <KTSVG path="" className="bi bi-gear p-2 fs-2 text-dark" />
                            </button>
                            <button className='btn p-2 btn-active-color-danger btn-icon' onClick={onDelete}>
                                {/* <KTSVG path="media/icons/duotune/general/gen034.svg" className="svg-icon-muted svg-icon-2hx" /> */}
                                <KTSVG path="" className="bi bi-trash p-2 fs-2 text-dark " />

                            </button>
                        </div>
                    </div>
                    <div className='d-md-none d-inline-flex align-items-center justify-content-end'>
                        <Link className='btn btn-sm btn-info' to={`/orders/${platform.id}`}>
                            View Order
                        </Link>
                        <Link className='btn btn-sm btn-info ms-4' to={`/inventories/${platform.id}`}>
                            View Inventory
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConnectPlatformStrip

