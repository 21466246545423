import React, { FC, ReactNode } from 'react'
import { KTSVG } from 'src/marketConnect/helpers'

const AppInfoAlert:FC<{title:string, description:ReactNode}> = ({title,description}) => {
  return (
    <div>
      <div className='alert bg-light-primary   d-flex align-items-center p-5 mb-10'>
        <span className='svg-icon svg-icon-2hx svg-icon-primary me-3'>
          <KTSVG
            path='media/icons/duotune/general/gen044.svg'
            className='svg-icon-muted svg-icon-2hx'
          />
        </span>

        <div className='d-flex flex-column'>
          <h5 className='mb-1'>{title}</h5>
          <span>
            {description}
          </span>
        </div>
      </div>
    </div>
  )
}

export default AppInfoAlert
