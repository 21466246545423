

// import Loader from 'src/utils/Loader';
// import ShipHeroSettings from './ShipHeroSettings';
// import VeeqoSettings from './VeeqoSettings';
// import ShopifySettings from './ShopifySettings';
// import DatePicker from 'react-datepicker'; // Ensure you have installed react-datepicker
// import 'react-datepicker/dist/react-datepicker.css';
// import { toast, ToastContainer } from 'react-toastify';
// import { fetchConnectedPlatformSettingAsync, retrivePlatformDataAsync } from 'src/redux/actions/platformActions';
// import { FC, useEffect, useState } from 'react';
// import { PlatformDataType } from 'src/interfaces/Platform.interface';
// import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks';
// import { Button, Modal } from 'react-bootstrap';



// type PlatformComponents = {
//   [key: string]: FC<any>;
// };
// const platformComponents: PlatformComponents = {
//   "ShipHero": ShipHeroSettings,
//   "VEEQO": VeeqoSettings,
//   "Shopify": ShopifySettings,
// };

// type ConnectedPlatformSettingModalPropsType = {
//   isVisible: boolean;
//   closeModal: () => void;
//   onSaveSettings: (settings: any) => Promise<void>;
//   selectedPlatform: PlatformDataType;
// };

// const ConnectedPlatformSettingModal: FC<ConnectedPlatformSettingModalPropsType> = ({
//   isVisible,
//   closeModal,
//   selectedPlatform,
//   onSaveSettings,
// }) => {
//   const [settings, setSettings] = useState<any>({});
//   const [isReadonly, setIsReadonly] = useState<boolean>(true);
//   const [errors, setErrors] = useState<{ [key: string]: string }>({});
//   const [isSaving, setIsSaving] = useState<boolean>(false);
//   const [isLoading, setIsLoading] = useState<boolean>(true); // Initialize as true for loading state
//   const dispatch = useAppDispatch();
//   const { retrivedPlatformData } = useAppSelector(state => state.retrivePlatformData);

//   useEffect(() => {
//     if (isVisible && selectedPlatform?.id) {
//       setIsLoading(true);
//       setIsReadonly(true);
//       dispatch(retrivePlatformDataAsync({ id: selectedPlatform.id }))
//         .finally(() => setIsLoading(false)); // Set loading to false after fetching
//     }
//   }, [isVisible, selectedPlatform, dispatch]);

//   useEffect(() => {
//     if (retrivedPlatformData) {
//       const platformName = retrivedPlatformData.availablePlatform?.name;
//       const initialSettings: any = {};
//       switch (platformName) {
//         case "VEEQO":
//           initialSettings.showOrderSync = !!retrivedPlatformData.is_order_sync_enabled;
//           initialSettings.showProductSync = !!retrivedPlatformData.is_product_sync_enables;
//           break;
//         case "ShipHero":
//           initialSettings.showPurchaseOrder = !!retrivedPlatformData.is_purchase_order_sync_enabled;
//           initialSettings.showInventory = !!retrivedPlatformData.is_inventory_sync_enabled;
//           initialSettings.showTransferOrder = !!retrivedPlatformData.is_transfer_order_sync_enabled;
//           initialSettings.purchaseOrderDateTime = retrivedPlatformData.purchase_order_sync_startdate;
//           initialSettings.transferOrderDateTime = retrivedPlatformData.transfer_sync_start_date || '';
//           break;
//         case "Shopify":
//           initialSettings.showOrderSync = !!retrivedPlatformData.is_order_sync_enabled;
//           initialSettings.showProductSync = !!retrivedPlatformData.is_product_sync_enables;
//           initialSettings.orderSyncDateTime = retrivedPlatformData.sync_start_date;
//           break;
//         default:
//           console.warn('Unknown platform name:', platformName);
//           break;
//       }
//       setSettings(initialSettings);
//       setIsReadonly(false);
//     }
//   }, [retrivedPlatformData]);

//   const fetchCurrentSettings = async (platformId: number) => {
//     try {
//       const action = await dispatch(fetchConnectedPlatformSettingAsync({ platform_id: platformId }));
//       const data = action.payload;
//       const hasDateTime = !!(data.purchase_order_date_time || data.transfer_order_date_time);
//       setIsReadonly(hasDateTime);
//       setSettings(prevSettings => ({
//         ...prevSettings,
//         purchaseOrderDateTime: data.purchase_order_date_time || '',
//         transferOrderDateTime: data.transfer_order_date_time || ''
//       }));
//     } catch (error) {
//       console.error('Error fetching current settings:', error);
//     }
//   };

//   const createPayload = (): any => {
//     const platformName = retrivedPlatformData?.availablePlatform?.name;
//     return {
//       platformId: selectedPlatform.id,
//       availablePlatform: retrivedPlatformData?.availablePlatform,
//       ...(platformName === "VEEQO" ? {
//         order_setting: {
//           isOrderSyncEnabled: settings.showOrderSync
//         },
//         product_setting: {
//           isProductSyncEnabled: settings.showProductSync
//         }
//       } : platformName === "ShipHero" ? {
//         purchase_order_setting: {
//           isPurchaseOrderSyncEnabled: settings.showPurchaseOrder,
//           purchaseOrderDateTime: settings.purchaseOrderDateTime
//         },
//         inventory_setting: {
//           isInventorySyncEnabled: settings.showInventory
//         },
//         transfer_order_setting: {
//           isTransferOrderSyncEnabled: settings.showTransferOrder,
//           transferOrderDateTime: settings.transferOrderDateTime
//         }
//       } : platformName === "Shopify" ? {
//         order_setting: {
//           isOrderSyncEnabled: settings.showOrderSync,
//           orderSyncDateTime: settings.orderSyncDateTime
//         },
//         product_setting: {
//           isProductSyncEnabled: settings.showProductSync
//         }
//       } : {})
//     };
//   };

//   const validateSettings = () => {
//     const newErrors: { [key: string]: string } = {};
//     const platformName = retrivedPlatformData?.availablePlatform?.name;

//     if (platformName === "ShipHero") {
//       if (settings.showPurchaseOrder && !settings.purchaseOrderDateTime) {
//         newErrors.purchaseOrderDateTime = 'Please select a purchase order date/time.';
//       }
//       if (settings.showTransferOrder && !settings.transferOrderDateTime) {
//         newErrors.transferOrderDateTime = 'Please select a transfer order date/time.';
//       }
//     }

//     if (platformName === "Shopify") {
//       if (settings.showOrderSync && !settings.orderSyncDateTime) {
//         newErrors.orderSyncDateTime = 'Please select the Order Sync date/time.';
//       }
//     }

//     return newErrors;
//   };

//   const handleSaveAllSettings = async () => {
//     const validationErrors = validateSettings();
//     if (Object.keys(validationErrors).length > 0) {
//       Object.values(validationErrors).forEach(error => toast.error(error));
//       setErrors(validationErrors);
//       return;
//     }
  
//     try {
//       setIsSaving(true);
//       await onSaveSettings(createPayload());
//       // toast.success('Settings saved successfully!');
//       setIsReadonly(true);
//     } catch (error) {
//       console.error('Error saving settings:', error);
//       toast.error('Error saving settings.');
//     } finally {
//       setIsSaving(false);
//     }
//   };

//   const handleCloseModal = () => {
//     closeModal();
//     resetModalState();
//   };

//   const resetModalState = () => {
//     setSettings({});
//     setIsReadonly(true);
//     setErrors({});
//     setIsSaving(false);
//   };

//   const handleDateChange = (field: string, date: Date | null) => {
//     if (date) {
//       const utcDate = new Date(date.toISOString());
//       setSettings(prevSettings => ({
//         ...prevSettings,
//         [field]: utcDate.toISOString()
//       }));
//     } else {
//       setSettings(prevSettings => ({
//         ...prevSettings,
//         [field]: ''
//       }));
//     }
//   };

//   const platformName = retrivedPlatformData?.availablePlatform?.name;
//   const PlatformComponent = platformName ? platformComponents[platformName] : null;

//   return (
//     <>
//       <Modal fullscreen show={isVisible} onHide={handleCloseModal}>
//         <Modal.Header>
//           <Modal.Title>Platform Setting</Modal.Title>
//           <div className='d-inline-flex'>
//             <Button size='sm' className='mx-2' onClick={handleCloseModal}>
//               Close
//             </Button>
//             <Button
//               size='sm'
//               variant='success'
//               onClick={handleSaveAllSettings}
//               disabled={isLoading || isSaving} // Disable button if loading or saving
//             >
//               {isSaving ? 'Saving...' : 'Save Changes'}
//             </Button>
//           </div>
//         </Modal.Header>
//         <Modal.Body>
//           <div className='card m-2 p-4 shadow'>
//             <div>
//               <p className='h3'>Platform Connection</p>
//             </div>
//             <div className='border-bottom my-2 border-2'></div>
//             <div className='p-4'>
//               <div className='my-2 d-flex flex-row align-items-center'>
//                 <p className='h4 mb-0'>Name</p>
//                 <span className='badge badge-info ms-4'>
//                   <h4 className='mb-0 text-white'>{retrivedPlatformData?.platform.display_name || 'Unknown'}</h4>
//                 </span>
//               </div>
//               <div className='my-2 d-flex flex-row align-items-center'>
//                 <p className='h4 mb-0'>Status</p>
//                 <span className={`badge ms-4 ${retrivedPlatformData?.platform.isConnected ? 'badge-success' : 'badge-danger'}`}>
//                   {retrivedPlatformData?.platform.isConnected ? 'Connected' : 'Not Connected'}
//                 </span>
//               </div>
//             </div>
//           </div>

//           {PlatformComponent ? (
//             <PlatformComponent
//               settings={settings}
//               setSettings={(newSettings: any) => setSettings(prev => ({ ...prev, ...newSettings }))}
//               platformName={platformName}
//               isReadonly={isReadonly}
//               onDateChange={handleDateChange}
//             />
//           ) : (
//             <p>No settings available for this platform.</p>
//           )}
//         </Modal.Body>
//       </Modal>
//       <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
//     </>
//   );
// };

// export default ConnectedPlatformSettingModal;




import Loader from 'src/utils/Loader'; // Import the Loader component
import ShipHeroSettings from './ShipHeroSettings';
import VeeqoSettings from './VeeqoSettings';
import ShopifySettings from './ShopifySettings';
import DatePicker from 'react-datepicker'; // Ensure you have installed react-datepicker
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from 'react-toastify';
import { fetchConnectedPlatformSettingAsync, retrivePlatformDataAsync } from 'src/redux/actions/platformActions';
import { FC, useEffect, useState } from 'react';
import { PlatformDataType } from 'src/interfaces/Platform.interface';
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks';
import { Button, Modal } from 'react-bootstrap';
import LoaderTransparent from 'src/utils/LoaderTransparent';


type PlatformComponents = {
  [key: string]: FC<any>;
};

// Map platform names to components
const platformComponents: PlatformComponents = {
  "ShipHero": ShipHeroSettings,
  "VEEQO": VeeqoSettings,
  "Shopify": ShopifySettings,
};

type ConnectedPlatformSettingModalPropsType = {
  isVisible: boolean;
  closeModal: () => void;
  onSaveSettings: (settings: any) => Promise<void>;
  selectedPlatform: PlatformDataType;
};

const ConnectedPlatformSettingModal: FC<ConnectedPlatformSettingModalPropsType> = ({
  isVisible,
  closeModal,
  selectedPlatform,
  onSaveSettings,
}) => {
  const [settings, setSettings] = useState<any>({});
  const [isReadonly, setIsReadonly] = useState<boolean>(true);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true); // Initialize as true for loading state
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true); // Track first load
  const dispatch = useAppDispatch();
  const { retrivedPlatformData } = useAppSelector(state => state.retrivePlatformData);

  useEffect(() => {
    if (isVisible && selectedPlatform?.id) {
      setIsLoading(true);
      // setIsFirstLoad(); // Reset to true whenever a new fetch is initiated
      dispatch(retrivePlatformDataAsync({ id: selectedPlatform.id }))
        .finally(() => {
          setIsLoading(false);
          setIsFirstLoad(false); // Set to false once loading is done
        });
    }
  }, [isVisible, selectedPlatform, dispatch]);

  useEffect(() => {
    if (retrivedPlatformData) {
      const platformName = retrivedPlatformData.availablePlatform?.name;
      const initialSettings: any = {};
      switch (platformName) {
        case "VEEQO":
          initialSettings.showOrderSync = !!retrivedPlatformData.is_order_sync_enabled;
          initialSettings.showProductSync = !!retrivedPlatformData.is_product_sync_enables;
          break;
        case "ShipHero":
          initialSettings.showPurchaseOrder = !!retrivedPlatformData.is_purchase_order_sync_enabled;
          initialSettings.showInventory = !!retrivedPlatformData.is_inventory_sync_enabled;
          initialSettings.showTransferOrder = !!retrivedPlatformData.is_transfer_order_sync_enabled;
          initialSettings.purchaseOrderDateTime = retrivedPlatformData.purchase_order_sync_startdate;
          initialSettings.transferOrderDateTime = retrivedPlatformData.transfer_sync_start_date || '';
          break;
        case "Shopify":
          initialSettings.showOrderSync = !!retrivedPlatformData.is_order_sync_enabled;
          initialSettings.showProductSync = !!retrivedPlatformData.is_product_sync_enables;
          initialSettings.orderSyncDateTime = retrivedPlatformData.sync_start_date;
          break;
        default:
          console.warn('Unknown platform name:', platformName);
          break;
      }
      setSettings(initialSettings);
      setIsReadonly(false);
    }
  }, [retrivedPlatformData]);

  const fetchCurrentSettings = async (platformId: number) => {
    try {
      const action = await dispatch(fetchConnectedPlatformSettingAsync({ platform_id: platformId }));
      const data = action.payload;
      const hasDateTime = !!(data.purchase_order_date_time || data.transfer_order_date_time);
      setIsReadonly(hasDateTime);
      setSettings(prevSettings => ({
        ...prevSettings,
        purchaseOrderDateTime: data.purchase_order_date_time || '',
        transferOrderDateTime: data.transfer_order_date_time || ''
      }));
    } catch (error) {
      console.error('Error fetching current settings:', error);
    }
  };

  const createPayload = (): any => {
    const platformName = retrivedPlatformData?.availablePlatform?.name;
    return {
      platformId: selectedPlatform.id,
      availablePlatform: retrivedPlatformData?.availablePlatform,
      ...(platformName === "VEEQO" ? {
        order_setting: {
          isOrderSyncEnabled: settings.showOrderSync
        },
        product_setting: {
          isProductSyncEnabled: settings.showProductSync
        }
      } : platformName === "ShipHero" ? {
        purchase_order_setting: {
          isPurchaseOrderSyncEnabled: settings.showPurchaseOrder,
          purchaseOrderDateTime: settings.purchaseOrderDateTime
        },
        inventory_setting: {
          isInventorySyncEnabled: settings.showInventory
        },
        transfer_order_setting: {
          isTransferOrderSyncEnabled: settings.showTransferOrder,
          transferOrderDateTime: settings.transferOrderDateTime
        }
      } : platformName === "Shopify" ? {
        order_setting: {
          isOrderSyncEnabled: settings.showOrderSync,
          orderSyncDateTime: settings.orderSyncDateTime
        },
        product_setting: {
          isProductSyncEnabled: settings.showProductSync
        }
      } : {})
    };
  };

  const validateSettings = () => {
    const newErrors: { [key: string]: string } = {};
    const platformName = retrivedPlatformData?.availablePlatform?.name;

    if (platformName === "ShipHero") {
      if (settings.showPurchaseOrder && !settings.purchaseOrderDateTime) {
        newErrors.purchaseOrderDateTime = 'Please select a purchase order date/time.';
      }
      if (settings.showTransferOrder && !settings.transferOrderDateTime) {
        newErrors.transferOrderDateTime = 'Please select a transfer order date/time.';
      }
    }

    if (platformName === "Shopify") {
      if (settings.showOrderSync && !settings.orderSyncDateTime) {
        newErrors.orderSyncDateTime = 'Please select the Order Sync date/time.';
      }
    }

    return newErrors;
  };

  const handleSaveAllSettings = async () => {
    const validationErrors = validateSettings();
    if (Object.keys(validationErrors).length > 0) {
      Object.values(validationErrors).forEach(error => toast.error(error));
      setErrors(validationErrors);
      return;
    }
  
    try {
      setIsSaving(true);
      await onSaveSettings(createPayload());
      // toast.success('Settings saved successfully!');
      setIsReadonly(true);
    } catch (error) {
      console.error('Error saving settings:', error);
      toast.error('Error saving settings.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleCloseModal = () => {
    closeModal();
    resetModalState();
  };

  const resetModalState = () => {
    setSettings({});
    setIsReadonly(true);
    setErrors({});
    setIsSaving(false);
  };

  const handleDateChange = (field: string, date: Date | null) => {
    if (date) {
      const utcDate = new Date(date.toISOString());
      setSettings(prevSettings => ({
        ...prevSettings,
        [field]: utcDate.toISOString()
      }));
    } else {
      setSettings(prevSettings => ({
        ...prevSettings,
        [field]: ''
      }));
    }
  };

  const platformName = retrivedPlatformData?.availablePlatform?.name;
  const PlatformComponent = platformName ? platformComponents[platformName] : null;

  console.log("checking loader",isLoading,isFirstLoad)
  return (
    <>
      <Modal fullscreen show={isVisible} onHide={handleCloseModal}>
        
        {isLoading && isFirstLoad ? (
          <Loader />
        ) : (
          <>
            {isLoading && !isFirstLoad  ? <LoaderTransparent /> : null}
            <Modal.Header>
              <Modal.Title>Platform Setting</Modal.Title>
              <div className='d-inline-flex'>
                <Button size='sm' className='mx-2' onClick={handleCloseModal}>
                  Close
                </Button>
                <Button
                  size='sm'
                  variant='success'
                  onClick={handleSaveAllSettings}
                  disabled={isLoading || isSaving} // Disable button if loading or saving
                >
                  {isSaving ? 'Saving...' : 'Save Changes'}
                </Button>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className='card m-2 p-4 shadow'>
                <div>
                  <p className='h3'>Platform Connection</p>
                </div>
                <div className='border-bottom my-2 border-2'></div>
                <div className='p-4'>
                  <div className='my-2 d-flex flex-row align-items-center'>
                    <p className='h4 mb-0'>Name</p>
                    <span className='badge badge-info ms-4'>
                      <h4 className='mb-0 text-white'>{retrivedPlatformData?.platform.display_name || 'Unknown'}</h4>
                    </span>
                  </div>
                  <div className='my-2 d-flex flex-row align-items-center'>
                    <p className='h4 mb-0'>Status</p>
                    <span className={`badge ms-4 ${retrivedPlatformData?.platform.isConnected ? 'badge-success' : 'badge-danger'}`}>
                      {retrivedPlatformData?.platform.isConnected ? 'Connected' : 'Not Connected'}
                    </span>
                  </div>
                </div>
              </div>

              {PlatformComponent ? (
                <PlatformComponent
                  settings={settings}
                  setSettings={(newSettings: any) => setSettings(prev => ({ ...prev, ...newSettings }))}
                  platformName={platformName}
                  isReadonly={isReadonly}
                  onDateChange={handleDateChange}
                />
              ) : (
                <p>No settings available for this platform.</p>
              )}
            </Modal.Body>
          </>
        )}
      </Modal>

      
      {/* <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover /> */}
    
    </>
  );
};

export default ConnectedPlatformSettingModal;