import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { RetrievePlatformSyncSetting } from "src/interfaces/Platform.interface"
import { retrivePlatformDataAsync } from "../actions/platformActions"

type retrivePlatformPayload={
    retrivedPlatformData: RetrievePlatformSyncSetting | null
    retrivedPlatformLoading: boolean
}

const initialState: retrivePlatformPayload ={
    retrivedPlatformData:null,
    retrivedPlatformLoading:false,
}

const retrivePlatformDataSlice =createSlice({
    name: 'retrivedPlatformDataSlice',
    initialState,
    extraReducers: (builder) => {
      builder.addMatcher(isAnyOf(retrivePlatformDataAsync.pending), (state, action) => {
        state.retrivedPlatformLoading = true
      })
      builder.addMatcher(isAnyOf(retrivePlatformDataAsync.fulfilled), (state, action) => {
        state.retrivedPlatformLoading = false
        state.retrivedPlatformData = action?.payload
        console.log("platform setting =>", action?.payload)
      })
      builder.addMatcher(isAnyOf(retrivePlatformDataAsync.rejected), (state) => {
        state.retrivedPlatformLoading = true
      })
    },
    reducers: {
        setRetrivedPlatformData: () => initialState,
    },

});

export const {setRetrivedPlatformData} = retrivePlatformDataSlice.actions
export default retrivePlatformDataSlice.reducer;



