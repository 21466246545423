import { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks';
import useAppToast from 'src/hooks/useAppToast';
import { jwtDecode } from 'jwt-decode';
import { createConnectionWithAIModelAsync } from 'src/redux/actions/gptModelsActions';

type AddNewAgentPropsType = {
    isVisible: boolean;
    closeModal: () => void;
    onAgentAdded: () => Promise<void>;
};

interface CustomJwtPayload {
    user_id?: number
}

const initialValues = {
    model_name: '',
    token: '',
};

const creationSchemaAgent = Yup.object().shape({
    model_name: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('Username is required'),
    token: Yup.string()
        .min(8, 'Minimum 8 characters')
        .max(128, 'Maximum 128 characters')
        .required('Password is required'),
});

const AddNewAgent: FC<AddNewAgentPropsType> = ({ isVisible, closeModal, onAgentAdded }) => {
    const [loading, setLoading] = useState(false);
    const { successToast, errorToast } = useAppToast();
    const dispatch = useAppDispatch();

    const token = sessionStorage.getItem('access_token') || '';
    const decoded_token = jwtDecode<CustomJwtPayload>(token);
    const decoded_user_id = decoded_token?.user_id

    const { } = useAppSelector((state) => state)

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchemaAgent,
        onSubmit: async (values, { setSubmitting }) => {
            setLoading(true);
            try {
                dispatch(
                    createConnectionWithAIModelAsync({
                        model_name: values.model_name,
                        token: values.token,
                    })
                ).unwrap()
                    .then((response) => {
                        if (response && response.id) {
                            successToast("Agent added successfully");
                            closeModal();
                            onAgentAdded();
                        } else {
                            errorToast("Something went wrong");
                        }
                    });
            } catch (error) {
                setLoading(false)

            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    })

    return (
        <Modal size={'lg'} centered show={isVisible} onHide={closeModal}>
            <form onSubmit={formik.handleSubmit}>

                <Modal.Header>
                    <Modal.Title>Add New Agent</Modal.Title>
                    <div className='d-inline-flex'>
                        <Button size='sm' className='mx-2' onClick={closeModal}>
                            Close
                        </Button>
                        <Button size='sm' variant='success' type='submit'>
                            Save Changes
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='mb-8'>
                        <label className='form-label'>Agent Name</label>
                        <input
                            type='text'
                            placeholder='Enter Agent Name'
                            autoComplete='off'
                            {...formik.getFieldProps('model_name')}
                            className={clsx('form-control', 'bg-transparent', {
                                'is-invalid': formik.touched.model_name && formik.errors.model_name,
                                'is-valid': formik.touched.model_name && !formik.errors.model_name,
                            })}
                        />
                        {formik.touched.model_name && formik.errors.model_name && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.model_name}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='mb-8'>
                        <label className='form-label'>Password</label>
                        <input
                            type='password'
                            placeholder='Enter token'
                            autoComplete='off'
                            {...formik.getFieldProps('token')}
                            className={clsx('form-control', 'bg-transparent', {
                                'is-invalid': formik.touched.token && formik.errors.token,
                                'is-valid': formik.touched.token && !formik.errors.token,
                            })}
                        />
                        {formik.touched.token && formik.errors.token && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.token}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </form>
        </Modal>
    );
};

export default AddNewAgent;
