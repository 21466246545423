import React, { FC } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

type PlatformDataOptionModalPropsType = {
    isVisible: boolean;
    closeModal: () => void;
    onOptionSelected: (choice: string) => void;
};

const PlatformDataOption: FC<PlatformDataOptionModalPropsType> = ({
    isVisible,
    closeModal,
    onOptionSelected,
}) => {
    const handleSelection = (choice: string) => {
        onOptionSelected(choice); // 'product' or 'order'
        closeModal(); // Close modal after selection
    };

    return (
        <Modal show={isVisible} onHide={closeModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>Choose Database Option</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div key={`default-radio`} className="mb-3 form-check">
                        <Form.Check
                            className="mb-3 "
                            type="radio"
                            id={`product-data`}
                            label={`Product Data`}
                            name="databaseOption"
                            onChange={() => handleSelection('Product')}
                        />
                        <Form.Check
                            type="radio"
                            id={`order-data`}
                            label={`Order Data`}
                            name="databaseOption"
                            onChange={() => handleSelection('Order')}
                        />
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PlatformDataOption;