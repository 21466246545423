import React, { FC } from 'react'
import { ConnectedDatabase } from 'src/interfaces/Platform.interface'
import { KTSVG } from 'src/marketConnect/helpers'

type ConnectDatabaseformStrip = {
    database: ConnectedDatabase
    onDelete: () => void
    // openSettingsModal: () => void
}

const ConnectDatabaseStrip: FC<ConnectDatabaseformStrip> = ({ database, onDelete}) => {
    return (
        <div className='card shadow p-2 my-2' >
            <div className='d-flex flex-md-row justify-content-between p-5 border border-light-primary rounded' >
                <div className='align-self-center' >
                    <h4 className='title mb-0' >{database.db_name}</h4>
                </div>
                <div className='d-md-inline-flex ' >
                    {/* <span className="badge badge-light-success py-0 ">Connected</span> */}
                    <div>
                        {/* <button className='btn p-2' onClick={openSettingsModal} >
                            <KTSVG path="media/icons/duotune/coding/cod009.svg" className="svg-icon-muted svg-icon-2hx" />
                        </button> */}
                        <button className='btn p-2 ' onClick={onDelete} >
                            {/* <KTSVG path="media/icons/duotune/general/gen034.svg" className="svg-icon-muted svg-icon-2hx" /> */}
                            <KTSVG path="" className="bi bi-x p-2 fs-3x text-dark" />
                        </button>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default ConnectDatabaseStrip