import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
    createDatabaseFormAsync,
    fetchUserDbAsync,
    deleteDatabaseAsync,
  } from 'src/redux/actions/platformActions'; // Ensure these are your actual actions

// Use the provided ConnectedDatabase type
export type ConnectedDatabase = {
  id: number;
  db_host: string;
  db_user: string;
  db_password: string;
  db_name: string;
  db_port: string;
  created_at: string;
  updated_at: string;
  user: any; // Consider defining a more specific type for 'user' if possible
};

type DatabaseState = {
    databases: ConnectedDatabase[] | null;
    loading: boolean;
    error: string | null;
};

const initialState: DatabaseState = {
    databases: null,
    loading: false,
    error: null,
};

const sliceDatabase = createSlice({
    name: 'sliceDatabase',
    initialState,
    reducers: {
        resetDatabaseState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
        .addMatcher(isAnyOf(fetchUserDbAsync.pending), (state) => {
            state.loading = true;
            state.error = null;
        })
        .addMatcher(isAnyOf(fetchUserDbAsync.fulfilled), (state, action) => {
            console.log('Fetched databases:', action.payload); // Debug log
            state.loading = false;
            state.databases = Array.isArray(action.payload) ? action.payload : [];
          })
          
        .addMatcher(isAnyOf(fetchUserDbAsync.rejected), (state, action) => {
            state.loading = false;
            state.error = action.error?.message || 'Failed to fetch databases';
        })

        .addMatcher(isAnyOf(createDatabaseFormAsync.pending), (state, action) => {
            // state.loading = false;
            // state.error = action.error?.message || 'Failed to fetch databases';
        })
        .addMatcher(isAnyOf(createDatabaseFormAsync.rejected), (state, action) => {
            // state.loading = false;
            // state.error = action.error?.message || 'Failed to fetch databases';
        })
        .addMatcher(isAnyOf(createDatabaseFormAsync.fulfilled), (state, action) => {
            // Ensure state.databases is an array before trying to spread it
            const newDatabase: ConnectedDatabase = action.payload;
            state.databases = state.databases ? [...state.databases, newDatabase] : [newDatabase];
        })
        .addMatcher(isAnyOf(deleteDatabaseAsync.fulfilled), (state, action) => {
            // Ensure state.databases is an array before filtering
            const databaseIdToDelete: number = action.meta.arg.id;
            state.databases = state.databases ? state.databases.filter(db => db.id !== databaseIdToDelete) : [];
        });
    },
});

export const { resetDatabaseState } = sliceDatabase.actions;
export default sliceDatabase.reducer;
