// // import React, { FC } from 'react';
// // import { UserAgentSetting } from 'src/interfaces/Platform.interface';
// // import { KTSVG } from 'src/marketConnect/helpers';

// // type ConnectAgentStripProps = {
// //     agent: UserAgentSetting;
// //     onDelete: () => void;
// //     // openSettingsModal: () => void;
// // }

// // const ConnectAgentStrip: FC<ConnectAgentStripProps> = ({ agent, onDelete}) => {
// //     return (
// //         <div className='card shadow p-2 my-2'>
// //             <div className='d-flex flex-md-row justify-content-between p-5 border border-light-primary rounded'>
// //                 <div className='align-self-center'>
// //                     <h4 className='title mb-0'>{agent.model_name}</h4> {/* Assuming the agent has a 'name' field */}
// //                 </div>
// //                 <div className='d-md-inline-flex'>
// //                     {/* Uncomment or modify based on actual functionality */}
// //                     {/* <button className='btn p-2' onClick={openSettingsModal}>
// //                         <KTSVG path="media/icons/duotune/coding/cod009.svg" className="svg-icon-muted svg-icon-2hx" />
// //                     </button> */}
// //                     <button className='btn p-2' onClick={onDelete}>
// //                         <KTSVG path="media/icons/duotune/general/gen034.svg" className="svg-icon-muted svg-icon-2hx" />
// //                     </button>
// //                 </div>
// //             </div>
// //         </div>
// //     );
// // };

// // export default ConnectAgentStrip;

// import React, { FC } from 'react';
// import { UserAgentSetting } from 'src/interfaces/Platform.interface';
// import { KTSVG } from 'src/marketConnect/helpers';

// type ConnectAgentTableProps = {
//     agent: UserAgentSetting;
//     onDelete: () => void;
//     onEdit: () => void;
//     // openSettingsModal: () => void;
// }

// const maskToken = (token: string): string => {
//     if (token.length <= 5) {
//         return '*'.repeat(token.length);
//     }
//     return token.slice(0, 5) + '*'.repeat(token.length - 5);
// };

// const ConnectAgentTable: FC<ConnectAgentTableProps> = ({ agent, onDelete, onEdit }) => {
//     return (
//         <div className='table-responsive'>
//             <table className='table table-bordered table-hover'>
//                 <thead className='thead-light'>
//                     <tr>
//                         {/* <th>Model Name</th> */}
//                         <th>Friendly Name</th>
//                         <th>Api key</th>
//                         <th>Actions</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     <tr>
//                         {/* <td>{agent.model_name}</td> */}
//                         <td>{agent.friendly_name}</td>
//                         <td>{maskToken(agent.token)}</td>
//                         <td>
//                             {/* Uncomment or modify based on actual functionality */}
//                             {/* <button className='btn p-2' onClick={openSettingsModal}>
//                                 <KTSVG path="media/icons/duotune/coding/cod009.svg" className="svg-icon-muted svg-icon-2hx" />
//                             </button> */}
//                              <button className='btn p-2' onClick={onEdit}>
//                                 <KTSVG path="media/icons/duotune/general/gen055.svg" className="svg-icon-muted svg-icon-2hx" />
//                             </button>
//                             <button className='btn p-2' onClick={onDelete}>
//                                 <KTSVG path="media/icons/duotune/general/gen034.svg" className="svg-icon-muted svg-icon-2hx" />
//                             </button>
//                         </td>
//                     </tr>
//                 </tbody>
//             </table>
//         </div>
//     );
// };

// export default ConnectAgentTable;



import React, { FC, useState } from 'react';
import { UserAgentSetting } from 'src/interfaces/Platform.interface';
import { KTSVG } from 'src/marketConnect/helpers';
import { Button, Modal } from 'react-bootstrap';

type ConnectAgentTableProps = {
    agent: UserAgentSetting;
    onDelete: () => void;
    onEdit: () => void;
};

const maskToken = (token: string): string => {
    if (token.length <= 5) {
        return '*'.repeat(token.length);
    }
    return token.slice(0, 5) + '*'.repeat(token.length - 5);
};

const ConnectAgentTable: FC<ConnectAgentTableProps> = ({ agent, onDelete, onEdit }) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleDeleteClick = () => {
        setShowConfirmModal(true);
    };

    const handleConfirmDelete = () => {
        setShowConfirmModal(false);
        onDelete();
    };

    const handleCancelDelete = () => {
        setShowConfirmModal(false);
    };

    return (
        <>
            <div className='table-responsive'>
                <table className='table table-bordered table-hover'>
                    <thead className='thead-light'>
                        <tr>
                            <th>Friendly Name</th>
                            <th>Api key</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{agent.friendly_name}</td>
                            <td>{maskToken(agent.token)}</td>
                            <td>
                                <button className='btn p-2' onClick={onEdit}>
                                    <KTSVG path="media/icons/duotune/general/gen055.svg" className="svg-icon-muted svg-icon-2hx" />
                                   
                                </button>
                                <button className='btn p-2' onClick={handleDeleteClick}>
                                    <KTSVG path="media/icons/duotune/general/gen034.svg" className="svg-icon-muted svg-icon-2hx" />
                                    {/* <KTSVG path="" className="bi bi-x p-2 fs-2 text-dark" /> */}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <Modal show={showConfirmModal} onHide={handleCancelDelete} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Before deleting the OpenAI key, please delete all the instructions associated with it. Are you sure you want to delete this agent?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancelDelete}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ConnectAgentTable;

