import { useEffect, useState } from 'react'
import AlertModal from 'src/utils/modals/AlertModal'
import ConnectPlatformStrip from './components/ConnectPlatformStrip'
import ConnectedPlatformSettingModal from './components/ConnectedPlatformSettingModal'
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'
import {
  createPlatformSettingAsync,
  fetchConnectedPlatformAsync,
  retrivePlatformDataAsync,
  deletePlatformAsync,
  getPlatformProductData,
  getPlatformOrderData
} from 'src/redux/actions/platformActions'
import { PlatformSettingsDataType } from 'src/interfaces/Platform.interface'
import { toast } from 'react-toastify'
import { jwtDecode } from 'jwt-decode'
import PlatformOrderDataTable from './components/PlatformOrderDataTable';
import AvailablePlatforms from './components/AvailablePlatforms'
import PlatformDataOption from './components/PlatformDataOption'
import Loader from 'src/utils/Loader'

type AddNewPlatformPropsType = {
  isVisible: boolean
  closeModal: () => void
  onPlatformAdded: () => void;

}

const ConnectedPlatforms = () => {
  interface CustomJwtPayload {
    user_id?: number;
  }
  // const [showAddPlatformModal, setShowAddPlatformModal] = useState<boolean>(false)

  const [showAvailablePlatfom, setShowAvailablePlatfom] = useState<boolean>(false)


  const [showAlertModal, setShowAlertModal] = useState<boolean>(false)
  const [showSettingModal, setShowSettingModal] = useState<boolean>(false)
  const [selectedPlatform, setSelectedPlatform] = useState<any>(null)
  const [selectedPlatformId, setSelectedPlatformId] = useState(null);
  const { fetchedConnectedPlatformLoading, fetchedConnectedPlatformData } = useAppSelector(
    (state) => state.fetchConnectedPlatform
  )
  const [platformSettingData, setPlatformSettingData] = useState<any>([])
  const token = sessionStorage.getItem('access_token') || '';
  const decoded_token = jwtDecode<CustomJwtPayload>(token);
  const [deleting, setDeleting] = useState(false)
  const user_id = decoded_token?.user_id

  const { retrivedPlatformData } = useAppSelector((state) => state.retrivePlatformData)
  
  const [showPlatformDataOptionModal, setshowPlatformDataOptionModal] = useState(false);
  const dispatch = useAppDispatch()

  const onDeleteStripe = async (platform: any) => {
    setShowAlertModal(true)
    setSelectedPlatformId(platform.id);
    setSelectedPlatform(platform)
  }


  const onClickAddNewPlatform = () => {
    setShowAvailablePlatfom(true)
  }


  const deletePlatform = async (platformId: any) => {
    try {
      setDeleting(true)
      dispatch(deletePlatformAsync({ id: platformId }))
      .then((response)=>{
        if(response.payload?.status ==='success'){
          setDeleting(false)
          toast.success(response?.payload?.message)
          setShowAlertModal(false); 
          handlePlatformAdded()
        }else{
          toast.warning("Unable to delete yet")
          setDeleting(false)
          handlePlatformAdded()
        }
      })
    } catch (error) {
      console.error('Error deleting platform:', error);
      setDeleting(false)
      toast.error('Failed to delete platform.');
    } 
  };



  const onClickSetting = (platform: any) => {
    setSelectedPlatform(platform)
    setShowSettingModal(true)
  }
  const handlePlatformAdded = () => {
    if (typeof user_id !== 'undefined') {
      dispatch(fetchConnectedPlatformAsync({ user_id }));
    } else {
    }
  };

  useEffect(() => {
    handlePlatformAdded()
  }, [user_id, dispatch]);


  const showSuccessToast = () => {
    toast.success('Platform settings saved successfully!', {
      autoClose: 3000,
    })
  }

  const { orders, loading, error } = useAppSelector((state) => state.platformOrderData);
  const [showPlatformOrderTable, setShowPlatformOrderTable] = useState<boolean>(false)
  
  const onclickShowOrderTable = async (platformId: any) =>{
    console.log('Opening order Table.');
    setShowPlatformOrderTable(true);
    setSelectedPlatformId(platformId);
  }

  const onclickShowDataOptions = async (platformId: any) =>{
    console.log('Opening Platform Data Option Modal...');
    setshowPlatformDataOptionModal(true);
    setSelectedPlatformId(platformId);
  }



  const handleDatabaseOptionSelected = (choice) => {
    if (!selectedPlatformId) {
      console.error('No platform ID selected');
      return;
    }
  
    if (choice === 'Order') { 
      console.log('Fetching order data for platform ID:', selectedPlatformId);
      dispatch(getPlatformOrderData({ selectedPlatformId: selectedPlatformId,params:'' }));
    } else if (choice === 'Product') { 
      console.log('Fetching product data for platform ID:', selectedPlatformId);
      dispatch(getPlatformProductData({ selectedPlatformId: selectedPlatformId }));
      
    } else {
      console.error('Invalid choice');
    }
  };

  const handleSaveSettings = async (allSettings: PlatformSettingsDataType) => {
    setPlatformSettingData(allSettings)

    try {
      await dispatch(createPlatformSettingAsync(allSettings))
      showSuccessToast() 
    } catch (error) {
      console.error('Error creating platform settings:', error)
    }
  }

  if(fetchedConnectedPlatformLoading) return <Loader/>

  return (
    <>

      <div id='test1' className='p-4 pt-0 rounded'>
        <div className='d-flex justify-content-end'>
          <button
            className='btn btn-light-info'
            onClick={onClickAddNewPlatform}
          >
            Add New Platform
          </button>
        </div>


      </div>
      <div className='py-2 pt-0 row'>
        {fetchedConnectedPlatformData?.length > 0 &&
          fetchedConnectedPlatformData?.map((platform: any) => {
            return (
              <ConnectPlatformStrip
                key={platform.id}
                platform={platform}
                onDelete={() => onDeleteStripe(platform)}
                openSettingsModal={() => onClickSetting(platform)}
                // openPlatformdata={onclickShowDataOptions}
                openPlatformdata={() => onclickShowDataOptions(platform.id)}
              />
            )
          })}
      </div>
      {/* {orders && orders.length >= 0 && <PlatformOrderDataTable 
      isVisible={showPlatformOrderTable} 
      closeModal={() => setShowPlatformOrderTable(false)}
      orders={orders} />} */}


      <AlertModal
        title={`Are you sure?`}
        subtitle={ <p> You are going to delete <b>{selectedPlatform?.display_name}</b> you won't be able to revert this! </p> }
        isVisible={showAlertModal}
        closeModal={() => {setShowAlertModal(false); setSelectedPlatform(null) }}
        onConfirm={() => deletePlatform(selectedPlatformId)}
        loading={deleting}
      />

      <ConnectedPlatformSettingModal
        key={'setting modal'}
        isVisible={showSettingModal}
        closeModal={() => setShowSettingModal(false)}
        onSaveSettings={handleSaveSettings}
        selectedPlatform={selectedPlatform}
      />
      

      <AvailablePlatforms
        isVisible={showAvailablePlatfom} 
        closeModal={() => setShowAvailablePlatfom(false)}
        onPlatformAdded={handlePlatformAdded}
      />


      <PlatformDataOption
        isVisible={showPlatformDataOptionModal} 
        closeModal={() => setshowPlatformDataOptionModal(false)}
        onOptionSelected={handleDatabaseOptionSelected}
      />

      {/* <ToastContainer /> */}
    </>
  )
}

export default ConnectedPlatforms






// import { useEffect, useState } from 'react'
// import AlertModal from 'src/utils/modals/AlertModal'
// import ConnectPlatformStrip from './components/ConnectPlatformStrip'
// import ConnectedPlatformSettingModal from './components/ConnectedPlatformSettingModal'
// import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'
// import {
//   createPlatformSettingAsync,
//   fetchConnectedPlatformAsync,
//   retrivePlatformDataAsync,
//   deletePlatformAsync,
//   getPlatformProductData,
//   getPlatformOrderData
// } from 'src/redux/actions/platformActions'
// import { PlatformSettingsDataType } from 'src/interfaces/Platform.interface'
// import { toast } from 'react-toastify'
// import { jwtDecode } from 'jwt-decode'
// import PlatformOrderDataTable from './components/PlatformOrderDataTable';
// import AvailablePlatforms from './components/AvailablePlatforms'
// import PlatformDataOption from './components/PlatformDataOption'
// import Loader from 'src/utils/Loader'

// type AddNewPlatformPropsType = {
//   isVisible: boolean
//   closeModal: () => void
//   onPlatformAdded: () => void;

// }

// const ConnectedPlatforms = () => {
//   interface CustomJwtPayload {
//     user_id?: number;
//   }
//   // const [showAddPlatformModal, setShowAddPlatformModal] = useState<boolean>(false)

//   const [showAvailablePlatfom, setShowAvailablePlatfom] = useState<boolean>(false)


//   const [showAlertModal, setShowAlertModal] = useState<boolean>(false)
//   const [showSettingModal, setShowSettingModal] = useState<boolean>(false)
//   const [selectedPlatform, setSelectedPlatform] = useState<any>(null)
//   const [selectedPlatformId, setSelectedPlatformId] = useState(null);
//   const { fetchedConnectedPlatformLoading, fetchedConnectedPlatformData } = useAppSelector(
//     (state) => state.fetchConnectedPlatform
//   )
//   const [platformSettingData, setPlatformSettingData] = useState<any>([])
//   const token = sessionStorage.getItem('access_token') || '';
//   const decoded_token = jwtDecode<CustomJwtPayload>(token);
//   const [deleting, setDeleting] = useState(false)
//   const user_id = decoded_token?.user_id

//   const { retrivedPlatformData } = useAppSelector((state) => state.retrivePlatformData)
  
//   const [showPlatformDataOptionModal, setshowPlatformDataOptionModal] = useState(false);
//   const dispatch = useAppDispatch()

//   const onDeleteStripe = async (platform: any) => {
//     setShowAlertModal(true)
//     setSelectedPlatformId(platform.id);
//     setSelectedPlatform(platform)
//   }


//   const onClickAddNewPlatform = () => {
//     setShowAvailablePlatfom(true)
//   }


//   const deletePlatform = async (platformId: any) => {
//     try {
//       setDeleting(true)
//       dispatch(deletePlatformAsync({ id: platformId }))
//       .then((response)=>{
//         if(response.payload?.status ==='success'){
//           setDeleting(false)
//           toast.success(response?.payload?.message)
//           setShowAlertModal(false); 
//           handlePlatformAdded()
//         }else{
//           toast.warning("Unable to delete yet")
//           setDeleting(false)
//           handlePlatformAdded()
//         }
//       })
//     } catch (error) {
//       console.error('Error deleting platform:', error);
//       setDeleting(false)
//       toast.error('Failed to delete platform.');
//     } 
//   };



//   const onClickSetting = (platform: any) => {
//     setSelectedPlatform(platform)
//     setShowSettingModal(true)
//   }
//   const handlePlatformAdded = () => {
//     if (typeof user_id !== 'undefined') {
//       dispatch(fetchConnectedPlatformAsync({ user_id }));
//     } else {
//     }
//   };

//   useEffect(() => {
//     handlePlatformAdded()
//   }, [user_id, dispatch]);


//   const showSuccessToast = () => {
//     toast.success('Platform settings saved successfully!', {
//       autoClose: 3000,
//     })
//   }

//   const { orders, loading, error } = useAppSelector((state) => state.platformOrderData);
//   const [showPlatformOrderTable, setShowPlatformOrderTable] = useState<boolean>(false)
  
//   const onclickShowOrderTable = async (platformId: any) =>{
//     console.log('Opening order Table.');
//     setShowPlatformOrderTable(true);
//     setSelectedPlatformId(platformId);
//   }

//   const onclickShowDataOptions = async (platformId: any) =>{
//     console.log('Opening Platform Data Option Modal...');
//     setshowPlatformDataOptionModal(true);
//     setSelectedPlatformId(platformId);
//   }



//   const handleDatabaseOptionSelected = (choice) => {
//     if (!selectedPlatformId) {
//       console.error('No platform ID selected');
//       return;
//     }
  
//     if (choice === 'Order') { 
//       console.log('Fetching order data for platform ID:', selectedPlatformId);
//       dispatch(getPlatformOrderData({ selectedPlatformId: selectedPlatformId,params:'' }));
//     } else if (choice === 'Product') { 
//       console.log('Fetching product data for platform ID:', selectedPlatformId);
//       dispatch(getPlatformProductData({ selectedPlatformId: selectedPlatformId }));
      
//     } else {
//       console.error('Invalid choice');
//     }
//   };

//   const handleSaveSettings = async (allSettings: PlatformSettingsDataType) => {
//     setPlatformSettingData(allSettings)

//     try {
//       await dispatch(createPlatformSettingAsync(allSettings))
//       showSuccessToast() 
//     } catch (error) {
//       console.error('Error creating platform settings:', error)
//     }
//   }

//   if(fetchedConnectedPlatformLoading) return <Loader/>

//   return (
//     <>

//       <div id='test1' className='p-4 pt-0 rounded'>
//         <div className='d-flex justify-content-end'>
//           <button
//             className='btn btn-light-info'
//             onClick={onClickAddNewPlatform}
//           >
//             Add New Platform
//           </button>
//         </div>


//       </div>
//       <div className='py-2 pt-0 row'>
//         {fetchedConnectedPlatformData?.length > 0 &&
//           fetchedConnectedPlatformData?.map((platform: any) => {
//             return (
//               <ConnectPlatformStrip
//                 key={platform.id}
//                 platform={platform}
//                 onDelete={() => onDeleteStripe(platform)}
//                 openSettingsModal={() => onClickSetting(platform)}
//                 // openPlatformdata={onclickShowDataOptions}
//                 openPlatformdata={() => onclickShowDataOptions(platform.id)}
//               />
//             )
//           })}
//       </div>
//       {/* {orders && orders.length >= 0 && <PlatformOrderDataTable 
//       isVisible={showPlatformOrderTable} 
//       closeModal={() => setShowPlatformOrderTable(false)}
//       orders={orders} />} */}
//       <AlertModal
//         title={`Are you sure?`}
//         subtitle={ <p> You are going to delete <b>{selectedPlatform?.display_name}</b> you won't be able to revert this! </p> }
//         isVisible={showAlertModal}
//         closeModal={() => {setShowAlertModal(false); setSelectedPlatform(null) }}
//         onConfirm={() => deletePlatform(selectedPlatformId)}
//         loading={deleting}
//       />
//       <ConnectedPlatformSettingModal
//         key={'setting modal'}
//         isVisible={showSettingModal}
//         closeModal={() => setShowSettingModal(false)}
//         onSaveSettings={handleSaveSettings}
//         selectedPlatform={selectedPlatform}
//       />
      
//       <AvailablePlatforms
//         isVisible={showAvailablePlatfom} 
//         closeModal={() => setShowAvailablePlatfom(false)}
//         onPlatformAdded={handlePlatformAdded}
//       />
//       <PlatformDataOption
//         isVisible={showPlatformDataOptionModal} 
//         closeModal={() => setshowPlatformDataOptionModal(false)}
//         onOptionSelected={handleDatabaseOptionSelected}
//       />
//       {/* <ToastContainer /> */}
//     </>
//   )
// }

// export default ConnectedPlatforms




















