import React, { useState, useEffect } from 'react';
import { Form, FormLabel, Dropdown, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'src/utils/Loader';
import { GenrateReportAsync, fetchConnectedPlatformAsync } from 'src/redux/actions/platformActions';
import { jwtDecode } from 'jwt-decode';

interface Option {
  value: string;
  label: string;
}

interface CustomJwtPayload {
  user_id: number;
}

const GenerateReport: React.FC = () => {
  const [interval, setInterval] = useState('Select Interval');
  const [isLoading, setIsLoading] = useState(false);
  const [isReportGenerated, setIsReportGenerated] = useState(false);
  const [platform, setPlatform] = useState<Option[]>([]);
  const [selectedPlatform, setSelectedPlatform] = useState<Option | null>(null);

  const { userDetail } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();


  const token = sessionStorage.getItem('access_token') || '';
  const decoded_token = jwtDecode<CustomJwtPayload>(token);
  const user_id = decoded_token?.user_id;
  if (user_id === undefined) {
    throw new Error('User ID is undefined');
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        

        const response = await dispatch(fetchConnectedPlatformAsync({ user_id })).unwrap();
        console.log('API response:', response);
        const platformOptions = response?.data.map((item: any) => ({
          value: item.id.toString(),
          label: item.display_name || item.name
        }));
        setPlatform(platformOptions);
        console.log('Platform options:', platformOptions);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  

  const handleSelect = (eventKey: string | null) => {
    if (eventKey) {
      setInterval(eventKey);
    }
  };

  const handlePlatformSelect = (eventKey: string | null) => {
    const selected = platform.find(option => option.value === eventKey);
    if (selected) {
      setSelectedPlatform(selected);
    }
  };

  const handleDownloadClick = async (event) => {
    toast.success("Report Download Successfully");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!userDetail?.email) {
      toast.error('User email not found. Please login again.');
      return;
    }
    if (selectedPlatform?.value === undefined){
      throw new Error('Platform is undefined');
    }

    const dataToSubmit = {
      user_id : user_id,
      userEmail: userDetail.email,
      timePeriod: interval,
      platform_id: selectedPlatform?.value,
    };
    try {
      const response = await dispatch(GenrateReportAsync(dataToSubmit)).unwrap();
      const { status_code, message } = response;

      if (status_code === 200) {
        toast.success(`Report generated successfully: ${message}`);
        setIsReportGenerated(true);
      } else {
        toast.warning(`Warning: ${message}`);
        setIsReportGenerated(false);
      }
    } catch (error) {
      console.error('Error submitting query: ', error);
      setIsReportGenerated(false);
      toast.error('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Dropdown onSelect={handlePlatformSelect} className='mt-3'>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                {selectedPlatform ? selectedPlatform.label : "Select Platform"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {platform.length === 0 ? (
                  <Dropdown.Item disabled>No platforms available</Dropdown.Item>
                ) : (
                  platform.map((option) => (
                    <Dropdown.Item key={option.value} eventKey={option.value}>
                      {option.label}
                    </Dropdown.Item>
                  ))
                )}
              </Dropdown.Menu>
            </Dropdown>


          <Form className='mt-4'>
            <FormLabel>Of what time period do you want to generate the report?</FormLabel>
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                {interval}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey='Hourly'>Hourly</Dropdown.Item>
                <Dropdown.Item eventKey='Weekly'>Weekly</Dropdown.Item>
                <Dropdown.Item eventKey='Monthly'>Monthly</Dropdown.Item>
                <Dropdown.Item eventKey='Quarterly'>Quarterly</Dropdown.Item>
                <Dropdown.Item eventKey='Half Yearly'>Half Yearly</Dropdown.Item>
                <Dropdown.Item eventKey='Yearly'>Yearly</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            
            <Button className='mt-2' onClick={handleSubmit}>
              Submit
            </Button>
          </Form>

          {isReportGenerated ? (
            <Button variant='Secondary' className='mt-5' onClick={handleDownloadClick}>
              Download Report
            </Button>
          ) : (
            <div></div>
          )}
        </>
      )}
    </>
  );
};

export default GenerateReport;
