// AvailableGPTModels.js
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks';
import {
    fetchAvailableGPTModelsAsync,
    fetchConnectedAgentAsync
} from 'src/redux/actions/gptModelsActions';
import { toast } from 'react-toastify';
import AddNewAgent from './AddNewAgent';
import { jwtDecode } from 'jwt-decode'

interface AvailableGPTModelsProps {
    isVisible: boolean;
    closeModal: () => void;
    onGPTModelSelected: () => void;
}



const AvailableGPTModels: React.FC<AvailableGPTModelsProps> = ({
    isVisible,
    closeModal,
    onGPTModelSelected,
}) => {
    const [showAddAgentModal, setShowAddAgentModal] = useState<boolean>(false)

    interface CustomJwtPayload {
        user_id?: number;
    }

    const dispatch = useAppDispatch();

    const onClickAddNewAgent = () => {
        setShowAddAgentModal(true)
    }

    const token = sessionStorage.getItem('access_token') || '';

    const decoded_token = jwtDecode<CustomJwtPayload>(token);

    const user_id = decoded_token?.user_id

    const handleGPTModelAdded = async () => {
        if (typeof user_id !== 'undefined') {
            dispatch(fetchConnectedAgentAsync());
        } else {
            console.error('user_id is undefined');
        }
    };

    const { loading, error, data: models } = useAppSelector((state) => state.availableAgents);

    useEffect(() => {
        if (isVisible) {
            dispatch(fetchAvailableGPTModelsAsync())
                .unwrap()
                .then(() => {
                    toast.success('GPT Models fetched successfully');
                })
                .catch((error) => {
                    toast.error('Failed to fetch GPT Models');
                });
        }
    }, [isVisible, dispatch]);

    return (
        <Modal show={isVisible} onHide={closeModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>

            <Modal.Header closeButton>
                <Modal.Title>Select a GPT Model</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="container py-4">
                    <div className="row">

                        {loading && <div>Loading...</div>}

                        {error && <div>{error}</div>}

                        {models && models.length > 0 ? models.map(model => (
                            <div className="col-md-4 mb-4 d-flex align-items-stretch" key={model.id}>
                                <div className="card ">
                                    <img src={model.logo_ref} className="card-img-top mx-auto d-block" alt={model.display_name} />
                                    <div className="card-body text-center">
                                        <h5 className="card-title">{model.display_name}</h5>
                                        {/* <Button className="btn btn-primary">Connect</Button> */}
                                        <Button className="btn btn-primary" onClick={onClickAddNewAgent}>Connect</Button>
                                    </div>
                                </div>
                            </div>
                        )) : <p>No available platforms to display.</p>}

                        {/* {models && models.length > 0 ? (
                            // <div className="list-group">
                                {models.map((model) => (
                                    <Button
                                        key={model.id}
                                        className="list-group-item list-group-item-action"
                                        onClick={() => {
                                            onGPTModelSelected(model.id); // Pass the model ID or any identifier
                                            closeModal();
                                        }}
                                    >
                                        {model.name}
                                    </Button>
                                ))}

                        ) : (
                            <p>No available models to display.</p>
                        )} */}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
            </Modal.Footer>
            {/* <ToastContainer /> */}
            <AddNewAgent
                isVisible={showAddAgentModal}
                closeModal={() => setShowAddAgentModal(false)}
                onAgentAdded={handleGPTModelAdded}

            />
        </Modal>
    );
};

export default AvailableGPTModels;
