import { FC, useEffect } from 'react';
import { Routes, Route, HashRouter, Navigate, useLocation } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { Logout, AuthPage, useAuth } from '../modules/auth';
import { App } from '../App';
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks';
import { accessSessionTokenKeyName, loggedInUserDataKeyName } from 'src/utils/constants';
import { setLoggingData } from 'src/redux/slices/authSlice';

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = sessionStorage.getItem(accessSessionTokenKeyName);
    const loggedInUser = localStorage.getItem(loggedInUserDataKeyName);
    const userFName = sessionStorage.getItem('user_first_name');
    const userLName = sessionStorage.getItem('user_last_name');
    const userEmail = sessionStorage.getItem('user_email');
    if (token) {
      dispatch(setLoggingData({
        isLoggedIn: true,
        userData: {
          first_name: userFName ? userFName : '',
          last_name: userLName ? userLName : '',
          email: userEmail ? userEmail : ''
        }
      }));
    }
    return () => {
      dispatch(setLoggingData({ isLoggedIn: false, userData: null }));
    };
  }, [dispatch]);

  return (
    <HashRouter basename={"/"}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {isLoggedIn ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              {/* <Route index element={<Navigate to='/dashboard' />} /> */}
              <Route index element={<Navigate to='/listing-channels' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<NavigateToAuth />} />
            </>
          )}
        </Route>
      </Routes>
    </HashRouter>
  );
};

const NavigateToAuth: FC = () => {
  const location = useLocation();
  return <Navigate to='/auth' replace state={{ from: location }} />;
};

export { AppRoutes };
