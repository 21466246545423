/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react'
import { ILayout, useLayout } from '../../core'

const Footer = () => {
  const { config } = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      <div className='text-dark order-2 app-container container-fluid order-md-1 d-flex flex-center justify-content-center align-items-center'>
        <span className='text-muted fw-semibold me-1'>
          {new Date().getFullYear().toString()}&copy;
        </span>
        <a
          href='https://constacloud.com/'
          target='_blank'
          className='text-gray-800 text-hover-primary fw-bold'
        >
          ConstaCloud Private Limited
        </a>
      </div>
      {/* <div class="app-footer" id="kt_app_footer"><div class="app-container container-fluid d-flex flex-column flex-center justify-content-center align-items-center flex-md-stack mt-7"><div class="text-dark order-2 order-md-1"><span class="text-muted fw-semibold me-1 fw-bold">2024©</span><a href="https://constacloud.com/" target="_blank" class="text-gray-800 text-hover-primary fw-bold">ConstaCloud Private Limited</a></div></div></div> */}
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export { Footer }
