import { lazy, FC, Suspense, useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { MasterLayout } from '../../marketConnect/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { MenuTestPage } from '../pages/MenuTestPage';
import { getCSSVariableValue } from '../../marketConnect/assets/ts/_utils';
import { WithChildren } from '../../marketConnect/helpers';
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper';
import ConnectedPlatforms from '../pages/listing/ConnectedPlatforms';
import DatabaseConnectivity from 'src/app/pages/template-and-pricing/DatabaseConnectivity';
import data from 'src/@dummyData/data.json';
import AgentConnection from '../pages/products-tables/AgentConnection';
import ChatWithBot from '../pages/chat-bot/ChatWithBot';
import Orders from '../pages/order/Orders';
import Inventories from '../pages/inventory/Inventories';
import AddInstructions from '../pages/Instructions/Addinstructions';
import AssiginTasksNav from '../pages/Instructions/AssiginTasksNav';
import GenrateReport from '../pages/Instructions/GenrateReport';
import InstructionsTable from '../pages/Instructions/InstructionsTable';
import TaskReport from '../pages/Instructions/TaskReport';


// import ViewQuery from '../pages/ViewQuery';

const PrivateRoutes = () => {
  const location = useLocation();
  const tableadata = data;
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'));
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'));

  const [selectedTab, setSelectedTab] = useState<string>('assign-tasks');

  return (
    <Routes>
      <Route element={<MasterLayout />}>
       <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>    
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='/add-instructions'
          element={
            <SuspensedView>
              <AssiginTasksNav selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
              <div className='content'>
                {selectedTab === 'assign-tasks' && <AddInstructions />}
                {selectedTab === 'generate-reports' && <GenrateReport />}
              </div>
            </SuspensedView>
          }
        />
        <Route path='/listing-channels' element={<ConnectedPlatforms />} />
        <Route path='/database-connectivity' element={<DatabaseConnectivity />} />
        <Route path='/agent-connection' element={<AgentConnection />} />
        <Route path='/ask-query' element={<ChatWithBot />} />
        <Route path='/orders/:orderType/:platformId' element={<Orders />} />
        <Route path='/inventories/:orderType/:platformId' element={<Inventories />} />
       

       
        <Route path='/all-tasks' element={<InstructionsTable />} />
        <Route path='/task-report/:historyId' element={<TaskReport />} /> {/* Testing: Route path to task-report */}

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
