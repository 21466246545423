// import React, { FC, useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import ReportTable from './ReportTable';

// const TaskReport: FC = () => {
//   const { historyId } = useParams<{ historyId: string }>();
//   const [columns, setColumns] = useState([]);
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchTableData = async () => {
//       try {
//         const response = await axios.get(`http://127.0.0.1:8000/api/task-report/${historyId}`); // Adjust the URL as needed
//         const { results, columns: columnNames } = response.data;

//         // Map columns from backend response
//         const columnData = columnNames.map((col: string) => ({
//           name: col.replace(/_/g, ' ').toUpperCase(), // Format column names if necessary
//           selector: (row: any) => row[col],
//           sortable: true,
//         }));

//         // Convert row data to objects with column names as keys
//         const rowData = results.map((row: any[]) => {
//           const rowObject: any = {};
//           columnNames.forEach((col: string, index: number) => {
//             rowObject[col] = row[index];
//           });
//           return rowObject;
//         });

//         setColumns(columnData);
//         setData(rowData);
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching table data:', error);
//         setLoading(false);
//       }
//     };

//     fetchTableData();
//   }, [historyId]);

//   return (
//     <div>
//       {loading ? 'Loading...' : <ReportTable columns={columns} data={data} />}
//     </div>
//   );
// };

// export default TaskReport;



import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReportTable from './ReportTable';
import { accessSessionTokenKeyName } from 'src/utils/constants';

const TaskReport: FC = () => {
  const { historyId } = useParams<{ historyId: string }>();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState<string>('');
  const [executionTime, setExecutionTime] = useState<string>('');

  const getTokenSync = () => {
    return sessionStorage.getItem(accessSessionTokenKeyName);
  };

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`http://127.0.0.1:8000/api/task-report/${historyId}`, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`,
            'Content-Type': 'application/json',
          },
        }); // Adjust the URL and headers as needed
        const { results, columns: columnNames, task, execution_time } = response.data;

        // Map columns from backend response
        const columnData = columnNames.map((col: string) => ({
          name: col.replace(/_/g, ' ').toUpperCase(), // Format column names if necessary
          selector: (row: any) => row[col],
          sortable: true,
        }));

        // Convert row data to objects with column names as keys
        const rowData = results.map((row: any[]) => {
          const rowObject: any = {};
          columnNames.forEach((col: string, index: number) => {
            rowObject[col] = row[index];
          });
          return rowObject;
        });

        setColumns(columnData);
        setData(rowData);
        setTask(task);
        setExecutionTime(execution_time);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching table data:', error);
        setLoading(false);
      }
    };

    fetchTableData();
  }, [historyId]);

  return (
    <div>
      {loading ? 'Loading...' : <ReportTable columns={columns} data={data} task={task} executionTime={executionTime}/>}
    </div>
  );
};

export default TaskReport;
