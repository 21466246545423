







import React, { FC, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc'; // Import the UTC plugin
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

dayjs.extend(utc); // Extend dayjs with UTC plugin

// Custom text field component
const CustomTextField: React.FC<any> = (props) => {
  return (
    <TextField
      {...props}
      variant="outlined"
      fullWidth
      InputProps={{
        style: {
          fontSize: '1rem',
        },
        endAdornment: (
          <>
            {props.InputProps?.endAdornment}
          </>
        ),
      }}
      InputLabelProps={{
        style: {
          fontSize: '1rem',
        },
      }}
    />
  );
};

interface ShopifySettingsProps {
  settings: {
    showOrderSync: boolean;
    showProductSync: boolean;
    orderSyncDateTime?: string;
  };
  setSettings: (settings: {
    showOrderSync?: boolean;
    showProductSync?: boolean;
    orderSyncDateTime?: string;
  }) => void;
  platformId: number;
  isReadonly: boolean;
  onDateChange: (field: string, date: Date | null) => void
}

const ShopifySettings: FC<ShopifySettingsProps> = ({ settings, setSettings, platformId, isReadonly }) => {
  const [orderSyncDateTime, setOrderSyncDateTime] = useState<Dayjs | null | string>(
     null
  );
  const [isReadonlyState, setIsReadonlyState] = useState<boolean>(isReadonly);

  const now = dayjs(); // Current date and time

  const handleOrderSyncDateChange = (date: Dayjs | null) => {
    if (date && date.isValid()) {
      // Convert to UTC before saving
      const utcDate = date.utc().toISOString();
      console.log("------ checking datetine t",utcDate)
      setOrderSyncDateTime(date.utc(true).toString());
      setSettings({
        ...settings,
        orderSyncDateTime: date.utc(true).toString(),
      });
    }
  };

  const handleToggle = (field: 'showOrderSync' | 'showProductSync') => {
    setSettings({
      ...settings,
      [field]: !settings[field],
    });
  };

  const handleSaveSettings = async () => {
    const dataToSend = {
      showOrderSync: settings.showOrderSync,
      showProductSync: settings.showProductSync,
      orderSyncDateTime: settings.orderSyncDateTime,
    };

    setIsReadonlyState(true);

    try {
      const response = await fetch(`/api/create-platform-sync-settings/${platformId}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSend)
      });
      const result = await response.json();
      console.log('Response from server:', result);
    } catch (error) {
      console.error('Error saving settings:', error);
    } finally {
      setIsReadonlyState(false);
    }
  };

  return (
    <>
      {/* Orders Section */}
      <div className='card m-2 p-4 shadow'>
        <div>
          <p className='h3'>Orders</p>
        </div>
        <div className='border-bottom my-2 border-2'></div>
        <div className='p-4'>
          <div className='form-check form-switch form-check-custom form-check-solid me-10'>
            <label className='form-check-label text-dark h4' htmlFor='orderSync'>
              Sync Orders
            </label>
            <input
              className='ms-8 form-check-input h-20px w-40px'
              type='checkbox'
              id='orderSync'
              checked={settings.showOrderSync}
              onChange={() => handleToggle('showOrderSync')}
              // disabled={isReadonly}
              style={{
                backgroundColor: settings.showOrderSync ? '#3E97FF' : 'silver',
                // opacity: isReadonly ? 0.5 : 1,
                height: '20px',
                width: '40px',
                cursor: isReadonly ? 'not-allowed' : 'pointer'
              }}
            />
          </div>
          {settings.showOrderSync && (
            <Box sx={{ marginTop: '20px', width: '100%', maxWidth: '400px' }}>
              <div style={{ marginBottom: '8px', fontSize: '1rem', fontWeight: 'bold' }}>
                Select Order Sync Start Date (UTC)
                <Tooltip
                title={
                  <div style={{ 
                    backgroundColor: '#f0f0f0', 
                    color: '#000', 
                    fontSize: '0.875rem', 
                    padding: '8px', 
                    maxWidth: '300px',
                    wordBreak: 'break-word',
                    borderRadius: '4px' // Optional, if you want rounded corners
                  }}>
                    <p style={{ fontSize: '1rem', margin: '0 0 8px' }}>
                      <strong>“</strong>This date specifies the start from which you want to fetch the order.<strong>”</strong>
                    </p>
                    <p style={{ fontSize: '1.2rem', margin: '0' }}>
                      <strong>Note:</strong> <strong>“</strong>Once saved, this date cannot be changed. If you want to change the date, you can connect with Support or create a new connection.<strong>”</strong>
                    </p>
                  </div>
                }
                arrow
              >
                <InfoIcon sx={{ marginLeft: '8px', fontSize: '2rem', color: '#6c757d' }} />
              </Tooltip>
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  // value={orderSyncDateTime}  
                  value={(orderSyncDateTime==null && settings["orderSyncDateTime"])?dayjs.utc(settings["orderSyncDateTime"]):orderSyncDateTime?dayjs.utc(orderSyncDateTime):null}
                  onChange={handleOrderSyncDateChange}
                  readOnly={isReadonly}
                  slots={{
                    textField: (textFieldProps) => <CustomTextField {...textFieldProps} />,
                  }}
                  ampm={false}
                  maxDate={now} // Restrict future dates
                  closeOnSelect={true}
                  format="DD-MMM-YY, HH:mm  "
                  disabled={(orderSyncDateTime==null && settings["orderSyncDateTime"])?Boolean(settings["orderSyncDateTime"]):false}
                  // disabled={isReadonly}
                />
              </LocalizationProvider>
            </Box>
          )}
        </div>
      </div>

      {/* Product Section */}
      <div className='card m-2 p-4 shadow'>
        <div>
          <p className='h3'>Product</p>
        </div>
        <div className='border-bottom my-2 border-2'></div>
        <div className='p-4'>
          <div className='form-check form-switch form-check-custom form-check-solid me-10'>
            <label className='form-check-label text-dark h4' htmlFor='productSync'>
              Sync Product
            </label>
            <input
              className='ms-8 form-check-input h-20px w-40px'
              type='checkbox'
              id='productSync'
              checked={settings.showProductSync}
              onChange={() => handleToggle('showProductSync')}
              // disabled={isReadonly}
              style={{
                backgroundColor: settings.showProductSync ? '#3E97FF' : 'silver',
                // opacity: isReadonly ? 0.5 : 1,
                height: '20px', 
                width: '40px',
                cursor: isReadonly ? 'not-allowed' : 'pointer'
              }}
            />
          </div>
        </div>
      </div>

      {/* Save Settings Button */}
      {/* <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
        <button
          className='btn btn-primary'
          onClick={handleSaveSettings}
          disabled={isReadonlyState}
        >
          Save Settings
        </button>
      </Box> */}
    </>
  );
};

export default ShopifySettings;
