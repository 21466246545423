import React, { FC } from 'react'
import {Card} from 'react-bootstrap'
import {Link} from 'react-router-dom'

const NoAgentFound:FC = () => {
  return (
    <Card className='shadow my-4 p-4 text-center'>
      <Card.Body>
        <Card.Title className='text-danger' style={{fontSize: '1.5rem', fontWeight: 'bold'}}>
          No Agents Found
        </Card.Title>
        <Card.Text style={{fontSize: '1.2rem', margin: '20px 0'}}>
          You do not have an agent. Please{' '}
          <Link
            to='/crafted/pages/profile/openai-key'
            className='text-info'
            style={{textDecoration: 'underline'}}
          >
            add an OpenAI key
          </Link>{' '}
          to proceed.
        </Card.Text>
        <Card.Text style={{fontSize: '1rem', color: '#6c757d'}}>
          Agents are required to perform tasks on this platform. Adding an OpenAI key will allow you
          to create and manage agents.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default NoAgentFound
