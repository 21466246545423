import React from 'react'
import { Spinner } from 'react-bootstrap'



const LoaderTransparent = () => {
  return (
    <div style={{minHeight:"80vh",position:"absolute",width: "100%",zIndex: "9", height: "100%",
      backgroundColor: "#0005"
    }} className='d-flex  justify-content-center align-items-center h-100' >
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>

    </div>
  )
}

export default LoaderTransparent


// min-height: 80vh;
//     position: absolute;
//     width: 100%;
//     z-index: 9;
//     height: 100%;
//     background-color: #0005;
//     height: 100vh !important;