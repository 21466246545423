import { useEffect, useState } from 'react'
import AlertModal from 'src/utils/modals/AlertModal'
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'
import { toast } from 'react-toastify'
import AvailableGPTModels from './components/AvailableGPTModels'
import { fetchConnectedAgentAsync, deleteAgentAsync, addAgentAsync, updateAgentAsync } from 'src/redux/actions/gptModelsActions'
import ConnectAgentStrip from './components/ConnectAgentStrip'
import Loader from 'src/utils/Loader'
import AppInfoAlert from 'src/utils/AppInfoAlert'
import {
  Button,
  Dropdown,
  FloatingLabel,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
} from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { UserAgentSetting } from 'src/interfaces/Platform.interface';



const AgentConnection = () => {
  const dispatch = useAppDispatch()

  // States for managing UI components
  const [showAgentModal, setShowAgentModal] = useState(false)
  const [showAvailableGPTModels, setShowAvailableGPTModels] = useState(false)
  const [showAlertModal, setShowAlertModal] = useState(false)
  const [selectedAgentId, setSelectedAgentId] = useState(null)
  const [isEditing, setIsEditing] = useState(false); // New state for editing

  const [editAgent, setEditAgent] = useState<UserAgentSetting | null>(null); // New state for holding agent details for editing


  const formik = useFormik({
    initialValues: {
      friendlyName: '',
      token: '',
      model: '',
    },
    validationSchema: Yup.object({
      friendlyName: Yup.string().required('User Friendly Name is required'),
      token: Yup.string().required('API Key is required').matches(/^sk-/, 'API Key must start with "sk-********"'),
      model: Yup.string().required('Model selection is required'),
    }),
    
    onSubmit: async (values, { setSubmitting }) => {

      try {
        let response: any = null;
        if (isEditing && editAgent) {
          // Update existing agent
          response = await dispatch(updateAgentAsync({ id: editAgent.id, ...values })).unwrap();
          toast.success('Agent updated successfully!');
        } else {
          // Add new agent
          response = await dispatch(addAgentAsync(values)).unwrap();
          toast.success(response.message);

        }

        setShowAgentModal(false);

        dispatch(fetchConnectedAgentAsync());

      } catch (error: any) {
        console.log(error.message)
        toast.error(error.message);

      } finally {

        setSubmitting(false);

      }

    },

  });

  // State from Redux for fetched agents
  const { fetchedAgentsLoading, fetchedAgentsData } = useAppSelector(
    (state) => state.fetchConnectedAgents
  )

  // Function to show the GPT models modal
  const onClickShowGPTModels = () => {
    setShowAgentModal(true)
    setIsEditing(false); // Reset editing state
    formik.resetForm(); // Reset form fields
  }

  const handleAgentAdded = async () => {
    console.log('Agent added')
    setShowAgentModal(false) // Close the modal
    dispatch(fetchConnectedAgentAsync()) // Fetch the updated list of agents
    toast.success('Agent added successfully!')
  }

  // Function to delete an agent
  const deleteAgent = async (agentId) => {
    console.log('Deleting agent', agentId)
    await dispatch(deleteAgentAsync({ id: agentId }))
    toast.success('Agent deleted successfully!')
    setShowAlertModal(false) // Close the alert modal
    dispatch(fetchConnectedAgentAsync()) // Fetch the updated list of agents
  }

  // function to edit agent
  const handleEdit = (agent) => {
    setEditAgent(agent); // Set the agent to be edited
    setIsEditing(true); // Set editing state
    setShowAgentModal(true); // Show the modal
    formik.setValues({
      friendlyName: agent.friendly_name,
      token: agent.token,
      model: agent.model,
    }); // Populate form with agent details
  };

  // Fetch agents on component mount
  useEffect(() => {
    dispatch(fetchConnectedAgentAsync())
  }, [dispatch])

  if (fetchedAgentsLoading) return <Loader />

  return (
    <>
      <div>
        <div className='py-2 pt-0'>
          {fetchedAgentsData?.length > 0 ? (
            fetchedAgentsData?.map((agent: any) => {
              return (
                <ConnectAgentStrip
                  key={agent.id}
                  agent={agent}
                  onDelete={() => deleteAgent(agent.id)}
                  // onEdit={handleEdit}
                  onEdit={() => handleEdit(agent)}
                // openSettingsModal={() => onClickSetting(platform)}
                />
              )
            })
          ) : (
            <div className='card shadow p-2'>
              <div className='card-header'>
                <h5 className='card-title fs-3'>Openai key</h5>
                <div className='card-toolbar'>
                  <button className='btn btn-sm btn-info' onClick={onClickShowGPTModels}>
                    Add New Agent
                  </button>
                </div>
              </div>

              <div className='card-body'>
                <div className='d-flex flex-column flex-md-row justify-content-between p-5 border border-light-primary rounded'>
                  <AppInfoAlert
                    title='No agents founds yet'
                    description={
                      <div>
                        <p>Add the agents from above button</p>
                      </div>
                    }
                  />

                </div>


              </div>
            </div>
          )}
        </div>
      </div>

      <Modal show={showAgentModal} centered size='lg' onHide={() => setShowAgentModal(false)}>
        <div className='w-100'>
          <Modal.Header closeButton>
            {/* <Modal.Title>Add New Agent</Modal.Title> */}
            <Modal.Title>{isEditing ? 'Edit Agent' : 'Add New Agent'}</Modal.Title>
          </Modal.Header>
          <div className='card p-4 bg-white' style={{ height: '38vh' }}>
            <Form className='m-3' onSubmit={formik.handleSubmit}>
              <FormLabel>Submit your API key for creating and working with Agents.</FormLabel>
              <FormGroup className='d-flex align-items-end mb-5'>
                <FloatingLabel className='w-75' label='User Friendly Name'>
                  <FormControl
                    size='lg'
                    type='text'
                    placeholder='Enter a user-friendly name for API-key'
                    {...formik.getFieldProps('friendlyName')}
                    isInvalid={formik.touched.friendlyName && !!formik.errors.friendlyName}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.friendlyName}
                  </Form.Control.Feedback>
                </FloatingLabel>
                <div className='w-25 ms-lg-5 h-100'>
                  <FormLabel>Select the OpenAI's model</FormLabel>
                  <Dropdown>
                    <Dropdown.Toggle variant='secondary' id='dropdown-basic' className='w-100'>
                      {formik.values.model || 'Models'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='w-100'>
                      <Dropdown.Item onClick={() => formik.setFieldValue('model', 'GPT-4o')}>
                        GPT-4o
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => formik.setFieldValue('model', 'GPT-4-turbo')}>
                        GPT-4-turbo
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => formik.setFieldValue('model', 'GPT-3.5 Turbo')}>
                        GPT-3.5 Turbo
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Control.Feedback type='invalid' style={{ display: 'block' }}>
                    {formik.touched.model && formik.errors.model}
                  </Form.Control.Feedback>
                </div>
              </FormGroup>
              <FloatingLabel label='Enter your Chat-GPT API Key'>
                <FormControl
                  className='mb-5'
                  size='lg'
                  type='text'
                  placeholder="Enter your OpenAI's API-key starting with sk-*****"
                  {...formik.getFieldProps('token')}
                  isInvalid={formik.touched.token && !!formik.errors.token}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.token}
                </Form.Control.Feedback>
              </FloatingLabel>
              <Button
                type='submit'
                className='btn btn-info float-end'
                disabled={formik.isSubmitting}
              >
                {/* Submit */}
                {isEditing ? 'Update' : 'Submit'}
              </Button>
            </Form>
          </div>
        </div>
      </Modal>

      <AlertModal
        title={`Are you sure?`}
        subtitle={
          <p>
            {' '}
            You are going to delete <b></b> you won't be able to revert this!{' '}
          </p>
        }
        isVisible={showAlertModal}
        closeModal={() => setShowAlertModal(false)}
        onConfirm={() => deleteAgent(selectedAgentId)}
      />
      <AvailableGPTModels
        isVisible={showAvailableGPTModels}
        closeModal={() => setShowAvailableGPTModels(false)}
        onGPTModelSelected={handleAgentAdded} // Assume this function also sets the selected agent
      />
      {/* Implement a component or method to list agents similar to ConnectPlatformStrip for platforms */}
    </>
  )
}

export default AgentConnection
